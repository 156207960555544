<template>
  <div class="panel-group" id="desplegable">
    <div
      v-for="elemento in informacion"
      v-bind:key="elemento.id"
      class="panel panel-default"
    >
      <div
        class="panel-title panel-heading accordion-toggle"
        data-toggle="collapse"
        data-parent="desplegable"
        :href="'#collapse' + elemento.id"
        role="tab"
        @click="ocultarotros(elemento.id)"
        :id="'titulo' + elemento.id"
      >
        <h2 class="texto30px" v-html="elemento.titulo">
          <!--{{ paginaid }}.{{ elemento.section_number }}.-->
          <!-- {{ elemento.titulo }}.-->
        </h2>
      </div>
      <div :id="'collapse' + elemento.id" class="panel-collapse collapse">
        <div
          v-html="elemento.contenido"
          class="texto20px textojustificado escroleable400px panel-body"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
//let lineas =
export default {
  props: {
    informacion: Array,
    paginaid: String,
  },
  data() {
    return {};
  },
  methods: {
    myFunction() {
      this.textodropdown = document.getElementById('myInput').value;
      /*var elements = document.getElementsByClassName('opcion-dropd');
      for (var i = 0; i < elements.length; i++) {
        elements[i].style.display = 'block';
      }*/
      document.getElementById('div-opcion-drop-d').style.display = 'block';
    },

    ocultarotros: function(id) {
      let presionado = 'titulo' + id;
      let colapso = 'collapse' + id;
      //console.log('presionado '+presionado);
      if (document.getElementById(colapso).classList.contains('show')) {
        //console.log('tiene la clase show');
        //document.getElementById(colapso).classList.remove('show');
      } else {
        let divsToHide = document.getElementsByClassName('accordion-toggle');
        let i = 0;
        for (i = 0; i < divsToHide.length; i++) {
          //  console.log('oculto a :'+divsToHide[i].id);
          if (divsToHide[i].id !== presionado) {
            divsToHide[i].setAttribute('aria-expanded', 'false'); // or
            divsToHide[i].classList.add('collapsed');
            divsToHide[i].classList.remove('collapsed');
          }
        }
        divsToHide = document.getElementsByClassName('collapse');
        for (i = 0; i < divsToHide.length; i++) {
          //  console.log('oculto a :'+divsToHide[i].id);
          if (divsToHide[i].id !== presionado) {
            divsToHide[i].classList.remove('show');
          }
        }
      }
    },
  },
  mounted() {},
};
</script>

<style>
.panel-title {
  position: relative;
  border-width: 1px;
  border-style: solid;
  border-color: #a0a0a0;
}
.panel-title::after {
  content: '\f107';
  color: #333;
  top: -2px;
  right: 0px;
  position: absolute;
  font-family: 'FontAwesome';
  font-size: 30px;
}

.panel-title[aria-expanded='true']::after {
  content: '\f106';
}
.panel-heading {
  padding: 0;
}

.panel-title {
  padding: 10px 15px;
}

.panel-title::after {
  top: 10px;
  right: 15px;
}

.panel-title:hover {
  background-color: #dcdcdc;
}

.collapsed > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
</style>
