var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("div", { staticClass: "section content-title-group" }, [
        _c("h2", { staticClass: "title" }, [_vm._v(_vm._s(_vm.titulo))]),
      ]),
      _c("FilterBar", { attrs: { mensajeFiltro: _vm.mensajeFiltro } }),
      _c("div", { staticClass: "notification is-info" }, [
        _c("pre", [
          _vm._v(_vm._s(_vm.actualizado) + " " + _vm._s(_vm.resultados)),
        ]),
      ]),
      _c("vuetable", {
        ref: "table",
        attrs: { fields: _vm.fields, "api-mode": false, data: _vm.lineas },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function (props) {
              return [
                _c("div", { staticClass: "custom-actions" }, [
                  _c(
                    "button",
                    {
                      staticClass: "ui basic button",
                      on: {
                        click: function ($event) {
                          return _vm.onAction(
                            "view-item",
                            props.rowData,
                            props.rowIndex
                          )
                        },
                      },
                    },
                    [_c("i", { staticClass: "zoom icon" })]
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }