var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "filter-bar ui basic segment grid" }, [
    _c("div", { staticClass: "ui form" }, [
      _c("div", { staticClass: "inline field" }, [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.filterText,
              expression: "filterText",
            },
          ],
          staticClass: "three wide column",
          staticStyle: { "margin-left": "-12px" },
          attrs: { type: "text", placeholder: "" },
          domProps: { value: _vm.filterText },
          on: {
            keyup: function ($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
              )
                return null
              return _vm.doFilter.apply(null, arguments)
            },
            input: function ($event) {
              if ($event.target.composing) return
              _vm.filterText = $event.target.value
            },
          },
        }),
        _c(
          "button",
          { staticClass: "ui primary button", on: { click: _vm.doFilter } },
          [_vm._v("Filtrar")]
        ),
        _c(
          "button",
          { staticClass: "ui button", on: { click: _vm.resetFilter } },
          [_vm._v("Restablecer")]
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }