/* eslint-disable prettier/prettier */
<template>
  <div class="">
    <div id="floatingarea" class="floatingarea"></div>
    <div id="mySidenav" class="sidenav">
      <a href="javascript:void(0)" class="closebtn" @click="closeNav"
        >&times;</a
      >
      <router-link to="/home">Home</router-link>

      <div v-for="contenedor in nav" v-bind:key="contenedor.id_espacio">
        <div v-if="contenedor.navegacion.component === ''">
          <b
            style="margin-left:15px;color: rgb(38, 175, 230)!important;text-align:center!important;"
            >{{ contenedor.navegacion.titulo }}</b
          >
        </div>
        <div v-else>
          <b>
            <router-link
              style="margin-left:15px;color: rgb(38, 175, 230)!important;padding-left:0px!important;text-align:center!important;"
              :to="'/' + contenedor.navegacion.path"
              >{{ contenedor.navegacion.titulo }}</router-link
            >
          </b>
        </div>
        <div
          v-for="seccion in contenedor.navegacion.members"
          v-bind:key="seccion.elementoid"
        >
          <div v-if="seccion.component === ''">
            <b :class="seccion.members.length > 0 ? 'dropdown-btn' : ''">
              {{ seccion.titulo }}
              <i
                :v-if="seccion.members.length > 0"
                :class="seccion.members.length > 0 ? 'fa fa-caret-down' : ''"
              ></i>
            </b>
            <div v-if="seccion.members.length > 0" class="dropdown-container">
              <div v-for="pagina in seccion.members" v-bind:key="pagina.pageid">
                <div v-if="pagina.members.length > 0">
                  <div v-if="pagina.component !== ''">
                  
                      <b :class="pagina.members.length > 0 ? 'dropdown-btn' : ''">
                        <router-link :to="pagina.path">
                        {{ pagina.titulo }}
                        <i
                          :v-if="pagina.members.length > 0"
                          :class="
                            pagina.members.length > 0 ? 'fa fa-caret-down' : ''
                          "
                        ></i>
                        </router-link>
                      </b>

                      <div class="dropdown-container">
                        <div
                          v-for="pagina2 in pagina.members"
                          v-bind:key="pagina2.pageid"
                        >
                          <router-link :to="pagina2.path">
                            {{ pagina2.titulo }}
                          </router-link>
                        </div>
                      </div>
                  </div>
                  <div v-else>
                    <b :class="pagina.members.length > 0 ? 'dropdown-btn' : ''">
                        {{ pagina.titulo }}
                        <i
                          :v-if="pagina.members.length > 0"
                          :class="
                            pagina.members.length > 0 ? 'fa fa-caret-down' : ''
                          "
                        ></i>
                      </b>

                      <div class="dropdown-container">
                        <div
                          v-for="pagina2 in pagina.members"
                          v-bind:key="pagina2.pageid"
                        >
                          <router-link :to="pagina2.path">
                            {{ pagina2.titulo }}
                          </router-link>
                        </div>
                      </div>

                  </div>
                </div>
                <div v-else>
                  <b :class="pagina.members.length > 0 ? 'dropdown-btn' : ''">
                    <router-link :to="pagina.path">
                      {{ pagina.titulo }}
                    </router-link>
                    <i
                      :v-if="pagina.members.length > 0"
                      :class="
                        pagina.members.length > 0 ? 'fa fa-caret-down' : ''
                      "
                    ></i>
                  </b>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <b :class="seccion.members.length > 0 ? 'dropdown-btn' : ''">
              <router-link :to="seccion.path">
                {{ seccion.titulo }}
              </router-link>
              <i
                :v-if="seccion.members.length > 0"
                :class="seccion.members.length > 0 ? 'fa fa-caret-down' : ''"
              ></i>
            </b>
            <div v-if="seccion.members.length > 0" class="dropdown-container">
              <div v-for="pagina in seccion.members" v-bind:key="pagina.pageid">
                <router-link :to="pagina.path">
                  {{ pagina.titulo }}

                  <i
                    :v-if="pagina.members.length > 0"
                    :class="pagina.members.length > 0 ? 'fa fa-caret-down' : ''"
                  ></i>
                </router-link>

                <!--<div
                  :v-if="pagina.members.length > 0"
                  v-for="pagina2 in pagina.navegacion.members"
                  v-bind:key="pagina2.pageid"
                >
                  <router-link :to="pagina2.path">
                    {{ pagina2.titulo }}
                  </router-link>
                </div>-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--    
          
          <div v-if="elemento.components.length > 0"></div>
        </div>
      </b>
      <b class="dropdown-btn">
        Recursos
        <i class="fa fa-caret-down"></i>
      </b>
      <div class="dropdown-container">
        <router-link to="/tabla_periodica">Tabla periódica</router-link>
        <router-link to="/compuestos">Base de compuestos</router-link>
      </div>
      <b class="dropdown-btn">
        Teoría
        <i class="fa fa-caret-down"></i>
      </b>
      <div class="dropdown-container">
        <router-link
          v-for="elemento in datosPaginas"
          v-bind:key="elemento.pageid"
          :to="'/teoria/' + elemento.pageid"
        >
          {{ elemento.titulo }}
        </router-link>
      </div>
    </div>-->
    <div class="navrow backgroundAppName">
      <div id="main" class="backgroundAppName">
        <span style="font-size:30px;"
          ><i
            class="fas fa-bars"
            style="margin-top: 8px;color:rgb(255, 255, 255, 0.6);"
            @click="openNav"
          />
          <!--<i class="fas fa-flask agencia"  @click="openNav" />-->
          <span class="kstpclass"> Knowledge Self-Training Platform</span>
        </span>
        <div style="float:right;margin-right:8px;" class="backgroundColorApp">
          <ul class="" v-if="!user">
            <li class="nav-item">
              <router-link class="nav-link" to="/login"
                ><span class="fas fa-lock"></span
                ><span style="padding-left:4px;"
                  >Sign in / Sign up</span
                ></router-link
              >
            </li>
          </ul>
          <div class="logout" v-if="user">
            <a class="colorElemento1" href="/"> {{ user.nickname }} </a>
            <a class="" @click="logout">
              <span style="padding-left:3px;" class="fas fa-sign-out-alt">
              </span>
              <span style="padding-left:3px;"> </span>
              Logout</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
//import DatosPaginas from '../views/data/Datos-Paginas';
import {mapGetters} from 'vuex';
import {_logout} from '../views/scripts/auth';
export default {
  components: {},
  data() {
    return {
      //datosPaginas: DatosPaginas,
    };
  },
  computed: {
    ...mapGetters(['user']),
    ...mapGetters(['nav']),
  },
  methods: {
    openNav() {
      document.getElementById('mySidenav').style.width = '250px';
      document.getElementById('main').style.marginLeft = '250px';
    },
    closeNav() {
      document.getElementById('mySidenav').style.width = '0';
      document.getElementById('main').style.marginLeft = '0';
    },
    clicked: function(e) {
      //console.log(e);
      if (e.classList.contains('disabled')) {
        //console.log('se presiono este');
        e.preventDefault();
      } else {
        //console.log('se presiono otro');
      }
    },
    logout: function() {
      _logout()
        // eslint-disable-next-line no-unused-vars
        .then(op => {
          if (
            this.$router.currentRoute.path !== '/home' &&
            this.$router.currentRoute.path !== '/'
          ) {
            this.$router.push('/');
          }

          this.$store.commit('LOGOUT');
        })
        // eslint-disable-next-line no-unused-vars
        .catch(err => {
          //this.error = true;
          //console.log('ERROR: -> ' + JSON.stringify(resultado));
        });
    },
  },
  created() {
    //console.log('nav-bar creado');
  },
  mounted() {
    //console.log('nav-bar montado');
    let dropdown = document.getElementsByClassName('dropdown-btn');
    let i;

    for (i = 0; i < dropdown.length; i++) {
      dropdown[i].addEventListener('click', function() {
        this.classList.toggle('active');
        var dropdownContent = this.nextElementSibling;
        if (dropdownContent.style.display === 'block') {
          dropdownContent.style.display = 'none';
        } else {
          dropdownContent.style.display = 'block';
        }
      });
    }
  },
};
</script>
<style scoped>
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
}

.sidenav a,
.dropdown-btn {
  padding: 8px 8px 8px 0px;
  text-decoration: none;
  font-size: 15px;
  color: #818181;
  display: block;
  transition: 0.3s;
  text-align: left;
}

.sidenav a:hover,
.dropdown-btn:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

#main {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  text-align: left;
}
.sticky {
  position: fixed;
}
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}

.agencia {
  color: rgb(38, 175, 230);
}
.kstpclass {
  color: rgb(52, 226, 105);
}
.metropolitano {
  color: rgb(255, 238, 5);
}
.dropdown-container {
  display: none;
  background-color: #2c3e50;
  padding-left: 8px;
}

/* Optional: Style the caret down icon */
.fa-caret-down {
  float: right;
  padding-right: 8px;
}

.navbar {
  background-color: #293241;
}
.nav-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgb(255, 255, 255, 0.6);
}
.nav-link > svg {
  margin-right: 10px;
}
.nav-link.router-link-active {
  color: #fff;
}

.logout {
  cursor: pointer;
  padding-top: 15px;
  color: rgb(255, 255, 255, 0.6);
}
</style>
