import {_getSec} from './rest';
const compuestosPath = '/cms/compuestos/tomarTodos';
export {_tomarCompuestos};

const _tomarCompuestos = access_token => {
  return new Promise(resolve => {
    _tomarCompuestosCall(access_token, data => {
      resolve(data);
    });
  });
};
function _tomarCompuestosCall(access_token, callback) {
  _getSec(compuestosPath, access_token, function(resultado) {
    callback(resultado);
  });
}
