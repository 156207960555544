<template>
  <div id="loader">
    <PaginaConSecciones
      :numeroDePagina="numeroDePagina"
      :nombrePagina="titulo"
      :informacion="paginaEnviada"
    />
  </div>
</template>

<script>
//import DatosPaginas from './data/Datos-Paginas.js';
import PaginaConSecciones from './pagina_con_secciones.vue';
//import MapModal from './components-map/MapModal';
import Vue from 'vue';
import VueEvents from 'vue-events';
import {_obtenerContenidoPagina} from './scripts/paginas';
Vue.use(VueEvents);
Vue.component('pagina-con-secciones', PaginaConSecciones);

export default {
  name: 'app',
  components: {
    PaginaConSecciones,
    //MapModal,
  },
  data() {
    return {
      titulo: '',
      paginas: '',
      paginaSolicitada: this.$route.name,
      paginaEnviada: Array,
      numeroDePagina: '',
      ultima_actualizacion: '',
    };
  },
  created() {
    //tomar pagina usando axios con la propiedad this.paginaSolicitada.
    //this.render = false;
    let elementos = document.getElementsByClassName('show');

    for (let i = 0; i < elementos.length; i++) {
      elementos[i].classList.remove('show');
    }
    this.updateContent(this.$route.name);
  },
  mounted() {
    //algo
    //this.render = false;
    this.updateContent(this.$route.name);
  },
  methods: {
    updateContent: function(url) {
      console.log('actualizando contenido');
      _obtenerContenidoPagina(url)
        .then(resultado => {
          //console.log(resultado);
          if (resultado.status === 200) {
            //this.paginas = resultado.data.data;
            let obj = resultado.data.data;
            //console.log(obj);

            this.titulo = obj.titulo;
            this.ultima_actualizacion = obj.ultima_actualizacion;
            this.paginaEnviada = obj.pagina;
            //this.render = true;
            //console.log('Resultado: ' + JSON.stringify(resultado));
          } else {
            this.error = true;
            //console.log('ERROR: -> ' + JSON.stringify(resultado));
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch(err => {
          this.error = true;
          //console.log('ERROR: -> ' + err);
          return 1;
        });
    },
  },
  updated() {
    //console.log('loader updateado');
    //console.log(this.$route.params.id);
    //this.updateContent();
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    $route(to, from) {
      // react to route changes...
      //console.log('watched');
      
      this.render = false;
      //this.updateContent();
      this.$nextTick(() => {
        let elementos = document.getElementsByClassName('show');

        for (let i = 0; i < elementos.length; i++) {
          elementos[i].classList.remove('show');
        }
        this.updateContent(to.name);
        this.$forceUpdate();
      });
    },
  },
};
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
<style lang="scss">
@import '@/design/index.scss';
</style>
