const loginPath = '/auth/login';
const regPath = '/auth/signup';
let datos = {email: '', password: ''};
let regdata = {
  email: '',
  password: '',
  nickname: '',
  firstname: '',
  lastname: '',
};
import {_encrypt, _encryptPass} from './cripto';
import {_post} from './rest';
//const util = require('util');
export {_login, _logout, _register};

const _login = (email, password) => {
  return new Promise(resolve => {
    _loginCall(email, password, data => {
      //if (err) return reject(err);
      //console.log(data);

      /* if (data.status === 200) {
         let datos;
        if (data.data.data) {
          const procesado = {
            iv: data.data.data.sessionid,
            encryptedData: data.data.data.token,
          };
          datos = JSON.parse(_decrypt(procesado));
          delete datos.token;
          delete datos.refresh;
          datos.sessionid = procesado.iv;
          datos.token = procesado.encryptedData;
          data.data.data = datos;
        }
        //aca hay que hacer un append de los datos encriptados.
        // if (datos.token) {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        //console.log('sí, entra');
        //localStorage.setItem('user', JSON.stringify(datos));
        // }
      }
      */
      resolve(data);
    });
  });
};
const _register = datosRegistro => {
  return new Promise(resolve => {
    _registerCall(datosRegistro, data => {
      if (data.status === 200) {
        data = {status: data.status, msg: 'Usuario creado exitosamente.'};
      }
      resolve(data);
    });
  });
};

function _loginCall(email, password, callback) {
  _post(loginPath, processDataLogin(email, password), function(resultado) {
    callback(resultado);
  });
}
const _logout = () => {
  return new Promise(resolve => {
    //localStorage.removeItem('user');
    resolve('logout');
  });
};

function _registerCall(data, callback) {
  //console.log('recibo:' + JSON.stringify(data));
  _post(regPath, processRegData(data), function(resultado) {
    callback(resultado);
  });
}
// eslint-disable-next-line no-unused-vars
function processData(email, password) {
  datos.email = email;
  datos.password = password;
  let datosEncriptados = _encrypt(JSON.stringify(datos));
  let datosEnmascarados = {
    sessionid: datosEncriptados.iv,
    token: datosEncriptados.encryptedData,
  };
  return datosEnmascarados;
}
function processDataLogin(email, password) {
  datos.email = email;
  datos.password = password;
  let datosEncriptados = _encrypt(JSON.stringify(datos));
  let datosEnmascarados = {
    user: datosEncriptados.iv,
    password: datosEncriptados.encryptedData,
  };
  return datosEnmascarados;
}
function processRegData(data) {
  regdata.email = data.email;
  regdata.password = _encryptPass(data.password).encryptedData;
  regdata.nickname = data.nickname;
  regdata.firstname = data.firstname;
  regdata.lastname = data.lastname;
  let datosEncriptados = _encrypt(JSON.stringify(regdata));
  let datosEnmascarados = {
    requestid: datosEncriptados.iv,
    data: datosEncriptados.encryptedData,
  };
  return datosEnmascarados;
}
