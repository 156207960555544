<template>
  <div id="app">
    <div class="section content-title-group">
      <h2 class="title">{{ titulo }}</h2>
    </div>
    <!-- eslint-disable-next-line-->
    <br/><br/>
    <span class="texto30px">
      El próposito de este sitio es brindar recursos para el 
      aprendizaje y consulta de contenidos.
      <!--<span class="flecha-doble-invertida texto30px resaltador-celeste"></span
    >--></span>
    <!--<FilterBar :mensajeFiltro="mensajeFiltro" />-->
  </div>
</template>

<script>
//import FilterBar from '../components/SelectionBar4';

import Vue from 'vue';
import VueEvents from 'vue-events';

Vue.use(VueEvents);
//Vue.component('filter-bar', FilterBar);

export default {
  name: 'app',
  components: {
    //FilterBar,
    //MapModal,
  },
  data() {
    return {
      titulo: 'Bienvenido',
    };
  },
  created() {},
  methods: {
    cargarModalElemento: function(id, nombreModal) {
      id = id.replace('#', '');
      this.informacion.filter(function(elem) {
        if (elem.element_data_id == id) {
          document.getElementById('textoModal').innerHTML =
            elem.element_data_content;
          document.getElementById('exampleModalLabel').innerText = nombreModal;
          // eslint-disable-next-line no-undef
          $('#exampleModal').modal({backdrop: false});
        }
      });
    },
  },
};
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
<style lang="scss">
@import '@/design/index.scss';
body {
  overflow-x: auto !important ;
}
</style>
