export default [
  {
    name: 'nomenclatura_tradicional',
    title: 'Nombre tradicional',
    sortField: 'nomenclatura_tradiciona',
    mandatory: true,
  },
  {
    name: 'nomenclatura_iupac',
    title: 'Nombre IUPAC',
    sortField: 'nomenclatura_iupa',
    mandatory: true,
  },
  {
    name: 'formula',
    title: 'Fórmula',
    sortField: 'formula',
    mandatory: true,
  },
  {
    name: 'informacion',
    title: 'Información',
    sortField: 'informacion',
    titleClass: 'center aligned',
    dataClass: 'center aligned',
    mandatory: false,
  },
];
