<template>
  <div id="app">
    <div class="section content-title-group">
      <h2 class="title">{{ titulo }}</h2>
    </div>
    <!--<FilterBar :mensajeFiltro="mensajeFiltro" />-->
    <!--style="margin-left:35px!important;"-->
    <section class="main" role="main" id="main">
      <div class="">
        <div class="small-12 medium-12 large-12 columns">
          <div class="">
            <div class="">
              <main class="pt-wrap">
                <div class="pt-table">
                  <section
                    v-for="elemento in fields"
                    :class="elemento.element_clases"
                    :id="elemento.element_symbol.toLowerCase()"
                    v-bind:key="elemento.element_symbol.toLowerCase()"
                  >
                    <a
                      href="#dialogoModal"
                      data-toggle="modal"
                      data-target="#dialogoModal"
                      @click="
                        cargarModalElemento(
                          elemento.element_onclick_id,
                          elemento.element_name
                        )
                      "
                    >
                      <div class="pt-element-number">
                        {{ elemento.element_number }}
                      </div>
                      <h2 class="pt-element-symbol">
                        {{ elemento.element_symbol }}
                      </h2>
                      <div class="pt-element-name">
                        {{ elemento.element_name }}
                      </div>
                      <div class="pt-element-weight">
                        {{ elemento.element_weight }}
                      </div></a
                    >
                  </section>
                  <section class="pt-element pt-lanthanide" id="lan">
                    <div class="pt-element-number">&nbsp;</div>
                    <h2 class="pt-element-symbol">&nbsp;</h2>
                    <div class="pt-element-name">Lantánidos</div>
                  </section>
                  <section class="pt-element pt-actinide" id="act">
                    <div class="pt-element-number">&nbsp;</div>
                    <h2 class="pt-element-symbol">&nbsp;</h2>
                    <div class="pt-element-name">Actínidos</div>
                  </section>
                </div>
              </main>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!--<MapModal />-->
    <!-- Modal -->
    <div
      class="modal fade"
      id="dialogoModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="dialogoModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="dialogoModalLabel">Modal title</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div id="textoModal" class="modal-body">
            ...
          </div>
          <!--<div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" class="btn btn-primary">Save changes</button>
      </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TPDatos from './data/TablaPeriodica-Elementos-Datos.js';
import TPInformacion from './data/TablaPeriodica-Elementos-Informacion.js';
//import FilterBar from '../components/SelectionBar4';

import Vue from 'vue';
import VueEvents from 'vue-events';

require('./css/tabla-periodica.css');

Vue.use(VueEvents);
//Vue.component('filter-bar', FilterBar);

export default {
  name: 'app',
  components: {
    //FilterBar,
    //MapModal,
  },
  data() {
    return {
      titulo: 'Tabla periódica',
      fields: TPDatos,
      informacion: TPInformacion,
      lineas: [],
      moreParams: {},
      actualizado: 'Cargando...',
      resultados: '',
      mensajeFiltro: 'nombre,elementos,tipo',
    };
  },
  created() {},
  methods: {
    cargarModalElemento: function(id, nombreModal) {
      id = id.replace('#', '');
      this.informacion.filter(function(elem) {
        if (elem.element_data_id == id) {
          document.getElementById('textoModal').innerHTML =
            elem.element_data_content;
          document.getElementById('dialogoModalLabel').innerText = nombreModal;
          // eslint-disable-next-line no-undef
          $('#dialogoModal').modal({backdrop: false});
        }
      });
    },
  },
};
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  overflow-x: auto !important ;
}
</style>
