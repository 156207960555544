<template>
  <div id="app">
    <div class="section content-title-group">
      <h2 class="title">{{ titulo }}</h2>
    </div>
    <FilterBar :mensajeFiltro="mensajeFiltro" />
    <div class="notification is-info">
      <pre>{{ actualizado }} {{ resultados }}</pre>
    </div>
    <vuetable ref="table" :fields="fields" :api-mode="false" :data="lineas">
      <template slot="actions" slot-scope="props">
        <div class="custom-actions">
          <button
            class="ui basic button"
            @click="onAction('view-item', props.rowData, props.rowIndex)"
          >
            <i class="zoom icon"></i>
          </button>
        </div>
      </template>
    </vuetable>
    <!--<MapModal />-->
  </div>
</template>

<script>
import Vuetable from 'vuetable-2';
import FieldDefs from './defs/Compuestos-TableFieldDefs.js';
import FilterBar from '../components/SelectionBar0';
//import MapModal from './components-map/MapModal';
import Vue from 'vue';
import VueEvents from 'vue-events';
import {_tomarCompuestos} from './scripts/custompages';
Vue.use(VueEvents);
Vue.component('filter-bar', FilterBar);
import {mapGetters} from 'vuex';

export default {
  name: 'app',
  components: {
    Vuetable,
    FilterBar,
    //MapModal,
  },
  computed: {
    ...mapGetters(['user']),
  },
  data() {
    return {
      titulo: 'Compuestos químicos',
      fields: FieldDefs,
      lineas: [],
      lineas_back: null,
      moreParams: {},
      actualizado: 'Cargando...',
      resultados: '',
      mensajeFiltro: 'nombre,elementos,tipo',
    };
  },
  created() {},
  methods: {
    clone: function(obj) {
      if (null == obj || 'object' != typeof obj) return obj;
      var copy = obj.constructor();
      for (var attr in obj) {
        if (obj.hasOwnProperty(attr)) copy[attr] = obj[attr];
      }
      return copy;
    },
    tomarFechaYHoraFormateada: function() {
      var date = new Date();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes;
      var strTime = hours + ':' + minutes + ' ' + ampm;
      var month = date.getMonth() + 1;
      if (month < 10) {
        month = '0' + month;
      }
      var formatedDate =
        '' +
        date.getDate() +
        '/' +
        month +
        '/' +
        date.getFullYear() +
        '  ' +
        strTime;
      return formatedDate;
    },
    // eslint-disable-next-line no-unused-vars
    resetearFiltro: function(operacion) {
      //console.log(operacion);
      if (
        this.lineas_back !== null &&
        this.lineas_back !== undefined &&
        this.lineas_back.length > 0
      ) {
        this.lineas = JSON.parse(JSON.stringify(this.lineas_back));
      }
      this.resultados = ' ' + this.lineas.length;
      if (this.lineas.length === 1) {
        this.resultados += ' registro obtenido';
      } else {
        this.resultados += ' registros obtenidos';
      }
    },
    filterValuePart: function(part) {
      //console.log(part);
      if (part.length > 0) {
        //console.log(this.lineas);
        part = part.toLowerCase();
        let arr = this.lineas.filter(function(el) {
          return (
            el.nomenclatura_iupac.toLowerCase().indexOf(part) > -1 ||
            el.nomenclatura_tradicional.toLowerCase().indexOf(part) > -1 ||
            el.formula.toLowerCase().indexOf(part) > -1
          );
        });
        this.lineas = arr;
        this.resultados = ' ' + this.lineas.length;
        if (this.lineas.length === 1) {
          this.resultados += ' registro obtenido';
        } else {
          this.resultados += ' registros obtenidos';
        }
      }
    },
    sortResults: function(prop, asc, numeric) {
      let arr = JSON.parse(JSON.stringify(this.lineas));
      if (numeric) {
        arr.sort(function(a, b) {
          if (asc) {
            return a[prop] - b[prop];
          } else {
            return b[prop] - a[prop];
          }
        });
      } else {
        arr.sort(function(a, b) {
          if (asc) {
            return a[prop] > b[prop] ? 1 : a[prop] < b[prop] ? -1 : 0;
          } else {
            return b[prop] > a[prop] ? 1 : b[prop] < a[prop] ? -1 : 0;
          }
        });
      }
      //console.log(arr);
      this.lineas = arr;
      this.$refs.table.refresh();
    },
  },
  mounted() {
    let objectReference = this;
    //let iphost = 'http://172.16.244.128:3000';
    //Cuando quiero que esto se acceda desde afuera tengo que poner la ip que va a exponer
    //la api. sino, si es local, uso localhost.
    _tomarCompuestos(this.user)
      .then(resp => {
        //console.log(resp.data);
        objectReference.lineas = JSON.parse(JSON.stringify(resp.data));
        objectReference.lineas_back = JSON.parse(JSON.stringify(resp.data));
        objectReference.actualizado =
          'Actualizado: ' + objectReference.tomarFechaYHoraFormateada();
        objectReference.resetearFiltro('reseteo');
      })
      // eslint-disable-next-line no-unused-vars
      .catch(error => {
        //console.log(error);
      });
    this.$events.$on('filter-set', eventData =>
      this.filterValuePart(eventData)
    );
    this.$events.$on('filter-reset', eventData =>
      this.resetearFiltro(eventData)
    );

    let selector = '#_informacion';
    let elemento = selector + ' i';
    document
      .querySelector(selector)
      .addEventListener('DOMSubtreeModified', function() {
        if (
          document.querySelector(elemento) !== null &&
          (document.querySelector(selector).className.indexOf('sorted-asc') >
            -1 ||
            document.querySelector(selector).className.indexOf('sorted-desc') >
              -1)
        ) {
          let propiedad = selector.replace('#_', '');
          //console.log(propiedad);
          let ascendente = false;
          if (
            document.querySelector(selector).className.indexOf('sorted-asc') >
            -1
          )
            ascendente = true;
          objectReference.sortResults(propiedad, ascendente, true);
        }
      });
    let selector_formula = '#_formula';
    let elemento_formula = selector + ' i';
    document
      .querySelector(selector_formula)
      .addEventListener('DOMSubtreeModified', function() {
        if (
          document.querySelector(elemento_formula) !== null &&
          (document
            .querySelector(selector_formula)
            .className.indexOf('sorted-asc') > -1 ||
            document
              .querySelector(selector_formula)
              .className.indexOf('sorted-desc') > -1)
        ) {
          let propiedad_formula = selector_formula.replace('#_', '');
          //console.log(propiedad_formula);
          let ascendente_formula = false;
          if (
            document
              .querySelector(selector_formula)
              .className.indexOf('sorted-asc') > -1
          )
            ascendente_formula = true;
          objectReference.sortResults(
            propiedad_formula,
            ascendente_formula,
            false
          );
        }
      });
    let selector_nomenclatura_iupac = '#_nomenclatura_iupac';
    let elemento_nomenclatura_iupac = selector + ' i';
    document
      .querySelector(selector_nomenclatura_iupac)
      .addEventListener('DOMSubtreeModified', function() {
        if (
          document.querySelector(elemento_nomenclatura_iupac) !== null &&
          (document
            .querySelector(selector_nomenclatura_iupac)
            .className.indexOf('sorted-asc') > -1 ||
            document
              .querySelector(selector_nomenclatura_iupac)
              .className.indexOf('sorted-desc') > -1)
        ) {
          let propiedad_nomenclatura_iupac = selector_nomenclatura_iupac.replace(
            '#_',
            ''
          );
          //console.log(propiedad_nomenclatura_iupac);
          let ascendente_nomenclatura_iupac = false;
          if (
            document
              .querySelector(selector_nomenclatura_iupac)
              .className.indexOf('sorted-asc') > -1
          )
            ascendente_nomenclatura_iupac = true;
          objectReference.sortResults(
            propiedad_nomenclatura_iupac,
            ascendente_nomenclatura_iupac,
            false
          );
        }
      });
    let selector_nomenclatura_tradicional = '#_nomenclatura_tradicional';
    let elemento_nomenclatura_tradicional = selector + ' i';
    document
      .querySelector(selector_nomenclatura_tradicional)
      .addEventListener('DOMSubtreeModified', function() {
        if (
          document.querySelector(elemento_nomenclatura_tradicional) !== null &&
          (document
            .querySelector(selector_nomenclatura_tradicional)
            .className.indexOf('sorted-asc') > -1 ||
            document
              .querySelector(selector_nomenclatura_tradicional)
              .className.indexOf('sorted-desc') > -1)
        ) {
          let propiedad_nomenclatura_tradicional = selector_nomenclatura_tradicional.replace(
            '#_',
            ''
          );
          //console.log(propiedad_nomenclatura_tradicional);
          let ascendente_nomenclatura_tradicional = false;
          if (
            document
              .querySelector(selector_nomenclatura_tradicional)
              .className.indexOf('sorted-asc') > -1
          )
            ascendente_nomenclatura_tradicional = true;
          objectReference.sortResults(
            propiedad_nomenclatura_tradicional,
            ascendente_nomenclatura_tradicional,
            false
          );
        }
      });

    //sortByShortName();
  },
};
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
<style lang="scss">
@import '@/design/index.scss';
</style>
