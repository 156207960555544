<template>
  <div class="filter-bar ui basic segment grid">
    <div class="ui form">
      <div class="inline field">
        <input
          type="text"
          v-model="filterText"
          class="three wide column"
          @keyup.enter="doFilter"
          placeholder=""
          style="margin-left:-12px;"
        />
        <button class="ui primary button" @click="doFilter">Filtrar</button>
        <button class="ui button" @click="resetFilter">Restablecer</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      mostrar: 'minutos',
      filterText: '',
    };
  },
  methods: {
    doFilter() {
      //console.log('filter-set', this.filterText);
      this.$events.fire('filter-set', this.filterText);
    },
    resetFilter() {
      this.filterText = this.mensajeFiltro;
      //console.log('filter-reset');
      this.$events.fire('filter-reset', 'reseteo');
    },
  },
};
</script>
