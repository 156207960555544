const crypto = require('crypto');
const algorithm = 'aes-256-cbc';
//const iv = 'eyJpdiI6ImFhMzAxYmQwYjE0ODNiZTQ3ZGIzNGZkYWMzYzZjZGNmIiwiZW5jcnlwdGVkRGF0YSI6IjYzNDUwYjdmNTBmM2I3Y2MyN2UwYmQ5MjlmMjRkNTZmZTFkY2Y4M2I3YjM3MjYxZDE4ZmJkMTc2YWUzZjU2ZTZiYmE3ZjcxNmQ0MWFlNGIxYWEwMTY3NjI4OTEyNjAwYjYwMzc5MmJhNmI4MWRjZTk4ZTQ2NDkxYjNjOGNhYmU5NDVjOWQ1ZDM4MzUzNGY5NWIwNDljYTM1Y2VlZjA2YTMyOTRkNzY2YjVjZDE3MTlmYjAxMTgzZjkyNWNhODFiM2UwOTM2NTUyNzVjYWU2M2JlMTE0Y2JjYTU0NmNiMjc2ODY0N2ZkYzc2YjRkYzJiMDk3ZjExMDQzNDI2OGE1ZGY3ZDVkZjYwYTVkZjZlMzg0NWIyMzYzNWIxNTVhYTRiZjU5MzU0OTFkYjY3ZWZjYjZhMjM4YTVkNDg5YzAxMDExMGU1NGE5ODc0MDE1MjQxZjJmZmY4ZDNhNmM1OWI5MjhhMzFlYWI4ZjA3M2ZlZGFlMjIxZWVjNWY4ZWM5MDRmMyJ9';
const key = 'fpK92jhnf914Kahqkecnml96l4apmgOf';
const ivb = 'Hujq643nbtWnl65A';
const keyb = 'RL97zQgC9mOqLy834gbmjabh43RRpp71';
function _encrypt(text) {
  let iv = crypto.randomBytes(16);
  //console.log(text);
  let cipher = crypto.createCipheriv(algorithm, Buffer.from(key), iv);
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return {iv: iv.toString('hex'), encryptedData: encrypted.toString('hex')};
}

// eslint-disable-next-line no-unused-vars
function _decrypt(text) {
  let iv = Buffer.from(text.iv, 'hex');
  let encryptedText = Buffer.from(text.encryptedData, 'hex');
  let decipher = crypto.createDecipheriv(algorithm, Buffer.from(key), iv);
  let decrypted = decipher.update(encryptedText);
  decrypted = Buffer.concat([decrypted, decipher.final()]);
  return decrypted.toString();
}
function _encryptPass(text) {
  let iv = ivb;
  //console.log(text);
  let cipher = crypto.createCipheriv(algorithm, Buffer.from(keyb), iv);
  let encrypted = cipher.update(text);
  encrypted = Buffer.concat([encrypted, cipher.final()]);
  return {encryptedData: encrypted.toString('hex')};
}
export {_encrypt, _decrypt, _encryptPass};
