import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/home.vue';
//import Homedtt from '../views/Home_dtt.vue';
Vue.use(VueRouter);
//const host = window.location.host;
let routes;

    routes = [
        {
          path: '/',
          redirect: '/home',
        },
        {
          path: '/home',
          name: 'home',
          component: Home,
        },
        {
          path: '/login',
          name: 'login',
          component: () => import('../views/login'),
        },
        {
          path: '/create-space',
          name: 'Crear espacio',
          component: () => import('../views/create-space'),
        },
        {
          path: '/create-page',
          name: 'Crear página',
          component: () => import('../views/create-page'),
        },
        {
          path: '/edit-page',
          name: 'editarpagina',
          component: () => import('../views/edit-page'),
        },
        /*{
            path: '/create-concept',
            name: 'crear concepto',
            component: () => import('./views/create-concept'),
          },*/
    ];
  


const router = new VueRouter({
  mode: 'history',
  routes,
});

export default router;
