<template>
  <div id="app">
    <div class="section content-title-group">
      <h2 class="title">{{ nombrePagina }}</h2>
    </div>
    <!-- eslint-disable-next-line-->
    <br/>
    <Accordion :informacion="informacion" :paginaid="numeroDePagina"/>

      
  </div>
</template>

<script>
//import FilterBar from '../components/SelectionBar4';

import Accordion from '../components/Accordion';
import Vue from 'vue';
import VueEvents from 'vue-events';
//import { CollapsePlugin } from 'bootstrap-vue'
//Vue.use(CollapsePlugin);

Vue.use(VueEvents);
//Vue.component('filter-bar', FilterBar);

export default {
  name: 'app',
  props: {
    nombrePagina: String,
    informacion: Array,
    numeroDePagina: String,
  },
  components: {
    Accordion,
    //FilterBar,
    //MapModal,
  },
  data() {
    return {
      titulo: '',
    };
  },
  mounted(){

  },
  created() {

  },
  methods: {
    cargarModalElemento: function(id, nombreModal) {
      id = id.replace('#', '');
      this.informacion.filter(function(elem) {
        if (elem.element_data_id == id) {
          document.getElementById('textoModal').innerHTML =
            elem.element_data_content;
          document.getElementById('exampleModalLabel').innerText = nombreModal;
          // eslint-disable-next-line no-undef
          $('#exampleModal').modal({backdrop: false});
        }
      });
    },
  },
};
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
<style lang="scss">
@import '@/design/index.scss';
body {
  overflow-x: auto !important ;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
</style>
