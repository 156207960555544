import Vue from 'vue';
import App from './App';
//import App from '@/App'; * from
import router from './router/router';
import VueCookies from 'vue-cookies';
import 'core-js';
import Loader from './views/loader';
import Tabla_Periodica from './views/tabla_periodica';
import Compuestos from './views/compuestos';
import Home from './views/home';
import store from './store/index';
import {mapGetters} from 'vuex';

Vue.config.productionTip = false;
Vue.use(VueCookies);
Vue.config.ignoredElements = ['tinycom'];
//console.log(Vue.version);
new Vue({
  computed: {
    ...mapGetters(['nav']),
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    nav(newValue, oldValue) {
      //console.log(`Updating from ${oldValue} to ${newValue}`);
      // Do whatever makes sense now
      if (newValue !== null && newValue !== undefined) {
        this.getDynamicRoutes(newValue, true);
      }
      if (newValue === null || newValue === undefined) {
        //this.getDynamicRoutes(oldValue, false);
      }
    },
  },
  data() {
    return {
      componentes: [Loader, Tabla_Periodica, Compuestos, Home],
      ejecutado: 1,
      jsonArray: Array,
    };
  },

  methods: {
    // eslint-disable-next-line no-unused-vars
    getDynamicRoutes: function(nav, addRoutes) {
      if (this.nav !== null && this.nav !== undefined) {
        this.processData(nav, addRoutes);
      }
    },
    processData: function(routes, addRoutes) {
      //console.log(routes);
      //console.log(routes.length);
      let contadornivel1 = 0;
      let contador = 0;
      let cantidadComponentes = this.componentes.length;
      let componenteActual = '';
      let newRoute;
      for (
        contadornivel1 = 0;
        contadornivel1 < routes.length;
        contadornivel1++
      ) {
        let pathNivelUno = '/' + routes[contadornivel1].navegacion.path;
        newRoute = [
          {
            //name: routes[contadornivel1].name,
            path: pathNivelUno,
            component: null,
            //meta: routes[contadornivel1].navegacion.meta,
          },
        ];
        componenteActual = '' + routes[contadornivel1].navegacion.component;
        if (componenteActual !== '') {
          for (contador = 0; contador < cantidadComponentes; contador++) {
            let ruta = this.componentes[contador].__file;

            if (ruta.indexOf(routes[contadornivel1].component) > -1) {
              newRoute[0].component = this.componentes[contador];
              //console.log('asigno componente');
            }
          }
        }
        //console.log(newRoute);
        this.$router.addRoutes(newRoute);
        let contadornivel2 = 0;
        let nivel2 = routes[contadornivel1].navegacion.members;
        //console.log(routes[contadornivel1].members);
        for (
          contadornivel2 = 0;
          contadornivel2 < nivel2.length;
          contadornivel2++
        ) {
          let pathnivel2 = nivel2[contadornivel2].path;
          if (addRoutes) {
            let newRouteNivel2 = [
              {
                name: nivel2[contadornivel2].name,
                path: pathnivel2,
                component: null,
                meta: nivel2[contadornivel2].meta,
              },
            ];
            componenteActual = '' + nivel2[contadornivel2].component;
            if (componenteActual !== '') {
              for (contador = 0; contador < cantidadComponentes; contador++) {
                let ruta = this.componentes[contador].__file;
                if (ruta.indexOf(nivel2[contadornivel2].component) > -1) {
                  newRouteNivel2[0].component = this.componentes[contador];
                  //console.log('asigno componente');
                }
              }
            }
            
            this.$router.addRoutes(newRouteNivel2);
          } else {
            this.$router.removeRoute(nivel2[contadornivel2].name);
          }
          let contadornivel3 = 0;
          let nivel3 = nivel2[contadornivel2].members;
          for (
            contadornivel3 = 0;
            contadornivel3 < nivel3.length;
            contadornivel3++
          ) {
            let pathnivel3 = nivel3[contadornivel3].path;
            if (addRoutes) {
              let newRouteNivel3 = [
                {
                  name: nivel3[contadornivel3].name,
                  path: pathnivel3,
                  component: null,
                  meta: nivel3[contadornivel3].meta,
                },
              ];
              componenteActual = '' + nivel3[contadornivel3].component;
              if (componenteActual !== '') {
                for (contador = 0; contador < cantidadComponentes; contador++) {
                  let ruta = this.componentes[contador].__file;

                  if (ruta!== null && ruta !== undefined &&ruta.indexOf(nivel3[contadornivel3].component) > -1) {
                    newRouteNivel3[0].component = this.componentes[contador];
                    //console.log('asigno componente');
                  }
                }
              }
              this.$router.addRoutes(newRouteNivel3);
            } else {
              this.$router.removeRoute(nivel3[contadornivel3].name);
            }
            let contadornivel4 = 0;
            let nivel4 = nivel3[contadornivel3].members;
            for (
              contadornivel4 = 0;
              contadornivel4 < nivel4.length;
              contadornivel4++
            ) {
              let pathnivel4 = nivel4[contadornivel4].path;
              if (addRoutes) {
                let newRouteNivel4 = [
                  {
                    name: nivel4[contadornivel4].name,
                    path: pathnivel4,
                    component: null,
                    meta: nivel4[contadornivel4].meta,
                  },
                ];
                componenteActual = '' + nivel4[contadornivel4].component;
                if (componenteActual !== '') {
                  for (
                    contador = 0;
                    contador < cantidadComponentes;
                    contador++
                  ) {
                    let ruta = this.componentes[contador].__file;

                    if (ruta!== null && ruta !== undefined && ruta.indexOf(nivel4[contadornivel4].component) > -1) {
                      newRouteNivel4[0].component = this.componentes[contador];
                      //console.log('asigno componente');
                    }
                  }
                }
                this.$router.addRoutes(newRouteNivel4);
              } else {
                this.$router.removeRoute(nivel4[contadornivel4].name);
              }
            }
          }
        }
      }
    },
  },

  created() {
    this.getDynamicRoutes(this.nav, true);
    let cookieExistente = '' + this.$cookies.get('lastNavDate');
    if (cookieExistente === 'null') {
      this.$cookies.set(
        'lastNavDate',
        new Date().getTime(),
        '2021-09-15T01:58:16.533Z'
      );
    }
  },
  router,
  store,
  render: h => h(App),
}).$mount('#app');
//esto es para poder identificar paginas que no quiero que se carguen
//es una manera de inhabilitar link
router.beforeEach((to, from, next) => {
  //console.log('router is being called'+ JSON.stringify(to)+ '  '+from);
  /*document.body.style.backgroundImage = '';
  if (!to.meta.avoidLoading) {
    next();
  }*/
  const publicPages = ['/login', '', '/home'];
  const backgroundPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  let loggedIn;
  try {
    loggedIn = JSON.parse(localStorage.getItem('vuex')).userInfo;
  } catch {
    loggedIn = false;
  }

  const backgroundNone = !backgroundPages.includes(to.path);
  if (backgroundNone) {
    document.body.style.backgroundImage = '';
  }
  if (authRequired && !loggedIn) {
    return next('/login');
  }

  next();
});

/*VueCookies.set(
  'lastNavDate',
  new Date().getTime(),
  '2021-09-15T01:58:16.533Z'
);
*/

// Nodejs encryption with CTR
