const crearPaginaPath = '/cms/pagina/crear';
const obtenerContenidoPaginaPath = '/cms/pagina/obtenercontenido';
const obtenerPaginaPath = '/cms/pagina/obtener';
const modificarPaginaPath = '/cms/pagina/modificar';
import {_postSec, _getSec} from './rest';

export {
  _crearPagina,
  _obtenerContenidoPagina,
  _obtenerPagina,
  _modificarPagina,
};

let loggedIn;
try {
  loggedIn = JSON.parse(localStorage.getItem('vuex')).userInfo;
} catch {
  loggedIn = false;
}

const _crearPagina = datos => {
  return new Promise(resolve => {
    _crearPaginaCall(datos, data => {
      if (data.status === 200) {
        data = {status: data.status, msg: 'Pagina creada exitosamente.'};
      }
      resolve(data);
    });
  });
};
function _crearPaginaCall(datos, callback) {
  _postSec(crearPaginaPath, datos, loggedIn, function(resultado) {
    callback(resultado);
  });
}
const _modificarPagina = datos => {
  return new Promise(resolve => {
    _modificarPaginaCall(datos, data => {
      if (data.status === 200) {
        data = {status: data.status, msg: 'Pagina modificada exitosamente.'};
      }
      resolve(data);
    });
  });
};
function _modificarPaginaCall(datos, callback) {
  _postSec(modificarPaginaPath, datos, loggedIn, function(resultado) {
    callback(resultado);
  });
}

const _obtenerContenidoPagina = datos => {
  return new Promise(resolve => {
    _obtenerContenidoPaginaCall(datos, data => {
      resolve(data);
    });
  });
};
function _obtenerContenidoPaginaCall(datos, callback) {
  let path = obtenerContenidoPaginaPath + '/' + datos;
  console.log(obtenerContenidoPaginaPath + '/' + datos);
  _getSec(path, loggedIn, function(resultado) {
    callback(resultado);
  });
}
const _obtenerPagina = datos => {
  console.log('llamando obtener pagina');
  return new Promise(resolve => {
    _obtenerPaginaCall(datos, data => {
      resolve(data);
    });
  });
};
function _obtenerPaginaCall(datos, callback) {
  let path = obtenerPaginaPath + '/' + datos;
  _getSec(path, loggedIn, function(resultado) {
    callback(resultado);
  });
}
