var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "panel-group", attrs: { id: "desplegable" } },
    _vm._l(_vm.informacion, function (elemento) {
      return _c(
        "div",
        { key: elemento.id, staticClass: "panel panel-default" },
        [
          _c(
            "div",
            {
              staticClass: "panel-title panel-heading accordion-toggle",
              attrs: {
                "data-toggle": "collapse",
                "data-parent": "desplegable",
                href: "#collapse" + elemento.id,
                role: "tab",
                id: "titulo" + elemento.id,
              },
              on: {
                click: function ($event) {
                  return _vm.ocultarotros(elemento.id)
                },
              },
            },
            [
              _c("h2", {
                staticClass: "texto30px",
                domProps: { innerHTML: _vm._s(elemento.titulo) },
              }),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "panel-collapse collapse",
              attrs: { id: "collapse" + elemento.id },
            },
            [
              _c("div", {
                staticClass:
                  "texto20px textojustificado escroleable400px panel-body",
                domProps: { innerHTML: _vm._s(elemento.contenido) },
              }),
            ]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }