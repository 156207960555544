/* eslint-disable */
export default [
  {
    element_data_id: 'h1',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-nonmetal pt-gas g1 p1" id="h"><div class="pt-element-number">1</div><h2 class="pt-element-symbol">H </h2><div class="pt-element-name">Hidrógeno</div><div class="pt-element-weight">1.008</div></section></div><div class="pt-col pt-col-desc"><p>El hidrógeno constituye más del 90 % de los átomos del universo y en 1776 se reconoció por primera vez como una sustancia diferente. En la Tierra, se encuentra frecuentemente combinado con oxígeno en forma de agua y también está presente en las plantas, el petróleo, el carbón y otra materia orgánica.</p><p>El hidrógeno líquido se usa en la criogenia y para estudiar la superconductividad. Los isótopos deuterio y tritio se utilizan como combustible de reactores de fusión nuclear. Los reactores nucleares producen tritio y este se utiliza para hacer bombas de hidrógeno.</p><p>Sus usos industriales incluyen hidrogenación (grasas y aceites), producción de metanol, hidrodealquilación, fraccionamiento hidráulico e hidrodesulfuración. También se usa como combustible para cohetes, para soldar, fabricar ácido clorhídrico, reducir menas de metales e inflar globos.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 1.008 <br><strong>Punto de fusión:</strong> -259.1°C <br><strong>Punto de ebullición:</strong> -252.9°C <br><strong>Fase a temperatura y presión estándar:</strong> Gas <br><strong>Configuración electrónica:</strong> 1s<sup>1</sup><br><strong>Estados de oxidación comunes:</strong> ±1 <br><strong>Número de electrones de valencia:</strong> 1 </p></div>',
  },
  {
    element_data_id: 'he2',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-noble pt-gas g18 p1" id="he"><div class="pt-element-number">2</div><h2 class="pt-element-symbol">He</h2><div class="pt-element-name">Helio</div><div class="pt-element-weight">4.003</div></section></div><div class="pt-col pt-col-desc"><p>El primer indicio de helio se detectó durante el eclipse solar de 1868. Es el segundo elemento más abundante y se puede extraer a partir de gas natural. La mayoría del helio en Estados Unidos se obtiene en pozos ubicados en Texas, Oklahoma y Kansas.</p><p>El helio se emplea extensamente en el campo de la criogenia y en investigaciones de superconductividad. Permanece en su forma líquida hasta el cero absoluto, pero se solidifica rápidamente ante un aumento de presión. Se conocen siete isótopos del helio.</p><p>El helio se utiliza en el crecimiento de cristales de silicio y de germanio, en las soldaduras por arco y en la producción de titanio y zirconio, para enfriar reactores nucleares y como gas en los túneles de viento supersónicos.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 4.0026 <br><strong>Punto de fusión:</strong> -272.2°C <br><strong>Punto de ebullición:</strong> -268.9°C <br><strong>Fase a temperatura y presión estándar:</strong> Gas <br><strong>Configuración electrónica:</strong> 1s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> 0 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'li3',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-alkali pt-solid g1 p2" id="li"><div class="pt-element-number">3</div><h2 class="pt-element-symbol">Li</h2><div class="pt-element-name">Litio</div><div class="pt-element-weight">6.941</div></section></div><div class="pt-col pt-col-desc"><p>El litio se descubrió en 1817 y es el más ligero de todos los metales. El litio no se encuentra en estado libre en la naturaleza, sino combinado en todas las rocas ígneas, manantiales minerales y en los minerales lepidolita, espodumena, petalita y ambligonita.</p><p>El litio tiene una apariencia similar a la plata, como otros metales alcalinos. Reacciona con el agua, otorga a las llamas una coloración carmesí y se quema con un deslumbrante color blanco. Es corrosivo y se debe manipular con cuidado.</p><p>El litio es útil para transferencias térmicas y para aplicaciones nucleares, y se ha usado en aleaciones y para síntesis de compuestos orgánicos. También se utiliza como material para el ánodo de las baterías y en vidrios y cerámicas.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 6.941 <br><strong>Punto de fusión:</strong> 180.5°C <br><strong>Punto de ebullición:</strong> 1342°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [He]2s<sup>1</sup><br><strong>Estados de oxidación comunes:</strong> +1 <br><strong>Número de electrones de valencia:</strong> 1 </p></div>',
  },
  {
    element_data_id: 'be4',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-alkaline pt-solid g2 p2" id="be"><div class="pt-element-number">4</div><h2 class="pt-element-symbol">Be</h2><div class="pt-element-name">Berilio</div><div class="pt-element-weight">9.012</div></section></div><div class="pt-col pt-col-desc"><p>El berilio se descubrió como un óxido en berilos y esmeraldas en 1798. El berilio se encuentra en la bertrandita, el berilo, el crisoberilo y la fenaquita, y en muchos otros minerales.</p><p>Este metal, de color gris acerado, es uno de los metales más ligeros y tiene un punto alto de fusión. Es más elástico que el acero, no es magnético, es resistente al ácido nítrico concentrado y tiene una conductividad térmica excelente. El berilio y sus sales son tóxicos y deben manipularse con cuidado.</p><p>La aleación de cobre-berilio se utiliza para resortes, contactos eléctricos, electrodos para soldadura por puntos y herramientas a prueba de chispas. Este elemento también se utiliza como un material estructural para aeronaves de alta velocidad, naves espaciales, satélites y mísiles.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 9.012 <br><strong>Punto de fusión:</strong> 1278°C <br><strong>Punto de ebullición:</strong> 2970°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [He]2s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> 2 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'b5',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-semimetal pt-solid g13 p2" id="b"><div class="pt-element-number">5</div><h2 class="pt-element-symbol">B</h2><div class="pt-element-name">Boro</div><div class="pt-element-weight">10.81</div></section></div><div class="pt-col pt-col-desc"><p>Los compuestos de boro se conocen desde hace miles de años, pero el elemento no se descubrió hasta 1808. No se encuentra libremente en la naturaleza, sino en forma de ácido bórico en aguas termales volcánicas y como boratos. Otras fuentes importantes son las menas de kernita (rasorita) y tincalconita (mena de bórax).</p><p>El boro puro y los boratos no son tóxicos, pero algunos compuestos de hidruros de boro son tóxicos y deben manipularse con cuidado.</p><p>El boro no es un buen conductor de la electricidad a temperatura ambiente, pero su conductividad eléctrica es eficaz a altas temperaturas. En los destellos de fuegos artificiales, el boro proporciona un color verde característico. En su forma pentahidratada, se utiliza para la fabricación de fibra de vidrio aislante y de blanqueador de perborato de sodio.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 10.81 <br><strong>Punto de fusión:</strong> 2079°C <br><strong>Punto de ebullición:</strong> 2550°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [He]2s<sup>2</sup>2p<sup>1</sup><br><strong>Estados de oxidación comunes:</strong> +3 <br><strong>Número de electrones de valencia:</strong> 3    </p></div>',
  },
  {
    element_data_id: 'c6',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-nonmetal pt-solid g14 p2" id="c"><div class="pt-element-number">6</div><h2 class="pt-element-symbol">C</h2><div class="pt-element-name">Carbono</div><div class="pt-element-weight">12.01</div></section></div><div class="pt-col pt-col-desc"><p>El carbono se descubrió en la prehistoria. Está ampliamente distribuido en la naturaleza y se encuentra dentro de las estrellas, cometas y en las atmósferas de la mayoría de planetas.</p><p>El carbono se encuentra en su estado libre en la naturaleza en forma de grafito, diamante y fullereno. También se cree que existe una cuarta forma, conocida como carbono blanco. El carbono tiene siete isótopos, entre los que se encuentran el carbono-12, que se usa como patrón para calcular las masas atómicas y el carbono-14, que se usa para datar madera, especímenes arqueológicos y otros materiales.</p><p>El carbono se encuentra como dióxido de carbono en la atmósfera terrestre y se disuelve en sus aguas naturales. Es parte de las masas rocosas como los carbonatos de calcio (piedras calizas), de magnesio y de hierro. El carbón, el petróleo y el gas natural son principalmente hidrocarburos.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 12.01 <br><strong>Punto de fusión:</strong> 3367°C <br><strong>Punto de ebullición:</strong> 4827°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [He]2s<sup>2</sup>2p<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> ±4 <br><strong>Número de electrones de valencia:</strong> 4      </p></div>',
  },
  {
    element_data_id: 'n7',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-nonmetal pt-gas g15 p2" id="n"><div class="pt-element-number">7</div><h2 class="pt-element-symbol">N</h2><div class="pt-element-name">Nitrógeno</div><div class="pt-element-weight">14.01</div></section></div><div class="pt-col pt-col-desc"><p>El nitrógeno se descubrió en 1772 y se encuentra en los materiales biológicos de todos los seres vivos.</p><p>El nitrógeno es incoloro e inodoro, tanto en su forma gaseosa como líquida, y el gas de nitrógeno (N2) compone el 78,1 % del aire de la Tierra por volumen. Sus compuestos se encuentran en materiales orgánicos y fertilizantes, venenos y explosivos.</p><p>El ciclo del nitrógeno es un proceso fundamental en la naturaleza para los organismos vivos. Aunque el gas nitrógeno es relativamente inerte, las bacterias en el suelo pueden convertir o reducir el nitrógeno a una forma que las plantas pueden utilizar para formar proteínas.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 14.01 <br><strong>Punto de fusión:</strong> -209.9°C <br><strong>Punto de ebullición:</strong> -195.8°C <br><strong>Fase a temperatura y presión estándar:</strong> Gas <br><strong>Configuración electrónica:</strong> [He]2s<sup>2</sup>2p<sup>3</sup><br><strong>Estados de oxidación comunes:</strong> -3 <br><strong>Número de electrones de valencia:</strong> 5</p></div>',
  },
  {
    element_data_id: 'o8',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-nonmetal pt-gas g16 p2" id="o"><div class="pt-element-number">8</div><h2 class="pt-element-symbol">O</h2><div class="pt-element-name">Oxígeno</div><div class="pt-element-weight">16.00</div></section></div><div class="pt-col pt-col-desc"><p>El descubrimiento del oxígeno suele atribuirse a Joseph Priestley. Su forma gaseosa es incolora, inodora e insípida. En sus formas líquida y sólida, el oxígeno es de color azul pálido; ambas son fuertemente paramagnéticas.</p><p>El oxígeno es un componente de cientos de miles de compuestos orgánicos y se combina fácilmente con la mayoría de elementos. El oxígeno tiene nueve isótopos. El ozono (O3), una de sus formas alótropas, se forma cuando se somete el oxígeno a una descarga eléctrica o a luz ultravioleta.</p><p>El gas oxígeno constituye el 21 % de la atmósfera por volumen y este elemento y sus compuestos conforman prácticamente la mitad de la masa de la corteza terrestre. Dos tercios del cuerpo humano y nueve décimas partes del agua son oxígeno.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 16.00 <br><strong>Punto de fusión:</strong> -218.4°C <br><strong>Punto de ebullición:</strong> -183°C <br><strong>Fase a temperatura y presión estándar:</strong> Gas <br><strong>Configuración electrónica:</strong> [He]2s<sup>2</sup>2p<sup>4</sup><br><strong>Estados de oxidación comunes:</strong> -2 <br><strong>Número de electrones de valencia:</strong> 6  </p></div>',
  },
  {
    element_data_id: 'f9',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-halogen pt-gas g17 p2" id="f"><div class="pt-element-number">9</div><h2 class="pt-element-symbol">F</h2><div class="pt-element-name">Flúor</div><div class="pt-element-weight">19.00</div></section></div><div class="pt-col pt-col-desc"><p>El uso de el flúor como fundente se describió en 1529, pero el flúor no se aisló hasta 1866. Es el elemento con mayor electronegatividad y reactividad de todos.</p><p>Su gas es de color amarillo pálido, es corrosivo y reacciona con la mayoría de las sustancias orgánicas e inorgánicas. El flúor puro y los iones de fluoruro son altamente tóxicos y presentan un característico olor penetrante.</p><p>El flúor y sus compuestos ayudan a producir uranio (a partir de hexafluoruro) y más de 100 productos químicos comerciales y plásticos para altas temperaturas. El ácido fluorhídrico se utiliza para grabados en vidrio y los fluoroclorohidrocarburos como refrigerante para aires acondicionados y refrigeración. El fluoruro soluble en agua potable se ha utilizado para ayudar a evitar las caries dentales.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 19.00 <br><strong>Punto de fusión:</strong> -219.8°C <br><strong>Punto de ebullición:</strong> -188.1°C <br><strong>Fase a temperatura y presión estándar:</strong> Gas <br><strong>Configuración electrónica:</strong> [He]2s<sup>2</sup>2p<sup>5</sup><br><strong>Estados de oxidación comunes:</strong> -1 <br><strong>Número de electrones de valencia:</strong> 7  </p></div>',
  },
  {
    element_data_id: 'ne10',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-noble pt-gas g18 p2" id="ne"><div class="pt-element-number">10</div><h2 class="pt-element-symbol">Ne</h2><div class="pt-element-name">Neón</div><div class="pt-element-weight">20.18</div></section></div><div class="pt-col pt-col-desc"><p>El neón se descubrió en 1898 y es un elemento poco frecuente y gaseoso. En su forma natural, es una mezcla de tres isótopos. También se han identificado otros seis isótopos menos estables.</p><p>El neón es muy inerte, pero se ha documentado la existencia de un compuesto de flúor-neón. También forma un hidrato inestable. El neón tiene más capacidad refrigerante por volumen que el helio líquido y triplica la del hidrógeno líquido.</p><p>El neón se usa con mayor frecuencia en avisos publicitarios, pero también es un componente en indicadores de alto voltaje, pararrayos y tubos de ondámetros. El neón también se usa con el helio para fabricar láseres gaseosos.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 20.18 <br><strong>Punto de fusión:</strong> -248°C <br><strong>Punto de ebullición:</strong> -248.7°C <br><strong>Fase a temperatura y presión estándar:</strong> Gas <br><strong>Configuración electrónica:</strong> [He]2s<sup>2</sup>2p<sup>6</sup><br><strong>Estados de oxidación comunes:</strong> 0 <br><strong>Número de electrones de valencia:</strong> 8 </p></div>',
  },
  {
    element_data_id: 'na11',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-alkali pt-solid g1 p3" id="na"><div class="pt-element-number">11</div><h2 class="pt-element-symbol">Na</h2><div class="pt-element-name">Sodio</div><div class="pt-element-weight">22.99</div></section></div><div class="pt-col pt-col-desc"><p>Aunque se reconocía en compuestos, el sodio no se aisló hasta 1807. El sodio es bastante abundante en el Sol y las estrellas, es el cuarto elemento más abundante en la Tierra y es el tipo de metal alcalino que se encuentra con mayor frecuencia.</p><p>El sodio nunca se encuentra en estado libre en la naturaleza. Es un metal blando, brillante y de color plateado que flota en el agua. Puede inflamarse espontáneamente en contacto con el agua y en condiciones normales no arde en contacto con el aire a temperaturas inferiores a 115 °C. Su compuesto más frecuente es el cloruro de sodio (sal común), pero también se encuentra en el nitrato de sodio, la criolita, el anfíbol, la zeolita y muchos otros minerales.</p><p>Los compuestos de sodio son importantes en las industrias del papel, el vidrio, el jabón, los textiles, el petróleo, los productos químicos y la metalúrgica.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 22.99 <br><strong>Punto de fusión:</strong> 97.8°C <br><strong>Punto de ebullición:</strong> 883°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Ne]3s<sup>1</sup><br><strong>Estados de oxidación comunes:</strong> +1 <br><strong>Número de electrones de valencia:</strong> 1 </p></div>',
  },
  {
    element_data_id: 'mg12',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-alkaline pt-solid g2 p3" id="mg"><div class="pt-element-number">12</div><h2 class="pt-element-symbol">Mg</h2><div class="pt-element-name">Magnesio</div><div class="pt-element-weight">24.31</div></section></div><div class="pt-col pt-col-desc"><p>El magnesio se reconoció en 1755 y se aisló por primera vez en 1808. Es el octavo elemento más abundante en la corteza terrestre y se encuentra principalmente en la magnesita, la dolomita y otros minerales.</p><p>El magnesio es un metal ligero, de color blanco plateado y bastante duro. Se deslustra ligeramente en contacto con el aire, arde fácilmente si se calienta y produce una llama blanca deslumbrante.</p><p>Se utiliza para flashes fotográficos, luces de bengala, pirotecnia y bombas incendiarias. Es más ligero que el aluminio y sus aleaciones son esenciales para la construcción de aviones y misiles. El hidróxido (leche de magnesia), el cloruro, el sulfato (sales de Epsom) y el citrato son formas del magnesio que se usan con fines medicinales. El magnesio orgánico es importante para la vida vegetal y animal.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 24.31 <br><strong>Punto de fusión:</strong> 649°C <br><strong>Punto de ebullición:</strong> 1090°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Ne]3s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +2 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'al13',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-basic pt-solid g13 p3" id="al"><div class="pt-element-number">13</div><h2 class="pt-element-symbol">Al</h2><div class="pt-element-name">Aluminio</div><div class="pt-element-weight">26.98</div></section></div><div class="pt-col pt-col-desc"><p>El aluminio se usaba originalmente como astringente y mordiente para tintorería y se aisló en 1827. Originalmente en inglés se le llamó «alumium» (alumio), pero la American Chemical Society adoptó el nombre «aluminum» (aluminio) en 1925.</p><p>El aluminio es el metal más abundante en la corteza terrestre (8,1 %) y se encuentra en la arcilla, la criolita, el granito y muchos otros minerales comunes. El aluminio es de color blanco plateado, ligero, no es magnético y no produce chispas, y está en segundo lugar entre los metales por su maleabilidad y en sexto lugar por su ductilidad.</p><p>El aluminio puro es blando y no es muy fuerte, pero las aleaciones con cobre, magnesio, silicio, manganeso y otros elementos potencian su utilidad.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 26.98 <br><strong>Punto de fusión:</strong> 660°C <br><strong>Punto de ebullición:</strong> 2467°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Ne]3s<sup>2</sup>3p<sup>1</sup><br><strong>Estados de oxidación comunes:</strong> +3 <br><strong>Número de electrones de valencia:</strong> 3 </p></div>',
  },
  {
    element_data_id: 'si14',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-semimetal pt-solid g14 p3" id="si"><div class="pt-element-number">14</div><h2 class="pt-element-symbol">Si</h2><div class="pt-element-name">Silicio</div><div class="pt-element-weight">28.09</div></section></div><div class="pt-col pt-col-desc"><p>El silicio impuro y amorfo se preparó en 1811 y se purificó en 1824. El silicio cristalino, la segunda forma alotrópica del elemento, se preparó en 1854.</p><p>El silicio cristalino es de color grisáceo y tiene un brillo metálico. Aunque es relativamente inerte, reacciona con halógenos y bases diluidos, y no se ve afectado por la mayoría de los ácidos.</p><p>El silicio está presente en el Sol y las estrellas y en los meteoritos conocidos como aerolitos. No se encuentra en la naturaleza en su estado libre, sino que usualmente se presenta en forma de óxido y silicatos. El silicio se encuentra en la arena, el cuarzo, el cristal de roca, la amatista, la ágata, el sílex, el jaspe, el ópalo, el granito, la hornblenda, el amianto, el feldespato, la arcilla, la mica y otros minerales.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 28.09 <br><strong>Punto de fusión:</strong> 1410°C <br><strong>Punto de ebullición:</strong> 2355°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Ne]3s<sup>2</sup>3p<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> ±4 <br><strong>Número de electrones de valencia:</strong> 4 </p></div>',
  },
  {
    element_data_id: 'p15',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-nonmetal pt-solid g15 p3" id="p"><div class="pt-element-number">15</div><h2 class="pt-element-symbol">P</h2><div class="pt-element-name">Fósforo</div><div class="pt-element-weight">30.97</div></section></div><div class="pt-col pt-col-desc"><p>El fósforo se descubrió en 1669 y existe en cuatro o más formas alotrópicas, que incluyen el fósforo blanco (o amarillo), rojo y negro (o violeta). El fósforo es un sólido de color blanco ceroso y es incoloro y transparente en estado puro.</p><p>El fósforo es insoluble en agua, soluble en sulfuro de carbono y se inflama espontáneamente en contacto con el aire. El fósforo es tóxico y tan solo 50 mg corresponden a una dosis letal. Mantenga el fósforo sumergido en agua y manipúlelo con pinzas para evitar quemaduras.</p><p>No se encuentra en la naturaleza en estado libre; suele encontrarse combinado en minerales. Los ácidos fosfóricos concentrados son importantes para la agricultura y los cultivos como componentes de fertilizantes. También se usan para producir cristales especiales, porcelana fina y levadura química.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 30.97 <br><strong>Punto de fusión:</strong> 44.1°C <br><strong>Punto de ebullición:</strong> 280°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Ne]3s<sup>2</sup>3p<sup>3</sup><br><strong>Estados de oxidación comunes:</strong> -3 <br><strong>Número de electrones de valencia:</strong> 5 </p></div>',
  },
  {
    element_data_id: 's16',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-nonmetal pt-solid g16 p3" id="s"><div class="pt-element-number">16</div><h2 class="pt-element-symbol">S</h2><div class="pt-element-name">Azufre</div><div class="pt-element-weight">32.07</div></section></div><div class="pt-col pt-col-desc"><p>El azufre es esencial para la vida y es un componente menor de grasas, líquidos y secreciones corporales y minerales óseos. Es un sólido inodoro, de color amarillo pálido y frágil que es insoluble en agua, pero es soluble en sulfuro de carbono. Se puede encontrar en varias formas: gaseosa, líquida o sólida.</p><p>El azufre de alta pureza está disponible comercialmente en purezas superiores al 99,999 %. Existen once isótopos de azufre y los cuatro que se encuentran en la naturaleza son radioactivos.</p><p>El azufre se encuentra de forma natural en las proximidades de los volcanes y aguas termales. Se encuentra en las piritas, la galena, la esfalerita, el cinabrio, la estibina, el yeso, las sales de Epsom, la celestina, la baritina y otros minerales. El azufre también se encuentra en meteoritos y en forma de gas natural y petróleo crudo.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 32.07 <br><strong>Punto de fusión:</strong> 112.8°C <br><strong>Punto de ebullición:</strong> 444.7°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Ne]3s<sup>2</sup>3p<sup>4</sup><br><strong>Estados de oxidación comunes:</strong> -2 <br><strong>Número de electrones de valencia:</strong> 6 </p></div>',
  },
  {
    element_data_id: 'cl17',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-halogen pt-gas g17 p3" id="cl"><div class="pt-element-number">17</div><h2 class="pt-element-symbol">Cl</h2><div class="pt-element-name">Cloro</div><div class="pt-element-weight">35.45</div></section></div><div class="pt-col pt-col-desc"><p>El cloro se descubrió en 1774 y se identificó claramente como un elemento y se le puso un nombre en 1810. El cloro forma parte del grupo de elementos halógenos o formadores de sales.</p><p>En la naturaleza, el cloro solo se encuentra en un estado combinado, la mayoría de las veces como sal común (NaCI), carnalita y silvina. Como gas, es de color amarillo verdoso y combina con prácticamente todos los elementos. En su forma gaseosa, el cloro es un irritante respiratorio y la exposición prolongada puede ser mortal.</p><p>El cloro se usa para producir agua potable segura y para la fabricación de productos de papel, tintes, textiles, productos de petróleo, medicamentos, antisépticos, insecticidas, alimentos, disolventes, pinturas, plásticos y muchos otros productos.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 35.45 <br><strong>Punto de fusión:</strong> -101°C <br><strong>Punto de ebullición:</strong> -34.6°C <br><strong>Fase a temperatura y presión estándar:</strong> Gas <br><strong>Configuración electrónica:</strong> [Ne]3s<sup>2</sup>3p<sup>5</sup><br><strong>Estados de oxidación comunes:</strong> -1 <br><strong>Número de electrones de valencia:</strong> 7 </p></div>',
  },
  {
    element_data_id: 'ar18',
    element_data_content: ' <div class="pt-col pt-col-element"><section class="pt-element pt-noble pt-gas g18 p3" id="ar"><div class="pt-element-number">18</div><h2 class="pt-element-symbol">Ar</h2><div class="pt-element-name">Argón</div><div class="pt-element-weight">39.95</div></section></div><div class="pt-col pt-col-desc"><p>Se sospechaba de la presencia del argón ya desde 1785 y se descubrió de forma oficial en 1894. Tanto en su forma gaseosa como líquida, el argón es incoloro e inodoro.</p><p>El argón es un gas inerte y no forma verdaderos compuestos químicos. En su forma natural, el argón se compone de una mezcla de tres isótopos. También existen doce isótopos radioactivos.</p><p>El argón se usa para bombillas de lámparas incandescentes y fluorescentes, y para los fototubos y tubos incandescentes. El argón se usa como un gas de protección para la soldadura y corte con arco, actúa como una manta en la producción de titanio y otros elementos reactivos, y ofrece una atmósfera protectora para el crecimiento de cristales de silicio y germanio.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 39.95 <br><strong>Punto de fusión:</strong> -189.2°C <br><strong>Punto de ebullición:</strong> -185.7°C <br><strong>Fase a temperatura y presión estándar:</strong> Gas <br><strong>Configuración electrónica:</strong> [Ne]3s<sup>2</sup>3p<sup>6</sup><br><strong>Estados de oxidación comunes:</strong> 0 <br><strong>Número de electrones de valencia:</strong> 8 </p></div>',
  },
  {
    element_data_id: 'k19',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-alkali pt-solid g1 p4" id="k"><div class="pt-element-number">19</div><h2 class="pt-element-symbol">K</h2><div class="pt-element-name">Potasio</div><div class="pt-element-weight">39.10</div></section></div><div class="pt-col pt-col-desc"><p>El potasio se descubrió en 1807 y es el séptimo metal más abundante. El potasio no se encuentra en la naturaleza en estado libre y la mayoría de los minerales que lo contienen son insolubles, por lo que es difícil de obtener.</p><p>Es el metal más reactivo y electropositivo, y el más ligero después del litio. El potasio es blando y de color plateado, se puede cortar con un cuchillo, se oxida rápidamente en contacto con el aire y debe almacenarse sumergido en un aceite mineral como el queroseno. Se descompone en el agua para producir hidrógeno y se enciende espontáneamente en contacto con el aire. El potasio tiene 17 isótopos, entre los que se encuentra una forma radioactiva.</p><p>La mayor demanda de potasio es en fertilizantes debido a que es esencial para el crecimiento de las plantas. También son importantes muchas sales de potasio, como el hidróxido, el nitrato, el carbonato, el cloruro, el clorato, el bromuro, el yoduro, el cianuro, el sulfato, el cromato y el dicromato.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 39.10 <br><strong>Punto de fusión:</strong> 63.25°C <br><strong>Punto de ebullición:</strong> 760°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Ar]4s<sup>1</sup><br><strong>Estados de oxidación comunes:</strong> +1 <br><strong>Número de electrones de valencia:</strong> 1 </p></div>',
  },
  {
    element_data_id: 'ca20',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-alkaline pt-solid g2 p4" id="ca"><div class="pt-element-number">20</div><h2 class="pt-element-symbol">Ca</h2><div class="pt-element-name">Calcio</div><div class="pt-element-weight">40.08</div></section></div><div class="pt-col pt-col-desc"><p>El calcio puro se descubrió en 1808. Este metal alcalinotérreo es el quinto más abundante de la corteza terrestre y se encuentra en hojas, huesos, dientes y conchas.</p><p>No se encuentra en la naturaleza por sí solo y se puede encontrar como parte de la piedra caliza, el yeso y el flúor. Este metal bastante duro tiene un color plateado y forma una capa blanca en contacto con el aire, reacciona con el agua y arde con una llama de color rojo amarillento.</p><p>Se utilizan ampliamente tanto los compuestos naturales como los sintéticos. Cuando se mezcla con la arena, el óxido de calcio endurece la argamasa y el yeso, y el calcio presente en la piedra caliza es un ingrediente clave en el cemento Portland. Otros compuestos importantes son el carburo, el cloruro, la cianamida, el hipoclorito, el nitrato y el sulfuro.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 40.08 <br><strong>Punto de fusión:</strong> 839°C <br><strong>Punto de ebullición:</strong> 1484°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Ar]4s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +2 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'sc21',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g3 p4" id="sc"><div class="pt-element-number">21</div><h2 class="pt-element-symbol">Sc</h2><div class="pt-element-name">Escandio</div><div class="pt-element-weight">44.96</div></section></div><div class="pt-col pt-col-desc"><p>El escandio se descubrió en 1878 en los minerales euxenita y gadolinita. Se encuentran cantidades diminutas de escandio en más de 800 minerales y es mucho más abundante en el Sol y ciertas estrellas que en la Tierra.</p><p>El escandio es un metal alcalinotérreo que forma un color amarillo o rosa cuando se expone al aire. Es relativamente blando y ligero, reacciona con el agua y su llama es de un color rojo amarillento.</p><p>Las lámparas de alta intensidad usan escandio y su isótopo radioactivo se utiliza como un agente trazador en craqueo de refinerías de petróleo crudo. El yoduro de escandio que se añade a las lámparas de vapor de mercurio produce una fuente lumínica de alta eficiencia semejante a la luz solar, lo que es importante para televisores en color para uso en interiores o nocturno.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 44.96 <br><strong>Punto de fusión:</strong> 1541°C <br><strong>Punto de ebullición:</strong> 2832°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Ar]3d<sup>1</sup>4s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +3 <br><strong>Número de electrones de valencia:</strong> 3 </p></div>',
  },
  {
    element_data_id: 'ti22',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g4 p4" id="ti"><div class="pt-element-number">22</div><h2 class="pt-element-symbol">Ti</h2><div class="pt-element-name">Titanio</div><div class="pt-element-weight">47.87</div></section></div><div class="pt-col pt-col-desc"><p>El titanio se descubrió en 1791 y se le puso un nombre en 1795, pero no fue posible producir metal de titanio puro hasta 1910. Es un metal brillante de color blanco con baja densidad, buena fuerza y una excelente resistencia a la corrosión. Solo es dúctil cuando está libre de oxígeno, arde en contacto con el aire y es el único elemento que arde en nitrógeno.</p><p>El metal de titanio es inerte desde el punto de vista fisiológico. El titanio natural está formado por cinco isótopos estables y se conocen otros ocho isótopos inestables.</p><p>El titanio se encuentra en los meteoritos y el Sol, y se ha encontrado en rocas lunares. Es el noveno elemento más abundante en la corteza terrestre y prácticamente siempre está presente en rocas ígneas, así como en el rutilo, la ilmenita, la titanita, el titanato, las menas de hierro y otros minerales.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 47.87 <br><strong>Punto de fusión:</strong> 1660°C <br><strong>Punto de ebullición:</strong> 3287°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Ar]3d<sup>2</sup>4s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +4,3,2 <br><strong>Número de electrones de valencia:</strong> 4 </p></div>',
  },
  {
    element_data_id: 'v23',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g5 p4" id="v"><div class="pt-element-number">23</div><h2 class="pt-element-symbol">V</h2><div class="pt-element-name">Vanadio</div><div class="pt-element-weight">50.94</div></section></div><div class="pt-col pt-col-desc"><p>El vanadio se descubrió por primera vez en 1801, pero no se identificó correctamente y se redescubrió en 1830. El vanadio en su estado natural es una mezcla de dos isótopos. Existen otros nueve isótopos inestables.</p><p>El vanadio puro es un metal blando, dúctil y de color blanco brillante con buena resistencia estructural y resistencia a la corrosión de bases, ácido sulfúrico, ácido clorhídrico y agua salada.</p><p>El vanadio se encuentra en la carnotita, la roscoelita, la vanadinita, la patronita y en muchos otros minerales. El vanadio también se encuentra en la fosforita, ciertas menas de hierro, el petróleo crudo y los meteoritos.</p><p>Se utiliza habitualmente en aplicaciones nucleares y para la fabricación de aceros resistentes a la oxidación y de herramientas de alta velocidad. Asimismo, se usa como estabilizador de carburo en la fabricación de aceros.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 50.94 <br><strong>Punto de fusión:</strong> 1890°C <br><strong>Punto de ebullición:</strong> 3380°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Ar]3d<sup>3</sup>4s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +5,2,3,4 <br><strong>Número de electrones de valencia:</strong> 5 </p></div>',
  },
  {
    element_data_id: 'cr24',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g6 p4" id="cr"><div class="pt-element-number">24</div><h2 class="pt-element-symbol">Cr</h2><div class="pt-element-name">Cromo</div><div class="pt-element-weight">52.00</div></section></div><div class="pt-col pt-col-desc"><p>El cromo es un metal duro, de color gris acerado y brillante que se descubrió en 1797. Se encuentra principalmente en menas de cromo y se suele producir mediante la reducción del óxido con aluminio.</p><p>El cromo se utiliza para endurecer el acero, fabricar acero inoxidable y formar otras aleaciones. Cuando se utiliza para galvanizados, produce una superficie dura, lisa y resistente a la corrosión. El cromo otorga a los cristales un color verde esmeralda y también se usa como catalizador.</p><p>Todos los compuestos de cromo tienen color y son útiles para una variedad de aplicaciones industriales. Los compuestos de cromo son tóxicos y deben manipularse adecuadamente. El cromo está presente en determinados alimentos, pero es tóxico en exceso.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 52.00 <br><strong>Punto de fusión:</strong> 1857°C <br><strong>Punto de ebullición:</strong> 2672°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Ar]3d<sup>5</sup>4s<sup>1</sup><br><strong>Estados de oxidación comunes:</strong> +3,2,6 <br><strong>Número de electrones de valencia:</strong> 6 </p></div>',
  },
  {
    element_data_id: 'mn25',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g7 p4" id="mn"><div class="pt-element-number">25</div><h2 class="pt-element-symbol">Mn</h2><div class="pt-element-name">Manganeso</div><div class="pt-element-weight">54.94</div></section></div><div class="pt-col pt-col-desc"><p>El manganeso, aislado en 1774, es de color blanco grisáceo, más duro que el hierro y muy frágil. Es reactivo químicamente y se utiliza para formar importantes aleaciones ferromagnéticas y de otros tipos. El manganeso mejora la manipulación del acero y le añade fuerza, rigidez, resistencia ante el desgaste y dureza.</p><p>El manganeso puro existe en cuatro formas alotrópicas. Los minerales de manganeso, como los óxidos, silicatos y carbonatos, son frecuentes. Actualmente, el manganeso se obtiene a partir de menas y minerales como la pirolusita y la rodocrosita.</p><p>El manganeso es un microelemento importante en biología y puede que esté relacionado con la capacidad de utilizar vitamina B1. La forma de permanganato se usa como agente oxidante en análisis cuantitativos y en medicina.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 54.94 <br><strong>Punto de fusión:</strong> 1244°C <br><strong>Punto de ebullición:</strong> 1962°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Ar]3d<sup>5</sup>4s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +2,3,4,6,7 <br><strong>Número de electrones de valencia:</strong> 7 </p></div>',
  },
  {
    element_data_id: 'fe26',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g8 p4" id="fe"><div class="pt-element-number">26</div><h2 class="pt-element-symbol">Fe</h2><div class="pt-element-name">Hierro</div><div class="pt-element-weight">55.85</div></section></div><div class="pt-col pt-col-desc"><p>El hierro es un metal relativamente abundante que se encuentra en el Sol y otras estrellas, y en los meteoritos. Es el cuarto elemento más abundante en la corteza terrestre por peso.</p><p>El hierro puro es muy reactivo y se corroe rápidamente. Tiene cuatro formas alotrópicas o ferritas; la forma alfa es magnética, pero el magnetismo desaparece en la forma beta. El hierro es duro, frágil, bastante fusible y se utiliza para producir acero y otras aleaciones. El hierro común se compone de cuatro isótopos y se sabe que existen otros diez isótopos.</p><p>El hierro es vital para las funciones de las plantas y animales, y se encarga de transportar oxígeno en la hemoglobina.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 55.85 <br><strong>Punto de fusión:</strong> 1535°C <br><strong>Punto de ebullición:</strong> 2750°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Ar]3d<sup>6</sup>4s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +3,2 <br><strong>Número de electrones de valencia:</strong> 8 </p></div>',
  },
  {
    element_data_id: 'co27',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g9 p4" id="co"><div class="pt-element-number">27</div><h2 class="pt-element-symbol">Co</h2><div class="pt-element-name">Cobalto</div><div class="pt-element-weight">58.93</div></section></div><div class="pt-col pt-col-desc"><p>El cobalto se descubrió en 1735. Se encuentra en la cobaltita, la escuterudita, la eritrina y otros minerales, y es un subproducto del procesamiento de menas de níquel, plata, plomo, cobre y hierro.</p><p>El cobalto es un metal frágil y duro que en su estado habitual es una mezcla de dos alótropos. El cobalto-60 artificial es una fuente importante de rayos gamma y se usa como agente radioterapéutico.</p><p>En las aleaciones, se usa para pigmentos y herramientas de corte de alta velocidad, gran resistencia y resistentes a altas temperaturas; en aceros magnéticos e inoxidables; y en turbinas de aviación y generadores de turbinas de gas.</p><p>Las sales de cobalto producen tonalidades y tintes permanentes de color azul brillante en la porcelana, el vidrio, la cerámica, los azulejos y los esmaltados. Los compuestos de cobalto se pueden utilizar para el tratamiento de deficiencias de minerales en animales.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 58.93 <br><strong>Punto de fusión:</strong> 1495°C <br><strong>Punto de ebullición:</strong> 2870°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Ar]3d<sup>7</sup>4s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +2,3 <br><strong>Número de electrones de valencia:</strong> 9 </p></div>',
  },
  {
    element_data_id: 'ni28',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g10 p4" id="ni"><div class="pt-element-number">28</div><h2 class="pt-element-symbol">Ni</h2><div class="pt-element-name">Níquel</div><div class="pt-element-weight">58.69</div></section></div><div class="pt-col pt-col-desc"><p>El níquel, descubierto en 1751, se encuentra en meteoritos y puede utilizarse para diferenciar los meteoritos de otros minerales. El níquel natural se compone de cinco isótopos estables y existen nueve isótopos inestables.</p><p>El níquel es un metal de color blanco plateado de alta pulibilidad. Es duro, maleable, dúctil, presenta cierto grado de ferromagnetismo y es un buen conductor térmico y eléctrico.</p><p>Se utiliza para fabricar acero inoxidable y hace que otras aleaciones sean más resistentes a la corrosión. El níquel se usa en monedas y en acero niquelado para blindaje y bóvedas a prueba de robos. El revestimiento de níquel proporciona una capa protectora para otros metales. El níquel se utiliza también en cerámica, fabricación de imanes y en baterías recargables, y les da un color verdoso a los cristales.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 58.69 <br><strong>Punto de fusión:</strong> 1453°C <br><strong>Punto de ebullición:</strong> 2730°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Ar]3d<sup>8</sup>4s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +2,3 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'cu29',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g11 p4" id="cu"><div class="pt-element-number">29</div><h2 class="pt-element-symbol">Cu</h2><div class="pt-element-name">Cobre</div><div class="pt-element-weight">63.55</div></section></div><div class="pt-col pt-col-desc"><p>La explotación de minas de cobre existe desde hace 5000 años. Es un metal rojizo que tiene un lustre brillante. Es maleable y dúctil, y es un buen conductor de calor y electricidad.</p><p>El cobre puede encontrarse en su estado natural en grandes depósitos de menas de sulfuros, óxidos y carbonatos. También se encuentra en la cuprita, la malaquita, la azurita, la calcopirita, la bornita y otros minerales.</p><p>El mayor uso que se le da al cobre es en la industria eléctrica y sus aleaciones, latón y bronce, se utilizan en monedas y metales de armas de fuego. El cobre también se utiliza como veneno en la agricultura y como alguicida. Los compuestos de cobre se usan ampliamente en las pruebas de química analítica.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 63.55 <br><strong>Punto de fusión:</strong> 1083°C <br><strong>Punto de ebullición:</strong> 2567°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Ar]3d<sup>10</sup>4s<sup>1</sup><br><strong>Estados de oxidación comunes:</strong> +2,1 <br><strong>Número de electrones de valencia:</strong> 1 </p></div>',
  },
  {
    element_data_id: 'zn30',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g12 p4" id="zn"><div class="pt-element-number">30</div><h2 class="pt-element-symbol">Zn</h2><div class="pt-element-name">Cinc</div><div class="pt-element-weight">65.38</div></section></div><div class="pt-col pt-col-desc"><p>Antes de identificarse como elemento, el cinc se utilizaba para fabricar latón. El metal se redescubrió en Europa en 1746.</p><p>El cinc es un metal lustroso de color blanco azulado, frágil a temperatura ambiente y, cuando se superan los 100 °C, se hace maleable y presenta superplasticidad. Es un buen conductor de electricidad y se enciende en contacto con el aire a altas temperaturas.</p><p>Las principales fuentes de cinc son las menas de esfalerita (sulfuro), smithsonita (carbonato), hemimorfita (silicato) y franklinita (óxido de cinc, hierro y manganeso). El cinc natural incluye cinco isótopos estables y se conocen otros dieciséis isótopos inestables.</p><p>El cinc también se utiliza para galvanizar otros metales con el fin de evitar la oxidación.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 65.38 <br><strong>Punto de fusión:</strong> 419.6°C <br><strong>Punto de ebullición:</strong> 906°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Ar]3d<sup>2</sup>4s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +2 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'ga31',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-basic pt-solid g13 p4" id="ga"><div class="pt-element-number">31</div><h2 class="pt-element-symbol">Ga</h2><div class="pt-element-name">Galio</div><div class="pt-element-weight">69.72</div></section></div><div class="pt-col pt-col-desc"><p>Mendeléyev predijo la existencia del galio (que describió como eka-aluminio) y se descubrió en 1875. El galio se suele encontrar en el diásporo, la esfalerita, la germanita, la bauxita y el carbón.</p><p>El galio ultra puro es de color plateado y en su forma de metal sólido se rompe de forma similar al vidrio. Se utiliza en aleaciones con puntos de fusión bajos con la mayoría de metales. Debido a que el galio se expande a medida que se solidifica, debe almacenarse en un contenedor flexible.</p><p>El galio puede estar en estado líquido cerca de la temperatura ambiente, por lo que es útil para termómetros de altas temperaturas. También tiende a sobre fusionarse por debajo de su punto de congelación.</p><p>El galio forma un espejo cuando se pinta sobre el cristal y se utiliza ampliamente en semiconductores, transistores y otros dispositivos de estado sólido.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 69.72 <br><strong>Punto de fusión:</strong> 29.8°C <br><strong>Punto de ebullición:</strong> 2403°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Ar]3d<sup>10</sup>4s2<sup>2</sup>p<sup>1</sup><br><strong>Estados de oxidación comunes:</strong> +3 <br><strong>Número de electrones de valencia:</strong> 3 </p></div>',
  },
  {
    element_data_id: 'ge32',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-semimetal pt-solid g14 p4" id="ge"><div class="pt-element-number">32</div><h2 class="pt-element-symbol">Ge</h2><div class="pt-element-name">Germanio</div><div class="pt-element-weight">72.63</div></section></div><div class="pt-col pt-col-desc"><p>Mendeléyev predijo la existencia del germanio (al que llamó eka-silicio). Se descubrió en 1886.</p><p>En su estado puro, este metaloide es de color blanco grisáceo, cristalino y frágil. Se encuentra en la argirodita (un sulfuro de germanio y plata), la germanita, las menas de cinc, el carbón y otros minerales.</p><p>El germanio es un semiconductor muy importante y se utiliza como un elemento en transistores para aplicaciones electrónicas. Puede utilizarse como un catalizador, un agente de aleación y como revestimiento fosforescente en lámparas fluorescentes. El elemento y su óxido son transparentes si se someten a luz infrarroja y se utilizan en espectrofotómetros y detectores de luz infrarroja. El germanio también se ha utilizado en objetivos de cámaras gran angular y en lentes de microscopios.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 72.63 <br><strong>Punto de fusión:</strong> 947.4°C <br><strong>Punto de ebullición:</strong> 2830°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Ar]3d<sup>10</sup>4s<sup>2</sup>4p<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +4,2 <br><strong>Número de electrones de valencia:</strong> 4 </p></div>',
  },
  {
    element_data_id: 'as33',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-semimetal pt-solid g15 p4" id="as"><div class="pt-element-number">33</div><h2 class="pt-element-symbol">As</h2><div class="pt-element-name">Arsénico</div><div class="pt-element-weight">74.92</div></section></div><div class="pt-col pt-col-desc"><p>Es posible que el arsénico se haya obtenido por primera vez en 1250 a. C., pero las instrucciones de preparación no se publicaron hasta 1649.</p><p>El arsénico es un sólido frágil, cristalino y semimetálico que se deslustra cuando se expone al aire. El arsénico puro se encuentra en la forma amarilla o gris metálica, cuyas densidades relativas son ligeramente distintas. Cuando se calienta, se oxida rápidamente y se convierte en óxido de arsénico, que tiene un olor similar al ajo. El arsénico y sus compuestos son tóxicos. Los compuestos útiles del arsénico son el trióxido de arsénico, el sulfuro de arsénico, el Verde de París, el arseniato de calcio y el arseniato de plomo.</p><p>Algunos de los usos del arsénico son para el revestimiento de bronce, la pirotecnia y el endurecimiento y mejora de perdigones. Sus compuestos se han usado en insecticidas y venenos en la agricultura.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 74.92 <br><strong>Punto de fusión:</strong> 817°C <br><strong>Punto de ebullición:</strong> 617°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Ar]3d<sup>10</sup>4s<sup>2</sup>4p<sup>3</sup><br><strong>Estados de oxidación comunes:</strong> ±3,+5 <br><strong>Número de electrones de valencia:</strong> 5 </p></div>',
  },
  {
    element_data_id: 'se34',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-nonmetal pt-solid g16 p4" id="se"><div class="pt-element-number">34</div><h2 class="pt-element-symbol">Se</h2><div class="pt-element-name">Selenio</div><div class="pt-element-weight">78.97</div></section></div><div class="pt-col pt-col-desc"><p>El selenio se descubrió en 1817 y se encuentra en varias formas alotrópicas y con estructuras amorfas o cristalinas. Como parte de la familia del azufre, es similar a este en su forma y en sus compuestos. </p><p>El selenio amorfo es rojo (forma de polvo) o negro (forma vítrea); la versión monoclínica cristalina es de color rojo oscuro y la forma hexagonal cristalina estable es de color gris metálico. El selenio puro contiene seis isótopos estables y se han encontrado otros quince.</p><p>El selenio se encuentra en la crookesita, la clausthalita y otros minerales poco frecuentes. Tiene tanto propiedades fotovoltaicas como fotoconductoras y se utiliza en paneles solares, fotorresistores y exposímetros para fotografía.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 78.97 <br><strong>Punto de fusión:</strong> 217°C <br><strong>Punto de ebullición:</strong> 685°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Ar]3d<sup>10</sup>4s<sup>2</sup>4p<sup>4</sup><br><strong>Estados de oxidación comunes:</strong> +4,−2,+6 <br><strong>Número de electrones de valencia:</strong> 6 </p></div>',
  },
  {
    element_data_id: 'br35',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-halogen pt-liquid g17 p4" id="br"><div class="pt-element-number">35</div><h2 class="pt-element-symbol">Br</h2><div class="pt-element-name">Bromo</div><div class="pt-element-weight">79.90</div></section></div><div class="pt-col pt-col-desc"><p>El bromo se descubrió en 1826, pero no se preparó en grandes cantidades hasta 1860. </p><p>El bromo es un elemento líquido no metálico. Es un líquido denso de color marrón rojizo que produce un vapor rojo con un olor característico y desagradable. El vapor irrita los ojos y la garganta, y al exponerlo a la piel produce úlceras dolorosas.</p><p>El bromo reacciona con muchos elementos, es fácilmente soluble en agua o sulfuro de carbono y se puede extraer a partir de salmueras y agua de mar.</p><p>El bromo se utiliza en pesticidas para fumigación, agentes a prueba de fuego, compuestos de purificación de agua, tintes, medicinas, soluciones antisépticas y sustancias químicas para fotografía. Los compuestos orgánicos e inorgánicos de bromo también tienen aplicaciones importantes en muchas industrias.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 79.90 <br><strong>Punto de fusión:</strong> -7.2°C <br><strong>Punto de ebullición:</strong> 58.8°C <br><strong>Fase a temperatura y presión estándar:</strong> Liquid <br><strong>Configuración electrónica:</strong> [Ar]3d<sup>10</sup>4s<sup>2</sup>4p<sup>5</sup><br><strong>Estados de oxidación comunes:</strong> ±1,+5 <br><strong>Número de electrones de valencia:</strong> 7 </p></div>',
  },
  {
    element_data_id: 'kr36',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-noble pt-gas g18 p4" id="kr"><div class="pt-element-number">36</div><h2 class="pt-element-symbol">Kr</h2><div class="pt-element-name">Criptón</div><div class="pt-element-weight">83.80</div></section></div><div class="pt-col pt-col-desc"><p>El espectro de criptón, descubierto en 1898, fue el estándar internacional para la longitud del metro entre 1960 y 1983.</p><p>El criptón es uno de los gases nobles y está caracterizado por su espectro de líneas brillantes verdes y naranjas. El criptón sólido es una sustancia cristalina blanca con una estructura frecuente en todos los gases poco frecuentes. El criptón en su estado natural tiene seis isótopos estables. También se han identificado diecisiete isótopos inestables. Aunque se considera inerte, se ha demostrado la existencia de algunos compuestos del criptón.</p><p>El criptón se utiliza en algunas lámparas de flases fotográficos para fotografías de alta velocidad.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 83.80 <br><strong>Punto de fusión:</strong> -157°C <br><strong>Punto de ebullición:</strong> -152°C <br><strong>Fase a temperatura y presión estándar:</strong> Gas <br><strong>Configuración electrónica:</strong> [Ar]3d<sup>10</sup>4s<sup>2</sup>4p<sup>6</sup><br><strong>Estados de oxidación comunes:</strong> 0 <br><strong>Número de electrones de valencia:</strong> 8 </p></div>',
  },
  {
    element_data_id: 'rb37',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-alkali pt-solid g1 p5" id="rb"><div class="pt-element-number">37</div><h2 class="pt-element-symbol">Rb</h2><div class="pt-element-name">Rubidio</div><div class="pt-element-weight">85.47</div></section></div><div class="pt-col pt-col-desc"><p>El rubidio se descubrió espectroscópicamente en 1861 en el mineral lepidolita. Este elemento es más abundante de lo que se pensaba inicialmente y también se encuentra en la polucita, la leucita, la zinnwaldita y otros minerales.</p><p>El rubidio es un elemento metálico blando y de color plateado que se encuentra en forma líquida a temperatura ambiente. Se enciende espontáneamente en contacto con el aire, reacciona violentamente si se expone al agua y debe mantenerse sumergido en aceite o en atmósferas inertes. Puede formar amalgamas con mercurio y aleaciones con oro, cesio, sodio y potasio. Además, produce una llama violeta amarillenta. Se conocen veinticuatro isótopos de rubidio; su forma natural es radioactiva e incluye dos isótopos. El rubidio también forma cuatro óxidos.</p><p>Este elemento se ioniza con facilidad y se ha investigado su uso para motores iónicos para vehículos espaciales. Se utiliza en válvulas termoiónicas, fotorresistencias y vidrios especiales.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 85.47 <br><strong>Punto de fusión:</strong> 38.9°C <br><strong>Punto de ebullición:</strong> 686°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Kr]5s<sup>1</sup><br><strong>Estados de oxidación comunes:</strong> 1 <br><strong>Número de electrones de valencia:</strong> 1 </p></div>',
  },
  {
    element_data_id: 'sr38',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-alkaline pt-solid g2 p5" id="sr"><div class="pt-element-number">38</div><h2 class="pt-element-symbol">Sr</h2><div class="pt-element-name">Estroncio</div><div class="pt-element-weight">87.62</div></section></div><div class="pt-col pt-col-desc"><p>El estroncio recibió su nombre por una ciudad en Escocia; se aisló en 1808 y se reconoció en 1790.</p><p>El estroncio es más blando que el agua y se descompone violentamente en ella, como el calcio. El metal puede arder espontáneamente en contacto con el aire, lo que cambia rápidamente la apariencia plateada de su superficie expuesta a un tono amarillento. El estroncio se puede mantener sumergido en queroseno para evitar la oxidación.</p><p>El estroncio natural está formado por una mezcla de cuatro formas de isótopos estables y también se conoce la existencia de dieciséis isótopos inestables. Las sales volátiles de estroncio, que dan un color carmesí a la llama, se utilizan en pirotecnia y luces de bengala.</p><p>El estroncio se encuentra principalmente en los minerales celestita y estroncianita, y puede usarse en la obtención de imágenes médicas, la producción de imanes de ferrita y la refinación de cinc.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 87.62 <br><strong>Punto de fusión:</strong> 769°C <br><strong>Punto de ebullición:</strong> 1384°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Kr]5s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +2 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'y39',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g3 p5" id="y"><div class="pt-element-number">39</div><h2 class="pt-element-symbol">Y</h2><div class="pt-element-name">Itrio</div><div class="pt-element-weight">88.91</div></section></div><div class="pt-col pt-col-desc"><p>El itrio se descubrió en 1794 y se encuentra en prácticamente todos los minerales de tierras raras.</p><p>El itrio es relativamente estable cuando está expuesto al aire y tiene un brillo metálico plateado. Sin embargo, las partes pequeñas de este metal se encienden en contacto con el aire a temperaturas superiores a 400 °C.</p><p>El itrio natural contiene un isótopo, pero también se han caracterizado diecinueve isótopos inestables. Las muestras de rocas lunares presentan altas concentraciones de contenido de itrio.</p><p>Se extrae con fines comerciales de la arena de monocita y de la bastnasita. El óxido de itrio se utiliza para la fabricación de compuestos que dan el color rojo de los tubos de televisores y para producir granates de hierro e itrio para filtros de microondas.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 88.91 <br><strong>Punto de fusión:</strong> 1523°C <br><strong>Punto de ebullición:</strong> 3337°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Kr]4d<sup>1</sup>5s<sup></sup>2 <br><strong>Estados de oxidación comunes:</strong> +3 <br><strong>Número de electrones de valencia:</strong> 3 </p></div>',
  },
  {
    element_data_id: 'zr40',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g4 p5" id="zr"><div class="pt-element-number">40</div><h2 class="pt-element-symbol">Zr</h2><div class="pt-element-name">Circón</div><div class="pt-element-weight">91.22</div></section></div><div class="pt-col pt-col-desc"><p>Aunque la gema circón se conoció primero, el circonio se identificó como un nuevo elemento en 1789.</p><p>Es un metal brillante de color blanco grisáceo. Si se divide en partes muy pequeñas, el metal de circonio puede encenderse espontáneamente en el aire. Es abundante en estrellas de tipo S, el Sol, los meteoritos y las muestras de rocas lunares. El circonio natural incluye cinco isótopos y existen otros quince.</p><p>El circonio es excepcionalmente resistente a la corrosión por ácidos y bases comunes, agua de mar y otros agentes. En su aleación con cinc, adquiere propiedades magnéticas cuando se enfría a temperaturas inferiores a 35 °K. </p><p>El circonio se utiliza cuando se necesitan agentes corrosivos y es un componente en tubos de vacío, agentes de aleación, aparatos quirúrgicos, bombillas de flases fotográficos, cebadores explosivos, hileras de rayón y filamentos de lámparas.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 91.22 <br><strong>Punto de fusión:</strong> 1852°C <br><strong>Punto de ebullición:</strong> 4377°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Kr]4d<sup>2</sup>5s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +4 <br><strong>Número de electrones de valencia:</strong> 4 </p></div>',
  },
  {
    element_data_id: 'nb41',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g5 p5" id="nb"><div class="pt-element-number">41</div><h2 class="pt-element-symbol">Nb</h2><div class="pt-element-name">Niobio</div><div class="pt-element-weight">92.91</div></section></div><div class="pt-col pt-col-desc"><p>El niobio se descubrió en 1801 en una mena y después de 100 años de controversia, su nombre actual reemplazó a la denominación “columbio” en 1950.</p><p>El niobio es un metal blando, dúctil y de color blanco brillante que se torna azulado con la exposición prolongada al aire. Se conocen dieciocho isótopos de niobio.</p><p>Se encuentra en los minerales niobita, niobita-tantalita, pirocloro y euxenita, y los grandes depósitos están asociados a rocas de carbonato-silicato.</p><p>El niobio se usa en varillas de soldadura por arco y en sistemas avanzados de estructuras de aeronaves que se utilizan para viajes espaciales. Sus propiedades superconductoras han ayudado a la fabricación de imanes que retienen su superconductividad en presencia de campos magnéticos intensos.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 92.91 <br><strong>Punto de fusión:</strong> 2468°C <br><strong>Punto de ebullición:</strong> 4742°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Kr]4d<sup>4</sup>5s<sup>1</sup><br><strong>Estados de oxidación comunes:</strong> +5,3 <br><strong>Número de electrones de valencia:</strong> 5 </p></div>',
  },
  {
    element_data_id: 'mo42',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g6 p5" id="mo"><div class="pt-element-number">42</div><h2 class="pt-element-symbol">Mo</h2><div class="pt-element-name">Molibdeno</div><div class="pt-element-weight">95.95</div></section></div><div class="pt-col pt-col-desc"><p>El molibdeno se reconoció como nuevo elemento en 1778 y su primera forma impura se produjo en 1782.</p><p>Este metal es blanco plateado y es muy duro, aunque es más blando y más dúctil que el tungsteno. Se usa como un agente de aleación y hace que los aceros enfriados y templados sean más duros y fuertes, y mejora su resistencia a altas temperaturas. El molibdeno se oxida a temperaturas elevadas.</p><p>Se utiliza en aleaciones con base de níquel que son resistentes al calor y a la corrosión. Este metal se ha utilizado en hornos para trabajar vidrio que se calientan eléctricamente y electrodos de antecrisoles, en aplicaciones de energía nuclear y en piezas de aeronaves y misiles.</p><p>El molibdeno es un elemento traza esencial para la fijación del nitrógeno y otros procesos metabólicos.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 95.95 <br><strong>Punto de fusión:</strong> 2617°C <br><strong>Punto de ebullición:</strong> 4612°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Kr]4d<sup>5</sup>5s<sup>1</sup><br><strong>Estados de oxidación comunes:</strong> +6,3,5 <br><strong>Número de electrones de valencia:</strong> 6 </p></div>',
  },
  {
    element_data_id: 'tc43',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g7 p5" id="tc"><div class="pt-element-number">43</div><h2 class="pt-element-symbol">Tc</h2><div class="pt-element-name">Tecnecio</div><div class="pt-element-weight">98.00</div></section></div><div class="pt-col pt-col-desc"><p>La tabla periódica fue la primera en predecir la existencia del elemento 43. El tecnecio no se descubrió hasta 1937 y fue el primer elemento en fabricarse de manera artificial.</p><p>Se conocen veintidós isótopos de tecnecio y todos son radiactivos. Tiene tres radioisótopos de larga vida, pero el isótopo más útil tiene un corto período de semidesintegración, por lo que es de utilidad en muchas pruebas médicas.</p><p>El tecnecio es un metal gris plateado y se deslustra lentamente cuando se expone a aire húmedo. Sus propiedades químicas son parecidas a las del renio: se disuelve en ácido nítrico, agua regia y ácido sulfúrico concentrado, pero no se disuelve en ácido clorhídrico. El tecnecio se utiliza como un inhibidor de corrosión en el acero y es un superconductor a temperaturas iguales o inferiores a 11 °K.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 98.00 <br><strong>Punto de fusión:</strong> 2172°C <br><strong>Punto de ebullición:</strong> 4877°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Kr]4d<sup>5</sup>5s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +7,4,6 <br><strong>Número de electrones de valencia:</strong> 7 </p></div>',
  },
  {
    element_data_id: 'ru44',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g8 p5" id="ru"><div class="pt-element-number">44</div><h2 class="pt-element-symbol">Ru</h2><div class="pt-element-name">Rutenio</div><div class="pt-element-weight">101.1</div></section></div><div class="pt-col pt-col-desc"><p>El rutenio se descubrió en 1844, forma parte del grupo del platino y se encuentra naturalmente junto con otros miembros del grupo.</p><p>El rutenio es un metal duro y blanco con cuatro modificaciones cristalinas. No se deslustra a temperatura ambiente, pero es explosivo cuando se oxida. El rutenio es un endurecedor eficaz para el platino y el paladio, y se utiliza con frecuencia en aleaciones con estos.</p><p>Es un catalizador versátil y se ha descrito que la aleación de rutenio y molibdeno es superconductora a temperaturas de 10,6 °K.</p><p>Se han encontrado compuestos del rutenio en al menos ocho estados de oxidación y estos compuestos son similares a los del cadmio.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 101.1 <br><strong>Punto de fusión:</strong> 2310°C <br><strong>Punto de ebullición:</strong> 3900°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Kr]4d<sup>7</sup>5s<sup>1</sup><br><strong>Estados de oxidación comunes:</strong> +4,3,6,8 <br><strong>Número de electrones de valencia:</strong> 8 </p></div>',
  },
  {
    element_data_id: 'rh45',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g9 p5" id="rh"><div class="pt-element-number">45</div><h2 class="pt-element-symbol">Rh</h2><div class="pt-element-name">Rodio</div><div class="pt-element-weight">102.9</div></section></div><div class="pt-col pt-col-desc"><p>El rodio se descubrió entre 1803 y 1804, y se encuentra de forma natural junto con otros metales de platino.</p><p>Es de color blanco plateado y cambia lentamente al sesquióxido cuando se calienta expuesto al aire y vuelve a convertirse en elemento a temperaturas más altas. Es altamente reflectante, duro y resistente.</p><p>El rodio se usa principalmente en aleaciones para endurecer el platino y el paladio, que luego se usan para hornos,elementos de termo pares, aisladores, bujías para aeronaves y crisoles de laboratorio. Es resistente a la corrosión y su baja resistencia eléctrica lo convierte en un buen material para contactos eléctricos. El rodio también se utiliza como catalizador en instrumentos ópticos, así como para joyería y decoración.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 102.9 <br><strong>Punto de fusión:</strong> 1966°C <br><strong>Punto de ebullición:</strong> 3727°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Kr]4d<sup>8</sup>5s<sup>1</sup><br><strong>Estados de oxidación comunes:</strong> +3,4,6 <br><strong>Número de electrones de valencia:</strong> 9 </p></div>',
  },
  {
    element_data_id: 'pd46',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g10 p5" id="pd"><div class="pt-element-number">46</div><h2 class="pt-element-symbol">Pd</h2><div class="pt-element-name">Paladio</div><div class="pt-element-weight">106.4</div></section></div><div class="pt-col pt-col-desc"><p>El paladio se descubrió en 1803 y se encuentra habitualmente con otros metales del grupo platino.</p><p>Es un metal blanco plateado que no se deslustra cuando se expone al aire. Es blando y dúctil cuando está recocido y su resistencia y dureza aumentan cuando se trabaja en frío. A temperatura ambiente, el paladio puede absorber el hidrógeno en cantidades de hasta 900 veces su propio volumen.</p><p>El paladio puede utilizarse como catalizador para los procesos de hidrogenación y deshidrogenación. Sus aleaciones se usan en joyería y se puede formar una hoja de paladio (grosor de 1/250 000 pulgadas). El metal de paladio se utiliza en la odontología, la fabricación de relojes, instrumentos quirúrgicos y contactos eléctricos.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 106.4 <br><strong>Punto de fusión:</strong> 1554°C <br><strong>Punto de ebullición:</strong> 3140°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Kr]4d<sup>10</sup><br><strong>Estados de oxidación comunes:</strong> +2,4<br><strong>Número de electrones de valencia:</strong> 10 </p></div>',
  },
  {
    element_data_id: 'ag47',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g11 p5" id="ag"><div class="pt-element-number">47</div><h2 class="pt-element-symbol">Ag</h2><div class="pt-element-name">Plata</div><div class="pt-element-weight">107.9</div></section></div><div class="pt-col pt-col-desc"><p>La plata se conoce desde la antigüedad. Se encuentra de forma natural en la argentita, la clorargirita, el plomo, el plomo-cinc, el cobre, el oro, el cobre-níquel y en otros minerales.</p><p>La plata pura es de color blanco brillante. Es un poco más dura que el oro, es maleable y dúctil, con una gran conductividad eléctrica y térmica, y baja resistencia de contacto. La plata es estable en contacto con el aire puro y el agua, y se deslustra cuando se expone al ozono, al ácido sulfhídrico o a aire que contiene sulfuro.</p><p>La aleación de plata de ley se usa para joyería y cubertería de plata; además, la plata es importante en fotografía, aleaciones dentales, aleaciones de soldaduras y soldaduras fuertes, contactos eléctricos y baterías de alta capacidad. La plata en sí misma no es tóxica, pero la mayoría de sus sales sí lo son.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 107.9 <br><strong>Punto de fusión:</strong> 962°C <br><strong>Punto de ebullición:</strong> 2212°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Kr]4d<sup>10</sup>5s<sup></sup>1 <br><strong>Estados de oxidación comunes:</strong> +1 <br><strong>Número de electrones de valencia:</strong> 1 </p></div>',
  },
  {
    element_data_id: 'cd48',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g12 p5" id="cd"><div class="pt-element-number">48</div><h2 class="pt-element-symbol">Cd</h2><div class="pt-element-name">Cadmio</div><div class="pt-element-weight">112.4</div></section></div><div class="pt-col pt-col-desc"><p>El cadmio se descubrió en 1817 y se encuentra en asociación con las menas de cinc. Prácticamente todo el cadmio es un subproducto del procesamiento de menas de cinc, cobre y plomo. </p><p>El cadmio es un metal blando y de color blanco azulado que puede cortarse fácilmente y es parecido al cinc en cuanto a sus propiedades. Es un componente de aleaciones de bajo punto de fusión y se utiliza en galvanizado, soldaduras, celdas estándar de fuerza electromotriz y baterías de Ni-Cd.</p><p>Los compuestos de cadmio se usan en fósforos y su sulfato se usa como pigmento amarillo.</p><p>El cadmio y las soluciones de sus compuestos son tóxicos. Subestimar las propiedades tóxicas del cadmio puede exponer a los trabajadores a situaciones peligrosas.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 112.4 <br><strong>Punto de fusión:</strong> 320.9°C <br><strong>Punto de ebullición:</strong> 765°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Kr]4d<sup>10</sup>5s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +2 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'in49',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-basic pt-solid g13 p5" id="in"><div class="pt-element-number">49</div><h2 class="pt-element-symbol">In</h2><div class="pt-element-name">Indio</div><div class="pt-element-weight">114.8</div></section></div><div class="pt-col pt-col-desc"><p>El indio se descubrió espectroscópicamente en 1863, se aisló en 1864 y recibió su nombre por la línea color índigo (azul) en su espectro. Se asocia frecuentemente con los materiales de cinc, es un subproducto del refinado de cinc y se puede encontrar en minas de hierro, plomo y cobre.</p><p>Este metal del bloque p es muy blando y de un color blanco plateado con un lustre brillante. El indio moja el vidrio y produce un ruido agudo cuando se dobla (debido a la agrupación de los cristales).</p><p>El indio es esencial para la tecnología moderna, especialmente en la industria de los semiconductores. Se usa para producir aleaciones con bajas temperaturas de fusión, en los sellos de metal blanco de alto vacío, para crear revestimientos conductores transparentes sobre vidrio y en transistores, rectificadores, termistores y fotoconductores.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 114.8 <br><strong>Punto de fusión:</strong> 156.6°C <br><strong>Punto de ebullición:</strong> 2080°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Kr]4d<sup>10</sup>5s<sup>2</sup>5p<sup>1</sup><br><strong>Estados de oxidación comunes:</strong> +3 <br><strong>Número de electrones de valencia:</strong> 3 </p></div>',
  },
  {
    element_data_id: 'sn50',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-basic pt-solid g14 p5" id="sn"><div class="pt-element-number">50</div><h2 class="pt-element-symbol">Sn</h2><div class="pt-element-name">Estaño</div><div class="pt-element-weight">118.7</div></section></div><div class="pt-col pt-col-desc"><p>El estaño, proveniente del latín «stannum», se conoce desde la antigüedad. El estaño se encuentra principalmente en menas de casiterita y se obtiene mediante el procesamiento de menas con carbón en un horno de reverbero.</p><p>El estaño ordinario es un metal maleable de color blanco plateado y con cierto grado de ductilidad y altamente cristalino. La distorsión de los cristales pueden producir un «grito» audible cuando se dobla un trozo de estaño. El estaño contiene nueve isótopos estables y se conocen otros 18 isótopos inestables.</p><p>El estaño tiene buena pulibilidad y se usa como un revestimiento para evitar la corrosión en otros metales. Algunas aleaciones importantes con estaño son la soldadura blanda, las aleaciones tipográficas, el peltre, el bronce, el metal de campana, el metal Babbitt, el metal blanco, las aleaciones para moldeo en matriz y el bronce fosforado.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 118.7 <br><strong>Punto de fusión:</strong> 232°C <br><strong>Punto de ebullición:</strong> 2270°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Kr]4d<sup>10</sup>5s<sup>2</sup>5p<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +4,2 <br><strong>Número de electrones de valencia:</strong> 4 </p></div>',
  },
  {
    element_data_id: 'sb51',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-semimetal pt-solid g15 p5" id="sb"><div class="pt-element-number">51</div><h2 class="pt-element-symbol">Sb</h2><div class="pt-element-name">Antimonio</div><div class="pt-element-weight">121.8</div></section></div><div class="pt-col pt-col-desc"><p>El antimonio se reconoció desde la antigüedad y se conoce como metal desde al menos el siglo XVII. El antimonio es abundante y se encuentra en más de 100 minerales, con mayor frecuencia en la forma de sulfuro de antimonio.</p><p>El antimonio no es buen conductor de calor y electricidad, y muchos de sus compuestos son tóxicos.</p><p>Se utiliza para fabricar detectores infrarrojos, diodos y otros dispositivos. Dado que aumenta la resistencia y dureza del plomo, aproximadamente la mitad del antimonio disponible se usa para producir baterías, aleaciones, metales, balas, revestimiento de cables y otros productos menores.</p><p>Los compuestos de antimonio (óxidos, sulfuros, antimoniato de sodio, tricloruro de antimonio y otros) se utilizan en materiales a prueba de fuego, pinturas y esmaltes de cerámica, vidrio y alfarería.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 121.8 <br><strong>Punto de fusión:</strong> 631°C <br><strong>Punto de ebullición:</strong> 1950°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Kr]4d<sup>10</sup>5s<sup>2</sup>5p<sup>3</sup><br><strong>Estados de oxidación comunes:</strong> +3,5 <br><strong>Número de electrones de valencia:</strong> 5 </p></div>',
  },
  {
    element_data_id: 'te52',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-semimetal pt-solid g16 p5" id="te"><div class="pt-element-number">52</div><h2 class="pt-element-symbol">Te</h2><div class="pt-element-name">Telurio</div><div class="pt-element-weight">127.6</div></section></div><div class="pt-col pt-col-desc"><p>El telurio, descubierto en 1782 y aislado en 1798, es frágil en su forma cristalina y presenta un color blanco plateado con un brillo metálico. </p><p>En su estado natural, la mayor parte del tiempo se encuentra como teluro de oro (calaverita) o combinado con otros metales. El telurio natural se compone de ocho isótopos pero se conocen otros treinta isótopos artificiales.</p><p>El telurio es un semiconductor tipo P, que exhibe diferente conductividad dependiendo de la alineación de los átomos y gana conductividad cuando se expone a la luz. Produce llamas de color azul verdoso. El telurio y sus compuestos son altamente tóxicos.</p><p>El telurio mejora la manipulación del cobre y el acero inoxidable, disminuye la corrosión y aumenta la resistencia y dureza del plomo.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 127.6 <br><strong>Punto de fusión:</strong> 449.5°C <br><strong>Punto de ebullición:</strong> 989.8°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Kr]4d<sup>10</sup>5s<sup>2</sup>25p<sup>4</sup><br><strong>Estados de oxidación comunes:</strong> +4,6,−2 <br><strong>Número de electrones de valencia:</strong> 6 </p></div>',
  },
  {
    element_data_id: 'i53',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-halogen pt-solid g17 p5" id="i"><div class="pt-element-number">53</div><h2 class="pt-element-symbol">I</h2><div class="pt-element-name">Yodo</div><div class="pt-element-weight">126.9</div></section></div><div class="pt-col pt-col-desc"><p>El yodo es un halógeno descubierto en 1811.</p><p>El yodo es un sólido brillante de color negro azulado que a temperatura ambiente se convierte en un gas de color violeta azulado con un olor característico. Forma compuestos, pero es menos reactivo que otros halógenos. El yodo tiene algunas propiedades metálicas, es altamente soluble en agua y forma una solución púrpura cuando se disuelve en cloroformo, tetracloruro de carbono o sulfuro de carbono.</p><p>Se han reconocido treinta isótopos de yodo y solo se ha encontrado un isótopo estable en la naturaleza. El radioisótopo artificial I-131 se utiliza para el tratamiento de alteraciones de la glándula tiroides. Los compuestos del yodo se utilizan en química orgánica y medicina.</p><p>Se debe tener cuidado al manipular y utilizar el yodo, ya que causa lesiones al contacto con la piel e irrita los ojos y las mucosas.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 126.9 <br><strong>Punto de fusión:</strong> 113.5°C <br><strong>Punto de ebullición:</strong> 184°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Kr]4d<sup>10</sup>5s<sup>2</sup>5p<sup>5</sup><br><strong>Estados de oxidación comunes:</strong> −1,+5,7 <br><strong>Número de electrones de valencia:</strong> 7 </p></div>',
  },
  {
    element_data_id: 'xe54',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-noble pt-gas g18 p5" id="xe"><div class="pt-element-number">54</div><h2 class="pt-element-symbol">Xe</h2><div class="pt-element-name">Xenón</div><div class="pt-element-weight">131.3</div></section></div><div class="pt-col pt-col-desc"><p>El xenón se descubrió en 1898 y es un gas noble o inerte. Está presente en las atmósferas de la Tierra y Marte y en gases de algunos manantiales minerales.</p><p>El xenón natural tiene nueve isótopos estables y se conocen 20 isótopos inestables. Se han creado más de 80 compuestos de xenón con xenón enlazado al flúor y al oxígeno.</p><p>El gas de xenón se utiliza en tubos electrónicos, lámparas estroboscópicas y bactericidas, y lámparas de láser que producen luz coherente.</p><p>Los perxenatos se utilizan en la química analítica como agentes oxidantes. El xenón en sí mismo es seguro, pero sus compuestos son altamente tóxicos debido a sus capacidades oxidativas.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 131.3 <br><strong>Punto de fusión:</strong> -111.8°C <br><strong>Punto de ebullición:</strong> -107.1°C <br><strong>Fase a temperatura y presión estándar:</strong> Gas <br><strong>Configuración electrónica:</strong> [Kr]4d<sup>10</sup>5s<sup>2</sup>5p<sup>6</sup><br><strong>Estados de oxidación comunes:</strong> 0 <br><strong>Número de electrones de valencia:</strong> 8 </p></div>',
  },
  {
    element_data_id: 'cs55',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-alkali pt-solid g1 p6" id="cs"><div class="pt-element-number">55</div><h2 class="pt-element-symbol">Cs</h2><div class="pt-element-name">Cesio</div><div class="pt-element-weight">132.9</div></section></div><div class="pt-col pt-col-desc"><p>El cesio es un metal alcalino descubierto mediante espectroscopia en 1860. Se encuentra en la lepidolita, la polucita y en otras fuentes.</p><p>El cesio es de color blanco plateado, blando y dúctil, y es el elemento más alcalino y electropositivo. El cesio es uno de los tres metales que son líquidos a temperatura ambiente. Reacciona de manera explosiva con el agua fría y con el hielo cuando las temperaturas son superiores a –116 °C.</p><p>El espectro del metal contiene dos líneas azules brillantes y varias líneas en las longitudes de onda roja, amarilla y verde.</p><p>El cesio se utiliza en tubos electrónicos y células fotoeléctricas como un catalizador de la hidrogenación para compuestos orgánicos específicos y en relojes atómicos.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 132.9 <br><strong>Punto de fusión:</strong> 28.4°C <br><strong>Punto de ebullición:</strong> 669°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]6s<sup>1</sup><br><strong>Estados de oxidación comunes:</strong> +1 <br><strong>Número de electrones de valencia:</strong> 1 </p></div>',
  },
  {
    element_data_id: 'ba56',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-alkaline pt-solid g2 p6" id="ba"><div class="pt-element-number">56</div><h2 class="pt-element-symbol">Ba</h2><div class="pt-element-name">Bario</div><div class="pt-element-weight">137.3</div></section></div><div class="pt-col pt-col-desc"><p>El bario se descubrió en 1808.</p><p>Se encuentra únicamente junto a otros elementos. Este metal alcalinotérreo es metálico, blando y de color blanco plateado cuando es puro. Se asemeja al calcio. El metal se oxida con facilidad y debe conservarse sumergido en petróleo u otros líquidos que no contengan oxígeno.</p><p>Los compuestos principales del bario son peróxidos, cloruros, sulfatos, carbonatos, nitratos y cloratos. Se encuentran en pigmentos, pinturas, diagnósticos de rayos X y producción de vidrio. Se usan otras formas en lodos bentoníticos para pozos petrolíferos, producción de caucho, veneno para ratas y pirotecnia.</p><p>Los compuestos de bario solubles en agua o ácidos son venenosos. El bario natural es una mezcla de siete isótopos estables y se sabe que existen 22 isótopos radioactivos.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 137.3 <br><strong>Punto de fusión:</strong> 725°C <br><strong>Punto de ebullición:</strong> 1640°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]6s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +2 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'la57',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-lanthanide pt-solid p6" id="la"><div class="pt-element-number">57</div><h2 class="pt-element-symbol">La</h2><div class="pt-element-name">Lantano</div><div class="pt-element-weight">138.9</div></section></div><div class="pt-col pt-col-desc"><p>El lantano se extrajo por primera vez en 1839 y se aisló en una forma relativamente pura en 1923. Se encuentra en la cerita, la monacita, la allanita, la bastnasita y otros minerales de tierras raras.</p><p>El lantano es un metal de tierras raras de color blanco plateado, maleable, dúctil y blando que se oxida rápidamente cuando se expone al aire. Reacciona directamente con carbono, nitrógeno, boro, selenio, silicio, fósforo, azufre y halógenos.</p><p>El lantano natural es una mezcla de dos isótopos estables y se reconocen otros 23 isótopos radioactivos.</p><p>Los compuestos de tierras raras que contienen lantano se usan mayoritariamente en iluminación y proyecciones y para la elaboración de cristales ópticos especiales. El lantano y sus compuestos están clasificados como de toxicidad aguda de baja a moderada y deben manipularse con cuidado.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 138.9 <br><strong>Punto de fusión:</strong> 920°C <br><strong>Punto de ebullición:</strong> 3454°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]5d<sup>1</sup>6s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +3 <br><strong>Número de electrones de valencia:</strong> 3 </p></div>',
  },
  {
    element_data_id: 'ce58',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-lanthanide pt-solid p6" id="ce"><div class="pt-element-number">58</div><h2 class="pt-element-symbol">Ce</h2><div class="pt-element-name">Cerio</div><div class="pt-element-weight">140.1</div></section></div><div class="pt-col pt-col-desc"><p>El cerio se descubrió en 1803 y se preparó el metal por primera vez en 1875.</p><p>Es el metal de tierras raras más abundante y se encuentra en la allanita (también denominada ortita), la monacita, la bastnasita, la cerita, la samarskita y otros minerales.</p><p>El cerio es un metal de color gris similar al hierro brillante y maleable que se oxida a temperatura ambiente, y el metal puro se puede encenderse si se raya con un cuchillo. Aunque el cerio no es radioactivo, en grado comercial puede contener trazas de torio radioactivo.</p><p>El óxido de cerio es un constituyente importante de mantos de gas incandescente y está en auge como catalizador de hidrocarburos en hornos autolimpiables. Se usan otros compuestos de cerio en la producción de vidrio, agentes de abrillantado de vidrio, iluminación con arco de carbono, catalizadores refinadores de petróleo y aplicaciones metalúrgicas y nucleares.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 140.1 <br><strong>Punto de fusión:</strong> 798°C <br><strong>Punto de ebullición:</strong> 3257°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>1</sup>5d<sup>1</sup>6s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +3,4 <br><strong>Número de electrones de valencia:</strong> 4 </p></div>',
  },
  {
    element_data_id: 'pr59',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-lanthanide pt-solid p6" id="pr"><div class="pt-element-number">59</div><h2 class="pt-element-symbol">Pr</h2><div class="pt-element-name">Praseodimio</div><div class="pt-element-weight">140.9</div></section></div><div class="pt-col pt-col-desc"><p>Semi aislado en 1841, el praseodimio se identificó claramente como elemento en 1885.</p><p>El praseodimio es blando, plateado, maleable, dúctil y desarrolla una capa de óxido verde cuando se expone al aire.</p><p>Se encuentra con otros elementos de tierras raras en distintos minerales, principalmente en la monacita y la bastnasita.</p><p>Los óxidos de tierras raras, incluidos los del praseodimio, son algunas de las sustancias más refractarias. Junto con otros metales de tierras raras, se usa para arcos de carbono y para añadir un color amarillo transparente al vidrio y al esmalte. Su presencia en el vidrio de didimio ayuda a proporcionar la coloración protectora en las gafas para soldadores.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 140.9 <br><strong>Punto de fusión:</strong> 931°C <br><strong>Punto de ebullición:</strong> 3017°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>3</sup>6s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +3 <br><strong>Número de electrones de valencia:</strong> 5 </p></div>',
  },
  {
    element_data_id: 'nd60',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-lanthanide pt-solid p6" id="nd"><div class="pt-element-number">60</div><h2 class="pt-element-symbol">Nd</h2><div class="pt-element-name">Neodimio</div><div class="pt-element-weight">144.2</div></section></div><div class="pt-col pt-col-desc"><p>El neodimio se aisló en 1925 y está presente en minerales como la monacita y la bastnasita.</p><p>Presenta un brillo metálico plateado y es uno de los metales de tierras raras más reactivo. El neodimio natural es una mezcla de siete isótopos estables, aunque existen otros 14 isótopos radioactivos.</p><p>Este elemento es un componente del vidrio coloreado, que aporta tonos de violeta pura a vino tinto y gris cálido. El vidrio elaborado con neodimio se usa como material láser para producir luz coherente y sus sales se utilizan como un colorante del esmalte.</p><p>El neodimio está clasificado con toxicidad aguda de baja a moderada y debe manipularse con precaución.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 144.2 <br><strong>Punto de fusión:</strong> 1016°C <br><strong>Punto de ebullición:</strong> 3127°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>4</sup>6s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +3 <br><strong>Número de electrones de valencia:</strong> 6 </p></div>',
  },
  {
    element_data_id: 'pm61',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-lanthanide pt-solid p6" id="pm"><div class="pt-element-number">61</div><h2 class="pt-element-symbol">Pm</h2><div class="pt-element-name">Prometio</div><div class="pt-element-weight">145</div></section></div><div class="pt-col pt-col-desc"><p>La existencia del prometio se predijo en 1902 y se confirmó en 1914, aunque las búsquedas del mismo en la Tierra no han tenido éxito.</p><p>Existen dos formas alotrópicas y se sabe muy poco acerca de sus propiedades. Ningún isótopo conocido del prometio tiene un período de semidesintegración superior a 17,7 años.</p><p>El prometio es un emisor de partículas beta blando y sus sales son de un color azul pálido o con un matiz verdoso. Se han creado más de 30 compuestos con este elemento.</p><p>Como fuente de emisión de radiación beta, se usa para producir luz y baterías nucleares de alimentación. Asimismo, es una posible fuente de radiación para rayos X portátiles o de calor y energía para satélites y sondas espaciales.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 145 <br><strong>Punto de fusión:</strong> 1042°C <br><strong>Punto de ebullición:</strong> 3000°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>5</sup>6s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> 3 <br><strong>Número de electrones de valencia:</strong> 7 </p></div>',
  },
  {
    element_data_id: 'sm62',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-lanthanide pt-solid p6" id="sm"><div class="pt-element-number">62</div><h2 class="pt-element-symbol">Sm</h2><div class="pt-element-name">Samario</div><div class="pt-element-weight">150.4</div></section></div><div class="pt-col pt-col-desc"><p>El samario se descubrió mediante espectroscopia en 1879 en la samarskita mineral.</p><p>Presenta un lustre plateado brillante y es razonablemente estable en el aire. Existen tres modificaciones cristalinas del metal con transformaciones a 734 y 922 °C. El metal se enciende en el aire a unos 150 °C.</p><p>Existen 21 isótopos de samario. Su forma natural es una mezcla de diversos isótopos, tres de los cuales son inestables con períodos de semidesintegración largos.</p><p>El samario se usa en la iluminación con arco de carbono para la industria cinematográfica y se ha usado en vidrios ópticos y láseres. Asimismo, se usa como absorbedor de neutrones en reactores nucleares.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 150.4 <br><strong>Punto de fusión:</strong> 1074°C <br><strong>Punto de ebullición:</strong> 1794°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>6</sup>6s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +3,2 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'eu63',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-lanthanide pt-solid p6" id="eu"><div class="pt-element-number">63</div><h2 class="pt-element-symbol">Eu</h2><div class="pt-element-name">Europio</div><div class="pt-element-weight">152.00</div></section></div><div class="pt-col pt-col-desc"><p>El europio se detectó por primera vez en 1890 y se aisló en una forma relativamente pura en 1901.</p><p>El europio tiene una apariencia metálica de color blanco plateado y se enciende en el aire a una temperatura de entre 150 y 180 °C. Es tan duro como el plomo y bastante dúctil. Es el más reactivo de los metales de tierras raras, se oxida rápidamente en el aire y reacciona con agua. Se han reconocido 17 isótopos de europio.</p><p>Este elemento se encuentra mayoritariamente en los minerales bastnasita y monacita y se ha identificado espectroscópicamente en el Sol y en determinadas estrellas.</p><p>Los isótopos de europio se estudian para su posible uso en aplicaciones de control nuclear, mientras que se usa plástico tratado con europio como material para láseres.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 152.00 <br><strong>Punto de fusión:</strong> 822°C <br><strong>Punto de ebullición:</strong> 1529°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>7</sup>6s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +3,2 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'gd64',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-lanthanide pt-solid p6" id="gd"><div class="pt-element-number">64</div><h2 class="pt-element-symbol">Gd</h2><div class="pt-element-name">Gadolinio</div><div class="pt-element-weight">157.3</div></section></div><div class="pt-col pt-col-desc"><p>El gadolinio, el óxido de gadolinio, se separó en 1880 y se aisló de forma independiente del itrio en 1886. Este metal de tierras raras se obtiene de la gadolinita y de otros minerales.</p><p>El gadolinio es de color blanco plateado con un brillo metálico y es maleable y dúctil. El metal es relativamente estable en aire seco, pero se deslustra en aire húmedo. Reacciona con lentitud con agua y se disuelve en ácido diluido. El gadolinio natural está compuesto de siete isótopos y, en realidad, se han reconocido 17 en total.</p><p>El gadolinio posee propiedades superconductoras y mejora la manejabilidad y la resistencia a la temperatura y a la oxidación del hierro, el cromo y otras aleaciones.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 157.3 <br><strong>Punto de fusión:</strong> 1313°C <br><strong>Punto de ebullición:</strong> 3273°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>7</sup>5d<sup>1</sup>6s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +3 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'tb65',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-lanthanide pt-solid p6" id="tb"><div class="pt-element-number">65</div><h2 class="pt-element-symbol">Tb</h2><div class="pt-element-name">Terbio</div><div class="pt-element-weight">158.9</div></section></div><div class="pt-col pt-col-desc"><p>El terbio se descubrió en 1843, es un metal de tierras raras. Se encuentra en la cerita, la gadolinita y otros minerales, y se puede recuperar de la monacita, la xenotima y la euxenita.</p><p>El gadolinio es de color gris plateado y es maleable y dúctil. En realidad, es tan blando que se puede cortar con un cuchillo y bastante estable en el aire. Teniendo eso en cuenta, se puede oxidar y pasar a tener un color chocolate o marrón oscuro. De este elemento se conocen 21 isótopos.</p><p>El borato de terbio y sodio se utiliza en dispositivos en estado sólido. Asimismo, actúa como estabilizador del cristal en células de combustible que funcionan a altas temperaturas.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 158.9 <br><strong>Punto de fusión:</strong> 1365°C <br><strong>Punto de ebullición:</strong> 3230°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>9</sup>6s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +3,4 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'dy66',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-lanthanide pt-solid p6" id="dy"><div class="pt-element-number">66</div><h2 class="pt-element-symbol">Dy</h2><div class="pt-element-name">Disprosio</div><div class="pt-element-weight">162.5</div></section></div><div class="pt-col pt-col-desc"><p>El disprosio se descubrió en 1886, pero ni el metal ni su óxido estuvieron disponibles hasta 1950. Se produce naturalmente con otros metales de tierras raras en una gama de minerales.</p><p>Este elemento tiene un lustre metálico brillante y es relativamente estable a temperatura ambiente. El disprosio se puede cortar con un cuchillo y puede procesarse con máquinas sin producir chispas si no se sobrecalienta. Sus propiedades físicas pueden verse afectadas de manera significativa por cantidades muy pequeñas de impurezas.</p><p>No existen muchas aplicaciones para el disprosio, pero pueden dársele usos metalúrgicos en aplicaciones de control nuclear o para aleaciones especiales de acero inoxidable. Cuando se combina con otros metales de tierras raras, se usa en materiales para láseres.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 162.5 <br><strong>Punto de fusión:</strong> 1412°C <br><strong>Punto de ebullición:</strong> 2567°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>10</sup>6s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +3 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'ho67',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-lanthanide pt-solid p6" id="ho"><div class="pt-element-number">67</div><h2 class="pt-element-symbol">Ho</h2><div class="pt-element-name">Holmio</div><div class="pt-element-weight">164.9</div></section></div><div class="pt-col pt-col-desc"><p>Las bandas de absorción espectral del holmio se advirtieron por primera vez en 1878 y la holmia, su óxido amarillo, se preparó en 1911. El holmio se produce en la gadolinita, la monacita y otros minerales de tierras raras. </p><p>El holmio puro posee un lustre plateado brillante y metálico. Es blando y maleable, estable a temperatura ambiente y en aire seco; además, se oxida rápidamente en aire húmedo y temperaturas elevadas.</p><p>El metal posee propiedades magnéticas inusuales, pero se conocen pocos usos para el holmio.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 164.9 <br><strong>Punto de fusión:</strong> 1474°C <br><strong>Punto de ebullición:</strong> 2700°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>11</sup>6s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +3 <br><strong>Número de electrones de valencia:</strong> 3 </p></div>',
  },
  {
    element_data_id: 'er68',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-lanthanide pt-solid p6" id="er"><div class="pt-element-number">68</div><h2 class="pt-element-symbol">Er</h2><div class="pt-element-name">Erbio</div><div class="pt-element-weight">167.3</div></section></div><div class="pt-col pt-col-desc"><p>El erbio tiene un historial largo pero no se aisló en una forma razonablemente pura hasta 1934. </p><p>El erbio puro es blando, maleable y tiene un lustre metálico plateado brillante. El metal es estable en aire y no se oxida rápidamente. En la naturaleza, el erbio es una mezcla de siete isótopos estables, aunque existen nueve isótopos radioactivos.</p><p>El erbio tiene algunas aplicaciones nucleares y metalúrgicas. Se usa en aleaciones y su óxido rosa se ha usado como un colorante para el vidrio y en esmaltes para porcelana.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 167.3 <br><strong>Punto de fusión:</strong> 1529°C <br><strong>Punto de ebullición:</strong> 2868°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>12</sup>6s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +3 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'tm69',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-lanthanide pt-solid p6" id="tm"><div class="pt-element-number">69</div><h2 class="pt-element-symbol">Tm</h2><div class="pt-element-name">Tulio</div><div class="pt-element-weight">168.9</div></section></div><div class="pt-col pt-col-desc"><p>El tulio se descubrió en 1879, se produce en una serie de minerales, como la monacita. Es el menos abundante de los elementos de tierras raras y es tan poco frecuente como la plata, el oro o el cadmio.</p><p>El tulio es de color gris plateado, blando, maleable, dúctil y se puede cortar con un cuchillo. Se conocen 25 isótopos; el tulio natural es estable.</p><p>El tulio, relativamente caro, tiene aplicaciones limitadas. Puede utilizarse como fuente de emisión de radiaciones para equipos portátiles de rayos X o como fuente de energía. El tulio natural puede ser útil en materiales magnéticos cerámicos o equipos de microondas.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 168.9 <br><strong>Punto de fusión:</strong> 1545°C <br><strong>Punto de ebullición:</strong> 1950°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>13</sup>6s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +3,2 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'yb70',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-lanthanide pt-solid p6" id="yb"><div class="pt-element-number">70</div><h2 class="pt-element-symbol">Yb</h2><div class="pt-element-name">Iterbio</div><div class="pt-element-weight">173.04</div></section></div><div class="pt-col pt-col-desc"><p>El iterbio se preparó por primera vez en 1937, aunque se consiguió producir una versión mucho más pura en 1953. Se produce en una gama de minerales junto con otros elementos de tierras raras.</p><p>El iterbio es brillante, plateado, lustroso, blando, maleable y dúctil. Es bastante estable, pero debe protegerse del aire y la humedad. El iterbio natural incluye siete isótopos estables; además, se conocen siete isótopos inestables.</p><p>Se ha usado el metal para mejorar las propiedades del acero inoxidable y un isótopo puede ser útil como fuente de emisión de radiación alternativa para las máquinas portátiles de rayos X, pero existen pocas aplicaciones más.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 173.04 <br><strong>Punto de fusión:</strong> 819°C <br><strong>Punto de ebullición:</strong> 1196°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>14</sup>6s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +3,2 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'lu71',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-lanthanide pt-solid p6" id="lu"><div class="pt-element-number">71</div><h2 class="pt-element-symbol">Lu</h2><div class="pt-element-name">Lutecio</div><div class="pt-element-weight">175.00</div></section></div><div class="pt-col pt-col-desc"><p>El lutecio se describió por primera vez en 1907, se produce en prácticamente todos los minerales que también contiene itrio, incluida la monacita. El nombre original del elemento se cambió en 1949.</p><p>Este metal puro es difícil de aislar. Es de color blanco plateado y relativamente estable en aire. Los nucleidos de lutecio estables emiten radiación beta pura (después de la activación) y pueden utilizarse como catalizadores en reacciones pirolizantes, de alquilación, hidrogenación y polimerización.</p><p>No se conoce ningún otro uso comercial del lutecio.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 175.00 <br><strong>Punto de fusión:</strong> 1663°C <br><strong>Punto de ebullición:</strong> 3402°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>14</sup>5d<sup>1</sup>6<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +3 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'hf72',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g4 p6" id="hf"><div class="pt-element-number">72</div><h2 class="pt-element-symbol">Hf</h2><div class="pt-element-name">Hafnio</div><div class="pt-element-weight">178.5</div></section></div><div class="pt-col pt-col-desc"><p>El hafnio se descubrió en 1923 y se ha utilizado desde entonces para aleaciones con hierro, titanio, niobio, tantalio y otros metales.</p><p>El hafnio es un metal dúctil con un lustre plateado brillante y es muy difícil de separar. Es resistente a alcalinos concentrados y reacciona a temperaturas elevadas con oxígeno, nitrógeno, carbono, boro, azufre y silicio, y directamente con halógenos para formar tetrahaluros.</p><p>Como el hafnio tiene una buena sección transversal de absorción para neutrones térmicos, propiedades mecánicas excelentes y resistencia a la corrosión, se usa para las barras de control de reactores nucleares. Asimismo, se usa en lámparas incandescentes y llenas con gas.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 178.5 <br><strong>Punto de fusión:</strong> 2227°C <br><strong>Punto de ebullición:</strong> 4600°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>14</sup>5d<sup>2</sup>6s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +4 <br><strong>Número de electrones de valencia:</strong> 4 </p></div>',
  },
  {
    element_data_id: 'ta73',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g5 p6" id="ta"><div class="pt-element-number">73</div><h2 class="pt-element-symbol">Ta</h2><div class="pt-element-name">Tantalio</div><div class="pt-element-weight">180.9</div></section></div><div class="pt-col pt-col-desc"><p>El tantalio se descubrió en 1802 y se produce principalmente en minerales columbita-tantalita. Se produjo por primera vez en una forma relativamente pura y dúctil en 1903.</p><p>El tantalio es gris, pesado y muy duro. En su forma pura, es dúctil y, cuando se forma como cable, se usa como filamento para aluminio evaporado y otros metales. Se usa para aumentar los puntos de fusión, la resistencia y la ductilidad de las aleaciones. El tantalio natural posee dos isótopos; se conocen un total de 25.</p><p>Este elemento también se usa en condensadores electrolíticos, piezas de hornos de vacío, equipos para procesos químicos, reactores nucleares, piezas para aeronaves y misiles y aplicaciones quirúrgicas.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 180.9 <br><strong>Punto de fusión:</strong> 2996°C <br><strong>Punto de ebullición:</strong> 5425°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>14</sup>5d<sup>3</sup>6s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +5 <br><strong>Número de electrones de valencia:</strong> 5 </p></div>',
  },
  {
    element_data_id: 'w74',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g6 p6" id="w"><div class="pt-element-number">74</div><h2 class="pt-element-symbol">W</h2><div class="pt-element-name">Tungsteno</div><div class="pt-element-weight">183.8</div></section></div><div class="pt-col pt-col-desc"><p>Aunque se detectó en 1779, el tungsteno no se aisló hasta 1883.</p><p>El tungsteno puro es un metal gris y blanco. Puede cortarse con una sierra, forjarse, hilarse, estirarse y extrudirse, pero es frágil y algo difícil de manipular. Se oxida en el aire, no tolera temperaturas altas y tiene una resistencia excelente a la corrosión. El tungsteno natural contiene cinco isótopos estables y se conocen 21 isótopos inestables.</p><p>El tungsteno y sus aleaciones se usan como filamentos en lámparas eléctricas, tubos de electrones y televisión, para trabajos con evaporación metálica y como puntos de contacto eléctrico para distribuidores automovilísticos.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 183.8 <br><strong>Punto de fusión:</strong> 3410°C <br><strong>Punto de ebullición:</strong> 5660°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>14</sup>5d<sup>4</sup>6s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +6,4 <br><strong>Número de electrones de valencia:</strong> 6 </p></div>',
  },
  {
    element_data_id: 're75',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g7 p6" id="re"><div class="pt-element-number">75</div><h2 class="pt-element-symbol">Re</h2><div class="pt-element-name">Renio</div><div class="pt-element-weight">186.2</div></section></div><div class="pt-col pt-col-desc"><p>El renio se descubrió en 1925 en minerales de platino y columbita. No se produce solo en la naturaleza o en un mineral específico, sino que se extiende por toda la corteza terrestre.</p><p>Este elemento es de color blanco plateado con un lustre metálico y, en forma de polvo, se puede consolidar, templar, doblar, bobinar o enrollar. El renio natural es una mezcla de dos isótopos estables; los otros 26 isótopos son inestables.</p><p>También se usa el renio en filamentos para espectrógrafos de masa, medidores iónicos y lámparas de destellos. Los catalizadores de renio resisten los efectos químicos del nitrógeno, el azufre y el fósforo, y se utilizan para hidrogenar productos químicos finos.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 186.2 <br><strong>Punto de fusión:</strong> 3180°C <br><strong>Punto de ebullición:</strong> 5600°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>14</sup>5d<sup>5</sup>6s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +7,4,6 <br><strong>Número de electrones de valencia:</strong> 7 </p></div>',
  },
  {
    element_data_id: 'os76',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g8 p6" id="os"><div class="pt-element-number">76</div><h2 class="pt-element-symbol">Os</h2><div class="pt-element-name">Osmio</div><div class="pt-element-weight">190.2</div></section></div><div class="pt-col pt-col-desc"><p>El osmio se descubrió en 1803. Se produce en el iridio, arenas de río que contienen platino y menas con níquel.</p><p>El metal es de color blanco azulado y lustroso, y duro y quebradizo incluso a altas temperaturas. Es difícil de fabricar y produce tetróxido de osmio, un agente oxidante potente y muy tóxico con un olor fuerte. Incluso concentraciones muy bajas han provocado daños pulmonares, cutáneos y oculares.</p><p>El tetróxido de osmio se ha usado en la detección de huellas dactilares y la tinción tisular. Pero lo más habitual es que el metal se use en aleaciones con otros elementos del grupo del platino para elaborar puntas de estilográficas, elementos de rotación para instrumentos, agujas para fonógrafos y contactos eléctricos.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 190.2 <br><strong>Punto de fusión:</strong> 3045°C <br><strong>Punto de ebullición:</strong> 5030°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>14</sup>5d<sup>6</sup>6s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +4,6,8 <br><strong>Número de electrones de valencia:</strong> 8 </p></div>',
  },
  {
    element_data_id: 'ir77',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g9 p6" id="ir"><div class="pt-element-number">77</div><h2 class="pt-element-symbol">Ir</h2><div class="pt-element-name">Iridio</div><div class="pt-element-weight">192.2</div></section></div><div class="pt-col pt-col-desc"><p>El iridio fue descubierto en 1803 y se llamó así por sus sales de colores.</p><p>Es el metal más resistente a la corrosión, es resistente a ácidos pero se altera con sales calientes como el cloruro sódico y el cianuro de sodio. Este elemento es parte de la familia del platino y es de color blanco con un tono amarillento. También es duro y quebradizo y, por tanto, duro para procesar con máquinas, formar o trabajar.</p><p>El iridio se produce de manera natural en depósitos aluviales y se puede obtener como subproducto de la minería del níquel.</p><p>Se usa para crisoles, contactos eléctricos y otras aplicaciones que requieran tolerancia a temperaturas elevadas. En combinación con el osmio, se usa para puntas de plumas y brújulas.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 192.2 <br><strong>Punto de fusión:</strong> 2410°C <br><strong>Punto de ebullición:</strong> 4130°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>14</sup>5d<sup>7</sup>6s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +4,3,6 <br><strong>Número de electrones de valencia:</strong> 9 </p></div>',
  },
  {
    element_data_id: 'pt78',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g10 p6" id="pt"><div class="pt-element-number">78</div><h2 class="pt-element-symbol">Pt</h2><div class="pt-element-name">Platino</div><div class="pt-element-weight">195.1</div></section></div><div class="pt-col pt-col-desc"><p>Aunque no se descubrió oficialmente hasta 1735, los americanos nativos precolombinos ya usaban el platino.</p><p>Se produce en la naturaleza con otros metales de su grupo en la tabla periódica, es de color blanco plateado, maleable y dúctil, y no se oxida en el aire. Por contra, el platino se corroe con halógenos, cianuros, azufre y alcalinos cáusticos.</p><p>Se usa mayoritariamente en joyería, cableado y recipientes de laboratorio, así como con electrodos sellados, elementos de termopares, contactos eléctricos y aparatos resistentes a la corrosión.</p><p>El platino también se utiliza para recubrir los conos de ojiva de misiles y las toberas de combustible de los motores de reacción, aplicaciones que requieren un rendimiento fiable a altas temperaturas.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 195.1 <br><strong>Punto de fusión:</strong> 1772°C <br><strong>Punto de ebullición:</strong> 3827°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>14</sup>5d<sup>9</sup>6s<sup>1</sup><br><strong>Estados de oxidación comunes:</strong> +4,2 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'au79',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-solid g11 p6" id="au"><div class="pt-element-number">79</div><h2 class="pt-element-symbol">Au</h2><div class="pt-element-name">Oro</div><div class="pt-element-weight">197.00</div></section></div><div class="pt-col pt-col-desc"><p>El oro se encuentra como un metal libre en la naturaleza, se encuentra en las vetas y depósitos aluviales, y está asociado con telúridos, cuarzo y pirita.</p><p>En su forma pura, es de un atractivo color amarillo metálico, pero puede ser negro, rubí o púrpura en cantidades más pequeñas. El oro, que es el metal más maleable y dúctil, se suele utilizar en aleaciones para aumentar su resistencia. Conduce el calor y la electricidad, refleja rayos infrarrojos y la mayoría de las veces no se ve afectado por el aire y las sustancias químicas. Algunos de los 18 isótopos del oro se usan en medicina para tratar el cáncer y la artritis.</p><p>También se utiliza en joyería, decoración, en el campo dental y en chapados y revestimientos.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 197.00 <br><strong>Punto de fusión:</strong> 1064°C <br><strong>Punto de ebullición:</strong> 3080°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>14</sup>5d<sup>10</sup>6s<sup>1</sup><br><strong>Estados de oxidación comunes:</strong> +3,1 <br><strong>Número de electrones de valencia:</strong> 1 </p></div>',
  },
  {
    element_data_id: 'hg80',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-liquid g12 p6" id="hg"><div class="pt-element-number">80</div><h2 class="pt-element-symbol">Hg</h2><div class="pt-element-name">Mercurio</div><div class="pt-element-weight">200.6</div></section></div><div class="pt-col pt-col-desc"><p>El mercurio es el único metal que es líquido a temperatura ambiente. Se encuentra principalmente en el mineral cinabrio.</p><p>El mercurio es pesado, de color blanco plateado, metálico y un conductor deficiente del calor, pero conduce bastante bien la electricidad. Forma amalgamas o aleaciones fácilmente con muchos metales. </p><p>El mercurio es un tóxico acumulativo y puede absorberse a través de la piel intacta o los aparatos respiratorio o gastrointestinal. El metilmercurio, un contaminante peligroso, se encuentra habitualmente en el agua y los canales fluviales.</p><p>El mercurio se ha usado en termómetros, barómetros, bombas de difusión, lámparas de vapor con mercurio, anuncios publicitarios, aparatos electrónicos, plaguicidas, elaboración de productos químicos, productos dentales, pintura, baterías y catalizadores.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 200.6 <br><strong>Punto de fusión:</strong> -38.9°C <br><strong>Punto de ebullición:</strong> 357°C <br><strong>Fase a temperatura y presión estándar:</strong> Liquid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>14</sup>5d<sup>10</sup>6s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +2,1 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'tl81',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-basic pt-solid g13 p6" id="tl"><div class="pt-element-number">81</div><h2 class="pt-element-symbol">Tl</h2><div class="pt-element-name">Talio</div><div class="pt-element-weight">204.4</div></section></div><div class="pt-col pt-col-desc"><p>El talio se descubrió en 1861 y se aisló en 1862. Se produce en la crookesita, la lorandita y la hutchinsonita y está presente en las piritas.</p><p>Cuando se expone al aire, el lustre metálico del talio desarrolla un matiz gris azulado. Es blando y maleable y se puede cortar con un cuchillo. El talio natural es una mezcla de dos isótopos; existe un total de 25 formas isotópicas.</p><p>El talio y sus compuestos son tóxicos y se sospecha que son cancerígenos. En el pasado se utilizaron como rodenticidas y mata hormigas, pero su uso doméstico se prohibió en Estados Unidos en 1975. También se usan en fotocélulas, materiales ópticos infrarrojos y vidrios especializados.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 204.4 <br><strong>Punto de fusión:</strong> 303°C <br><strong>Punto de ebullición:</strong> 1457°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>14</sup>5d<sup>10</sup>6s<sup>2</sup>6p<sup>1</sup><br><strong>Estados de oxidación comunes:</strong> +1,3 <br><strong>Número de electrones de valencia:</strong> 3 </p></div>',
  },
  {
    element_data_id: 'pb82',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-basic pt-solid g14 p6" id="pb"><div class="pt-element-number">82</div><h2 class="pt-element-symbol">Pb</h2><div class="pt-element-name">Plomo</div><div class="pt-element-weight">207.2</div></section></div><div class="pt-col pt-col-desc"><p>Se sabe que el plomo nativo es uno de los metales más antiguos y es poco frecuente que aparezca de manera natural. Los minerales con plomo más frecuentes son la galena, la anglesita, la cerusita y el minio.</p><p>El plomo es brillante, de color blanco azulado y muy blando. Es muy maleable, dúctil y conduce la electricidad de manera deficiente. El plomo natural es una mezcla de cuatro isótopos estables, todos procedentes de la desintegración de elementos radioactivos naturales.</p><p>El metal se usa en soldaduras, cobertura de cables, fontanería y municiones, y como escudo contra la radiación en equipos de rayos X y reactores nucleares. Los compuestos del plomo se usan en baterías, pinturas, cristal y en vidrio flint. Asimismo, es un tóxico acumulativo y debe manipularse con precaución.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 207.2 <br><strong>Punto de fusión:</strong> 327.5°C <br><strong>Punto de ebullición:</strong> 1740°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>14</sup>5d<sup>10</sup>6s<sup>2</sup>6p<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +2,4 <br><strong>Número de electrones de valencia:</strong> 4 </p></div>',
  },
  {
    element_data_id: 'bi83',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-basic pt-solid g15 p6" id="bi"><div class="pt-element-number">83</div><h2 class="pt-element-symbol">Bi</h2><div class="pt-element-name">Bismuto</div><div class="pt-element-weight">209.00</div></section></div><div class="pt-col pt-col-desc"><p>El bismuto se descubrió en 1753 y, a veces, se confunde con el plomo y el estaño.</p><p>Es un metal blanco, cristalino y quebradizo con un tono rosáceo. El elemento se da de forma natural en los minerales bismutinita (sulfuro de bismuto) y bismita. Y cuando se caliente en aire, produce una llama azul y forma vapores amarillos.</p><p>En agua, sus sales solubles forman sales básicas insolubles. Algunos compuestos se usan en cosmética y medicina.</p><p>Cuando se combina con manganeso, forma «bismanol», un imán permanente potente. Sus aleaciones se usan para elaborar objetos que se dañen a altas temperaturas, incluidos dispositivos de detección de incendios y sistemas de extinción.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 209.00 <br><strong>Punto de fusión:</strong> 271°C <br><strong>Punto de ebullición:</strong> 1560°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>14</sup>5d<sup>10</sup>6s<sup>2</sup>6p<sup>3</sup><br><strong>Estados de oxidación comunes:</strong> +3,5 <br><strong>Número de electrones de valencia:</strong> 5 </p></div>',
  },
  {
    element_data_id: 'po84',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-basic pt-solid g16 p6" id="po"><div class="pt-element-number">84</div><h2 class="pt-element-symbol">Po</h2><div class="pt-element-name">Polonio</div><div class="pt-element-weight">(209)</div></section></div><div class="pt-col pt-col-desc"><p>El polonio fue el primer elemento descubierto por Marie Curie en 1898. En 1934, los científicos produjeron polonio bombardeando bismuto con neutrones.</p><p>Este elemento es muy escaso y se encuentra con mucha menos frecuencia que el uranio o el radio. Sus emisiones alfa excitan el aire circundante para producir un aura azul. Existen dos alótropos y 25 isótopos de polonio.</p><p>El polonio se ha usado como fuente para la energía termoeléctrica en satélites espaciales, en dispositivos que eliminan las cargas estáticas y en cepillos para eliminar el polvo de las películas fotográficas.</p><p>El polonio-210 es muy peligroso en cualquier cantidad; el tejido corporal absorbe por completo sus partículas alfa.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (209) <br><strong>Punto de fusión:</strong> 254°C <br><strong>Punto de ebullición:</strong> 962°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>14</sup>5d<sup>10</sup>6s<sup>2</sup>6p<sup>4</sup><br><strong>Estados de oxidación comunes:</strong> +4,2 <br><strong>Número de electrones de valencia:</strong> 6 </p></div>',
  },
  {
    element_data_id: 'at85',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-semimetal pt-solid g17 p6" id="at"><div class="pt-element-number">85</div><h2 class="pt-element-symbol">At</h2><div class="pt-element-name">Astatina</div><div class="pt-element-weight">(210)</div></section></div><div class="pt-col pt-col-desc"><p>La astatina se sintetizó por primera vez en 1940, es muy radioactiva y existe por la desintegración de la radioactividad de otros elementos.</p><p>Las propiedades de la astatina son únicamente estimaciones. Puede tener una apariencia oscura, ser un semiconductor o un metal. Es más metálica que el yodo, aunque sus compuestos son similares a los compuestos de este, y es probable que se acumule en la glándula tiroidea.</p><p>El elemento no existe en forma pura; se evaporaría de manera inmediata por el calor de su propia radioactividad. Los cuatro isótopos de astatina que se producen naturalmente tienen una vida corta. El isótopo más estable y la forma médica útil se producen sintéticamente.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (210) <br><strong>Punto de fusión:</strong> 302°C <br><strong>Punto de ebullición:</strong> 337°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>14</sup>5d<sup>10</sup>6s<sup>2</sup>6p<sup>5</sup><br><strong>Número de electrones de valencia:</strong> 7 </p></div>',
  },
  {
    element_data_id: 'rn86',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-noble pt-gas g18 p6" id="rn"><div class="pt-element-number">86</div><h2 class="pt-element-symbol">Rn</h2><div class="pt-element-name">Radón</div><div class="pt-element-weight">(222)</div></section></div><div class="pt-col pt-col-desc"><p>Friederich Ernst Dorn descubrió el radón en 1900 y se aisló en 1908.</p><p>El radón, que es esencialmente inerte, es el gas conocido más pesado. También es radiactivo, tiene 39 isótopos y se encuentra en el suelo y en algunas aguas termales. Es un gas incoloro a temperatura ambiente y tiene un color fosforescente amarillo brillante por debajo de su punto de congelación.</p><p>El radón se usa terapéuticamente en forma de «semillas» o «agujas». Puede suponer un riesgo de salud cuando se acumula en edificios o se inhala. El exceso de radón es una preocupación para los trabajadores de las minas de uranio y muchas muertes por cáncer de pulmón se atribuyen a la exposición al radón.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (222) <br><strong>Punto de fusión:</strong> -71°C <br><strong>Punto de ebullición:</strong> -61.8°C <br><strong>Fase a temperatura y presión estándar:</strong> Gas <br><strong>Configuración electrónica:</strong> [Xe]4f<sup>14</sup>5d<sup>10</sup>6s<sup>2</sup>6p<sup>6</sup><br><strong>Estados de oxidación comunes:</strong> 0 <br><strong>Número de electrones de valencia:</strong> 8 </p></div>',
  },
  {
    element_data_id: 'fr87',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-alkali pt-solid g1 p7" id="fr"><div class="pt-element-number">87</div><h2 class="pt-element-symbol">Fr</h2><div class="pt-element-name">Francio</div><div class="pt-element-weight">(223)</div></section></div><div class="pt-col pt-col-desc"><p>El francio se descubrió en 1939, es el metal alcalino más pesado conocido y el último elemento encontrado en la naturaleza. Se produce a partir de la desintegración alfa del actinio o bombardeando torio con protones.</p><p>El francio es el más inestable de los primeros 101 elementos y tiene 33 isótopos. La comprensión de sus propiedades químicas procede de las técnicas radioquímicas. El único isótopo que se produce en la naturaleza tiene un período de semidesintegración de 22 minutos y se desintegra en astatina, radio y radón.</p><p>Aunque se supone que es un metal muy reactivo, el francio no existe en forma pura. Se encuentran trazas del elemento en el los minerales uranio y torio.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (223) <br><strong>Punto de fusión:</strong> 27°C <br><strong>Punto de ebullición:</strong> 677°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Rn]7s<sup>1</sup><br><strong>Estados de oxidación comunes:</strong> +1 <br><strong>Número de electrones de valencia:</strong> 1 </p></div>',
  },
  {
    element_data_id: 'ra88',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-alkaline pt-solid g2 p7" id="ra"><div class="pt-element-number">88</div><h2 class="pt-element-symbol">Ra</h2><div class="pt-element-name">Radio</div><div class="pt-element-weight">(226)</div></section></div><div class="pt-col pt-col-desc"><p>El radio fue descubierto en 1898 y aislado en 1911 por Madame Curie. Está presente en todos los minerales de uranio.</p><p>Este metal alcalinotérreo puro es de color blanco brillante, pero se vuelve negro cuando se expone al aire. Muestra luminiscencia y es de color rojo carmín en llamas. El radio emite rayos alfa, beta y gamma y produce neutrones cuando se mezcla con el berilio.</p><p>El radio también produce gas radón, que se usa en el tratamiento del cáncer y de otras enfermedades. La exposición al radio vía inhalación, inyección u otro tipo de exposición corporal puede causar cáncer y otras enfermedades.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (226) <br><strong>Punto de fusión:</strong> 700°C <br><strong>Punto de ebullición:</strong> 1140°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Rn]7s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +2 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'ac89',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-actinide pt-solid p7" id="ac"><div class="pt-element-number">89</div><h2 class="pt-element-symbol">Ac</h2><div class="pt-element-name">Actinio</div><div class="pt-element-weight">(227)</div></section></div><div class="pt-col pt-col-desc"><p>El actinio se descubrió en 1899 y se produce de manera natural con minerales de uranio.</p><p>El actinio-227 es un producto de la desintegración del uranio-235, emite radiaciones beta y tiene un período de semidesintegración de 21,6 años. Se descompone en torio-227, radio-223 y también en isótopos de radón, bismuto, polonio, plomo y otros productos de vida corta. También es una potente fuente de partículas alfa.</p><p>Desde el punto de vista químico, el actinio se comporta de manera similar a los metales de tierras raras como el lantano. El actinio purificado se equilibra con sus productos de descomposición tras 185 días y continúa desintegrándose en función de su período de semidesintegración. Dado que su actividad es de aproximadamente 150 veces la del radio, es valioso en la producción de neutrones.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (227) <br><strong>Punto de fusión:</strong> 1050°C <br><strong>Punto de ebullición:</strong> 3200°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Rn]6d<sup>1</sup>7s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +3 <br><strong>Número de electrones de valencia:</strong> 3 </p></div>',
  },
  {
    element_data_id: 'th90',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-actinide pt-solid p7" id="th"><div class="pt-element-number">90</div><h2 class="pt-element-symbol">Th</h2><div class="pt-element-name">Torio</div><div class="pt-element-weight">(232)</div></section></div><div class="pt-col pt-col-desc"><p>El torio se observó por primera vez en 1829 y se consideró radiactivo en 1898. Tiene 27 isótopos inestables.</p><p>El torio puro es un metal blanco plateado que se deslustra lentamente en el aire hasta convertirse en gris y negro. Es blando, muy dúctil y dimórfico (cambio a 1400 °C). El polvo de torio metálico puede ser piróforo y debe manipularse con cuidado. Las piezas pequeñas de torio se prenden y arden en color blanco cuando se calientan en aire.</p><p>El torio-232, un emisor de radiación alfa, que se desintegra en plomo-208, es la forma más frecuente. Es un nucleido primordial que se encuentra en la mayoría de las rocas, suelos y minerales, como la torita, la torianita y la monacita.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (232) <br><strong>Punto de fusión:</strong> 1750°C <br><strong>Punto de ebullición:</strong> 4790°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Rn]6d<sup>2</sup>7s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +4 <br><strong>Número de electrones de valencia:</strong> 4 </p></div>',
  },
  {
    element_data_id: 'pa91',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-actinide pt-solid p7" id="pa"><div class="pt-element-number">91</div><h2 class="pt-element-symbol">Pa</h2><div class="pt-element-name">Protactinio</div><div class="pt-element-weight">231.00</div></section></div><div class="pt-col pt-col-desc"><p>El protactinio se predijo por primera vez en 1871 y se aisló en 1900. Se le llamó originalmente «uranio-X» y, posteriormente, la IUPAC lo abrevió a partir de la forma «proto-actinio» en 1949.</p><p>Este elemento es un metal gris plateado, denso, que reacciona con oxígeno, vapor de agua y ácidos inorgánicos. Se conocen 29 radioisótopos, el más frecuente es 231Pa, un producto de la desintegración del uranio-235 y un emisor de radiación alfa con un período de semidesintegración de 32 700 años.</p><p>El protactinio es poco frecuente, se da en la naturaleza y es radiactivo. Es caro y, actualmente, no tiene ningún uso práctico. Habitualmente, se extrae del combustible nuclear gastado y se usa en investigación científica básica. Se debe manipular con precaución.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 231.00 <br><strong>Punto de fusión:</strong> 1570°C <br><strong>Punto de ebullición:</strong> 4000°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Rn]5f<sup>2</sup>6d<sup>1</sup>7s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +5,4 <br><strong>Número de electrones de valencia:</strong> 5 </p></div>',
  },
  {
    element_data_id: 'u92',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-actinide pt-solid p7" id="u"><div class="pt-element-number">92</div><h2 class="pt-element-symbol">U</h2><div class="pt-element-name">Uranio</div><div class="pt-element-weight">238.00</div></section></div><div class="pt-col pt-col-desc"><p>El uranio se identificó por primera vez en 1789 y se aisló en 1841. Su radioactividad no se descubrió hasta 1896.</p><p>El metal del uranio puro es blanco plateado, con una radioactividad débil y es más duro que la mayoría del resto de elementos. Es denso, maleable, dúctil, ligeramente paramagnético, con una electropositividad marcada, es un conductor de la electricidad precario y es piróforo en piezas pequeñas.</p><p>En la naturaleza se producen cantidades pequeñas de uranio en el agua, el suelo, las rocas y los minerales.</p><p>El óxido de uranio se usó durante siglos para colorear esmaltes cerámicos y vidrios, pero el uso contemporáneo del uranio explota sus propiedades nucleares. El uranio-235 es el único isótopo fósil que se produce de manera natural.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> 238.00 <br><strong>Punto de fusión:</strong> 1132°C <br><strong>Punto de ebullición:</strong> 3818°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Rn]5f<sup>3</sup>6d<sup>1</sup>7s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +6,3,4,5 <br><strong>Número de electrones de valencia:</strong> 6 </p></div>',
  },
  {
    element_data_id: 'np93',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-actinide pt-solid p7" id="np"><div class="pt-element-number">93</div><h2 class="pt-element-symbol">Np</h2><div class="pt-element-name">Neptunio</div><div class="pt-element-weight">(237)</div></section></div><div class="pt-col pt-col-desc"><p>En 1940, los investigadores bombardearon uranio con neutrones para formar un nuevo elemento llamado «neptunio». El neptunio-239 fue el primer elemento transuránico de la serie de actínidos descubierto y producido sintéticamente.</p><p>El metal es denso, plateado, reactivo y se encuentra en al menos tres alótropos. El neptunio tiene 25 isótopos radiactivos conocidos y el mayor rango de líquidos entre los puntos de ebullición y fusión (3363 °K). También forma compuestos, en especial haluros, óxidos y fluoruros.</p><p>El neptunio-237 se usa en dispositivos que detectan neutrones de alta energía. Y dado que el neptunio-237 se desintegra en un isótopo de protactinio con un período de semidesintegración mucho más corto, los científicos pueden determinar cuándo se separó y purificó por última vez.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (237) <br><strong>Punto de fusión:</strong> 640°C <br><strong>Punto de ebullición:</strong> 3900°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Rn]5f<sup>4</sup>6d<sup>1</sup>7s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +5,3,4,6 <br><strong>Número de electrones de valencia:</strong> 7 </p></div>',
  },
  {
    element_data_id: 'pu94',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-actinide pt-solid p7" id="pu"><div class="pt-element-number">94</div><h2 class="pt-element-symbol">Pu</h2><div class="pt-element-name">Plutonio</div><div class="pt-element-weight">(244)</div></section></div><div class="pt-col pt-col-desc"><p>El plutonio se descubrió observando la síntesis del neptunio-238. La primera muestra se produjo en 1943.</p><p>El plutonio es brillante, plateado y se deslustra en el aire. Duro y quebradizo, pasa a ser blando y dúctil en aleaciones, pero no es un buen conducto del calor ni de la electricidad. El plutonio es caliente al tacto y las piezas más grandes pueden producir suficiente calor para llevar el agua a la ebullición.</p><p>Este elemento tiene seis alótropos o estructuras de cristal con niveles similares de energía y densidades variables. Esto hace al plutonio muy sensible a la temperatura, la presión o los cambios químicos. El plutonio-239 es un componente importante de las armas nucleares y de las centrales nucleares con fines civiles.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (244) <br><strong>Punto de fusión:</strong> 641°C <br><strong>Punto de ebullición:</strong> 3232°C <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Rn]5f<sup>6</sup>7s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +4,3,5,6 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'am95',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-actinide pt-solid p7" id="am"><div class="pt-element-number">95</div><h2 class="pt-element-symbol">Am</h2><div class="pt-element-name">Americio</div><div class="pt-element-weight">(243)</div></section></div><div class="pt-col pt-col-desc"><p>El americio se creó en 1944 en un reactor nuclear, fue el cuarto elemento transuránico y sintético descubierto. Desde el punto de vista químico, se comporta como la serie de los lantánidos y motivó unas revisiones en la tabla periódica.</p><p>El primer americio metálico sustancial se produjo en 1951. Se deslustra lentamente, es de color blanco plateado y bastante maleable. Existen numerosos compuestos del americio y sus óxidos tienen aplicaciones prácticas.</p><p>El americio tiene diversos isótopos con períodos de semidesintegración que van de los 0,64 microsegundos a 7370 años. A causa de su radioactividad, debe manipularse de forma adecuada.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (243) <br><strong>Punto de fusión:</strong> 994°C <br><strong>Punto de ebullición:</strong> 2607°C <br><strong>Fase a temperatura y presión estándar:</strong> N/A <br><strong>Configuración electrónica:</strong> [Rn]5f<sup>7</sup>7s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +3,4,5,6 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'cm96',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-actinide pt-solid p7" id="cm"><div class="pt-element-number">96</div><h2 class="pt-element-symbol">Cm</h2><div class="pt-element-name">Curio</div><div class="pt-element-weight">(247)</div></section></div><div class="pt-col pt-col-desc"><p>El curio fue el tercer elemento transuránico descubierto, se identificó en 1944 y se aisló en 1947.</p><p>Es probable que exista en depósitos naturales de uranio, resultado de la captura de neutrones y la desintegración beta, pero aún no se ha detectado. El metal de curio es plateado, maleable, químicamente reactivo y electropositivo. También se oxida rápidamente en el aire. Los compuestos de curio y las soluciones son estables y de color amarillo o amarillo verdoso.</p><p>Se conocen 14 isótopos de curio. Tanto el curio-242 como el curio-244 se han usado como fuentes de energía para aplicaciones espaciales y médicas. El curio se acumula en los huesos cuando se absorbe y su radiación es destructiva y tóxica.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (247) <br><strong>Punto de fusión:</strong> 1340°C <br><strong>Punto de ebullición:</strong> N/A <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Rn]5f<sup>7</sup>6d<sup>1</sup>7s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +3 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'bk97',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-actinide pt-solid p7" id="bk"><div class="pt-element-number">97</div><h2 class="pt-element-symbol">Bk</h2><div class="pt-element-name">Berkelio</div><div class="pt-element-weight">(247)</div></section></div><div class="pt-col pt-col-desc"><p>El berkelio se produjo por primera vez en 1949. A partir de 1962, se determinó la primera estructura de un compuesto de berkelio.</p><p>El metal de berkelio es plateado, fácilmente soluble en ácidos minerales diluidos y se oxida rápidamente mediante aire u oxígeno a temperaturas elevadas. Presenta dos formas de cristal y existe en numerosas aleaciones y compuestos. Se conocen y se han sintetizado 14 isótopos de berkelio.</p><p>Como otros elementos actínidos, el berkelio puede acumularse en el sistema esquelético. Actualmente no tiene usos comerciales, pero se utiliza como objetivo para sintetizar elementos más pesados debido a su período de semidesintegración mayor y a su disponibilidad.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (247) <br><strong>Punto de fusión:</strong> 986°C <br><strong>Punto de ebullición:</strong> N/A <br><strong>Fase a temperatura y presión estándar:</strong> Solid <br><strong>Configuración electrónica:</strong> [Rn]5f<sup>9</sup>7s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +3,4 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'cf98',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-actinide pt-solid p7" id="cf"><div class="pt-element-number">98</div><h2 class="pt-element-symbol">Cf</h2><div class="pt-element-name">Californio</div><div class="pt-element-weight">(251)</div></section></div><div class="pt-col pt-col-desc"><p>El californio se produjo por primera vez en 1950 y actúa como otros elementos lantánidos. Es bastante reactivo y forma rápidamente un óxido en el aire o con humedad. El californio-252 es un emisor de neutrones muy potente y un peligro biológico. Se conocen 20 isótopos, aunque los isótopos 237 y 238 no se han probado.</p><p>Algunas aleaciones y numerosos compuestos de estado sólido se han preparado con californio, incluidos óxidos, haluros, oxihaluros, pníctidos, hidruros de calcógeno, telururos y algunos compuestos orgánicos.</p><p>Como fuente eficiente de emisión de neutrones, se espera encontrar muchos nuevos usos para el californio. Se utiliza en medidores de humedad de neutrones, registros de pozos y como fuente portátil de neutrones para el descubrimiento de metales.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (251) <br><strong>Punto de fusión:</strong> N/A <br><strong>Punto de ebullición:</strong> N/A <br><strong>Fase a temperatura y presión estándar:</strong> N/A <br><strong>Configuración electrónica:</strong> [Rn]5f<sup>10</sup>7s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +3 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'es99',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-actinide pt-solid p7" id="es"><div class="pt-element-number">99</div><h2 class="pt-element-symbol">Es</h2><div class="pt-element-name">Einstenio</div><div class="pt-element-weight">(252)</div></section></div><div class="pt-col pt-col-desc"><p>El einstenio se identificó en 1952 a partir de los restos de la primera gran explosión termonuclear. Desde entonces, se ha producido en el reactor de isótopos de alto flujo (HFIR, por sus siglas en inglés) del Oak Ridge National Laboratory.</p><p>Este elemento tiene 16 isótopos con tres isómeros. También es el primer metal divalente en la serie de los actínidos y tiene dos electrones de enlace, no tres. </p><p>No hay datos cristalográficos de rayos X para el einstenio debido a las interferencias de su intensa desintegración radiactiva. Sin embargo, a veces se usa para estudiar el envejecimiento acelerado, los daños que provoca la radiación y para tratamientos médicos dirigidos con radiación.</p><p>El einstenio no tiene usos comerciales, aunque permite los estudios fundamentales de los electrones 5f.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (252) <br><strong>Punto de fusión:</strong> N/A <br><strong>Punto de ebullición:</strong> N/A <br><strong>Fase a temperatura y presión estándar:</strong> N/A <br><strong>Configuración electrónica:</strong> [Rn]5f<sup>11</sup>7s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +3 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'fm100',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-actinide pt-solid p7" id="fm"><div class="pt-element-number">100</div><h2 class="pt-element-symbol">Fm</h2><div class="pt-element-name">Fermio</div><div class="pt-element-weight">(257)</div></section></div><div class="pt-col pt-col-desc"><p>El fermio se descubrió en 1952 después de que un avión teledirigido llevara un papel de filtro a través de la lluvia radioactiva de la primera prueba exitosa de una bomba de fusión de hidrógeno. Su formación mediante la absorción de neutrones y la desintegración beta de uranio-238 abrió la posibilidad de encontrar más elementos.</p><p>La composición química del fermio es la característica de los actínidos tardíos. Existen un total de 21 isótopos del fermio y dos son metaestables. La mayoría presentan períodos de semidesintegración que van de menos de un milisegundo a 30 minutos.</p><p>No existen compuestos puros del fermio, pero pueden formar complejos con ligandos orgánicos de oxígeno, cloruros, nitratos y otros elementos.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (257) <br><strong>Punto de fusión:</strong> N/A <br><strong>Punto de ebullición:</strong> N/A <br><strong>Fase a temperatura y presión estándar:</strong> N/A <br><strong>Configuración electrónica:</strong> [Rn]5f<sup>12</sup>7s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +3 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'md101',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-actinide pt-solid p7" id="md"><div class="pt-element-number">101</div><h2 class="pt-element-symbol">Md</h2><div class="pt-element-name">Mendelevio</div><div class="pt-element-weight">(258)</div></section></div><div class="pt-col pt-col-desc"><p>El mendelevio se identificó por primera vez en 1955 y su nombre se debe al creador de la tabla periódica. Este elemento sintético y radiactivo puede producirse únicamente en aceleradores de partículas.</p><p>Se han reconocido hasta ahora 16 isótopos radiactivos del mendelevio. Algunos experimentos muestran que posee un estado de oxidación dipositivo (II) algo estable y un estado de oxidación tripositivo (III). El isótopo 256Md se ha usado para determinar las propiedades químicas del elemento en solución acuosa.</p><p>La investigación científica es su única aplicación práctica.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (258) <br><strong>Punto de fusión:</strong> N/A <br><strong>Punto de ebullición:</strong> N/A <br><strong>Fase a temperatura y presión estándar:</strong> N/A <br><strong>Configuración electrónica:</strong> [Rn]5f<sup>13</sup>7s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +3,2 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'no102',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-actinide pt-solid p7" id="no"><div class="pt-element-number">102</div><h2 class="pt-element-symbol">No</h2><div class="pt-element-name">Nobelio</div><div class="pt-element-weight">(259)</div></section></div><div class="pt-col pt-col-desc"><p>El nobelio se descubrió e identificó en 1958. Lleva el nombre de Alfred Nobel, inventor de la dinamita, y la descubrieron prácticamente de forma simultánea equipos de investigación de Suecia, Estados Unidos y la Unión Soviética.</p><p>El elemento se produjo bombardeando curio con iones de carbono. Se han reconocido 12 isótopos, uno de los cuales tiene un período de semidesintegración de 3 minutos.</p><p>El nobelio se comporta de forma diferente a otros actínidos y de forma más parecida a algunos metales alcalinotérreos. Su capacidad para formar complejos con iones de cloruros es similar al bario y su capacidad para formar complejos con citratos, oxalatos y acetatos en soluciones acuosas se sitúa entre la del calcio y el estroncio.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (259) <br><strong>Punto de fusión:</strong> N/A <br><strong>Punto de ebullición:</strong> N/A <br><strong>Fase a temperatura y presión estándar:</strong> N/A <br><strong>Configuración electrónica:</strong> [Rn]5f<sup>14</sup>7s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> +2,3 <br><strong>Número de electrones de valencia:</strong> 2 </p></div>',
  },
  {
    element_data_id: 'lr103',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-actinide pt-solid p7" id="lr"><div class="pt-element-number">103</div><h2 class="pt-element-symbol">Lr</h2><div class="pt-element-name">Laurencio</div><div class="pt-element-weight">(262)</div></section></div><div class="pt-col pt-col-desc"><p>El lawrencio se descubrió en 1961 y se llamó así por el inventor del ciclotrón. Es sintético y radiactivo y puede producirse únicamente mediante aceleradores de partículas.</p><p>Este elemento se comporta como el lutecio, es trivalente y puede clasificarse como el primer metal de transición del 7.º período. Debido a su configuración como electrón, puede tener una volatilidad similar al plomo.</p><p>Se conocen 12 isótopos de nobelio radiactivo y se han identificado isómeros nucleares para las masas atómicas 251, 253 y 254. Los períodos de semidesintegración van de fracciones de milisegundo a 58 minutos. Se prevé que un isótopo no descubierto, 261No, tenga un período de semidesintegración de 170 minutos.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (262) <br><strong>Punto de fusión:</strong> N/A <br><strong>Punto de ebullición:</strong> N/A <br><strong>Fase a temperatura y presión estándar:</strong> N/A <br><strong>Configuración electrónica:</strong> [Rn]5f<sup>14</sup>7s<sup>2</sup>7p<sup>1</sup><br><strong>Estados de oxidación comunes:</strong> +3 <br><strong>Número de electrones de valencia:</strong> 3 </p></div>',
  },
  {
    element_data_id: 'rf104',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-unknown g4 p7" id="rf"><div class="pt-element-number">104</div><h2 class="pt-element-symbol">Rf</h2><div class="pt-element-name">Rutherfordio</div><div class="pt-element-weight">(267)</div></section></div><div class="pt-col pt-col-desc"><p>El rutherfordio se descubrió en 1964 gracias a investigaciones independientes en la Unión Soviética y Estados Unidos. Es radiactivo y se formó al mezclar núcleos de carbono con californio.</p><p>Este elemento no tiene isótopos naturales ni estables. Aunque se han documentado 16 isótopos, la mayoría de ellos se desintegran mediante fisión espontánea.</p><p>El rutherfordio es un metal de transición; su potencial de ionización, el radio atómico, las energías orbitales y los niveles básicos de estado ionizado son similares al hafnio y otros elementos del grupo cuatro.</p><p>Como del circonio y el hafnio, del rutherfordio se espera que formen un óxido estable y reaccionen con halógenos para formar tetrahaluros volátiles.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (267) <br><strong>Punto de fusión:</strong> N/A <br><strong>Punto de ebullición:</strong> N/A <br><strong>Fase a temperatura y presión estándar:</strong> N/A <br><strong>Configuración electrónica:</strong> [Rn]5f<sup>14</sup>6d<sup>2</sup>7s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> N/A <br><strong>Número de electrones de valencia:</strong> 4 </p></div>',
  },
  {
    element_data_id: 'db105',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-unknown g5 p7" id="db"><div class="pt-element-number">105</div><h2 class="pt-element-symbol">Db</h2><div class="pt-element-name">Dubnio</div><div class="pt-element-weight">(268)</div></section></div><div class="pt-col pt-col-desc"><p>Los intentos por descubrir el dubnio empezaron en 1967, pero no se identificó oficialmente hasta 1970. Originalmente, se le denominó «hahnio», aunque posteriormente la IUPAC cambió el nombre en honor al centro de Dubna del Instituto central de investigaciones nucleares, de la antigua Unión Soviética.</p><p>A partir de octubre de 1971, se sintetizaron dos nuevos isótopos utilizando el acelerador lineal de iones pesados en Berkeley, California. Se han reconocido hasta la fecha siete isótopos de dubnio.</p><p>En teoría, el dubnio es un metal de transición del grupo cinco y comparte muchas propiedades químicas con estos elementos. Sin embargo, los experimentos químicos de soluciones revelan que puede comportarse inesperadamente de forma más similar al niobio que al tántalo.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (268) <br><strong>Punto de fusión:</strong> N/A <br><strong>Punto de ebullición:</strong> N/A <br><strong>Fase a temperatura y presión estándar:</strong> N/A <br><strong>Configuración electrónica:</strong> *[Rn]5f<sup>14</sup>6d<sup>3</sup>7s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> N/A <br><strong>Número de electrones de valencia:</strong> 5 </p></div>',
  },
  {
    element_data_id: 'sg106',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-unknown g6 p7" id="sg"><div class="pt-element-number">106</div><h2 class="pt-element-symbol">Sg</h2><div class="pt-element-name">Seaborgio</div><div class="pt-element-weight">(269)</div></section></div><div class="pt-col pt-col-desc"><p>En 1974, el Instituto central de investigaciones nucleares de Dubna, en la antigua Unión Soviética, anunció el descubrimiento del seaborgio. Posteriormente, ese mismo años, trabajadores de los laboratorios Lawrence Berkeley y Livermore también afirmaron haber creado este elemento.</p><p>El seaborgio solo se puede crear mediante fusión en un acelerador de partículas; posteriormente, se desintegra en rutherfordio, después en nobelio y, por último, en seaborgio. Es radiactivo, no se encuentra en la naturaleza y su isótopo más estable tiene un período de semidesintegración de unos 14 minutos.</p><p>El elemento no presenta isótopos estables ni naturales; se han producido 12 isótopos y únicamente tres tienen estados metaestables.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (269) <br><strong>Punto de fusión:</strong> N/A <br><strong>Punto de ebullición:</strong> N/A <br><strong>Fase a temperatura y presión estándar:</strong> N/A <br><strong>Configuración electrónica:</strong> *[Rn]5f<sup>14</sup>6d<sup>4</sup>7s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> N/A <br><strong>Número de electrones de valencia:</strong> 6 </p></div>',
  },
  {
    element_data_id: 'bh107',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-unknown g7 p7" id="bh"><div class="pt-element-number">107</div><h2 class="pt-element-symbol">Bh</h2><div class="pt-element-name">Bohrio</div><div class="pt-element-weight">(270)</div></section></div><div class="pt-col pt-col-desc"><p>En 1976, científicos soviéticos del Instituto central de investigaciones nucleares de Dubna, en la antigua Unión Soviética, anunciaron la síntesis del elemento 107. Su existencia la confirmó de manera independiente un equipo de físicos de la Alemania Occidental.</p><p>El bohrio es un elemento radiactivo y sintético que no se encuentra en la naturaleza. Su isótopo más estable tiene un período de semidesintegración de aproximadamente 1 minuto. Aunque sus propiedades químicas se han caracterizado únicamente en parte, es similar a otros elementos del grupo siete.</p><p>De los 12 que se han sintetizado, el bohrio no tiene isótopos naturales ni estables. Uno de ellos presenta un estado metaestable y algunos pueden sufrir fisión espontánea.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (270) <br><strong>Punto de fusión:</strong> N/A <br><strong>Punto de ebullición:</strong> N/A <br><strong>Fase a temperatura y presión estándar:</strong> N/A <br><strong>Configuración electrónica:</strong> *[Rn]5f<sup>14</sup>6d<sup>5</sup>7s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> N/A <br><strong>Número de electrones de valencia:</strong> 7 </p></div>',
  },
  {
    element_data_id: 'hs108',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-unknown g8 p7" id="hs"><div class="pt-element-number">108</div><h2 class="pt-element-symbol">Hs</h2><div class="pt-element-name">Hasio</div><div class="pt-element-weight">(269)</div></section></div><div class="pt-col pt-col-desc"><p>El hasio es un elemento radiactivo sintético que no se produce en la naturaleza. Su isótopo conocido más estable tiene un período de semidesintegración de aproximadamente 16 segundos. Se descubrió en 1984.</p><p>El hasio es un elemento transactínido y un metal de transición. Las pruebas han confirmado que se comporta como un homólogo más pesado del osmio y reacciona con el oxígeno para formar un tetróxido volátil. Aunque solo se ha caracterizado parcialmente, las propiedades químicas del hasio son similares a las de otros elementos del grupo ocho.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (269) <br><strong>Punto de fusión:</strong> N/A <br><strong>Punto de ebullición:</strong> N/A <br><strong>Fase a temperatura y presión estándar:</strong> N/A <br><strong>Configuración electrónica:</strong> *[Rn]5f<sup>14</sup>6d<sup>6</sup>7s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> N/A <br><strong>Número de electrones de valencia:</strong> N/A </p></div>',
  },
  {
    element_data_id: 'mt109',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-unknown g9 p7" id="mt"><div class="pt-element-number">109</div><h2 class="pt-element-symbol">Mt</h2><div class="pt-element-name">Meitnerio</div><div class="pt-element-weight">(278)</div></section></div><div class="pt-col pt-col-desc"><p>En 1982, físicos de la Alemania Occidental produjeron e identificaron el elemento 109, el meitnerio. La creación de este elemento mostró la viabilidad de utilizar técnicas de fusión para elaborar nuevos núcleos pesados.</p><p>El meitnerio no tiene isótopos estables ni naturales; se forma mediante fusión de dos átomos o la desintegración de elementos más pesados. Se han documentado ocho isótopos, dos con estados metaestables. La mayoría de isótopos sufren desintegración alfa o fisión espontánea.</p><p>El meitnerio es un metal de transición y forma parte del grupo del platino. Según los cálculos científicos, es probable que sus propiedades básicas se parezcan a las del cobalto, el rodio y el iridio.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (278) <br><strong>Punto de fusión:</strong> N/A <br><strong>Punto de ebullición:</strong> N/A <br><strong>Fase a temperatura y presión estándar:</strong> N/A <br><strong>Configuración electrónica:</strong> *[Rn]5f<sup>14</sup>6d<sup>7</sup>7s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> N/A <br><strong>Número de electrones de valencia:</strong> N/A </p></div>',
  },
  {
    element_data_id: 'ds110',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-unknown g10 p7" id="ds"><div class="pt-element-number">110</div><h2 class="pt-element-symbol">Ds</h2><div class="pt-element-name">Darmstatio</div><div class="pt-element-weight">(281)</div></section></div><div class="pt-col pt-col-desc"><p>El darmstatio se produjo por primera vez en 1994 mediante la fusión de átomos de níquel y plomo en un acelerador de iones pesados.</p><p>Este elemento es muy radiactivo y su isótopo más estable tiene un período de semidesintegración de aproximadamente 12,7 segundos.</p><p>El darmstatio es un elemento transactínido, sin embargo, no se han realizado experimentos químicos para confirmar su comportamiento como metal de transición o si sus propiedades son similares a las del níquel, el paladio y el platino.</p><p>No se conocen isótopos estables ni naturales, aunque se han notificado nueve y existen varios que no se han confirmado.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (281) <br><strong>Punto de fusión:</strong> N/A <br><strong>Punto de ebullición:</strong> N/A <br><strong>Fase a temperatura y presión estándar:</strong> N/A <br><strong>Configuración electrónica:</strong> *[Rn]5f<sup>14</sup>6d<sup>9</sup>7s<sup>1</sup><br><strong>Estados de oxidación comunes:</strong> N/A <br><strong>Número de electrones de valencia:</strong> N/A </p></div>',
  },
  {
    element_data_id: 'rg111',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-unknown g11 p7" id="rg"><div class="pt-element-number">111</div><h2 class="pt-element-symbol">Rg</h2><div class="pt-element-name">Roentgenio</div><div class="pt-element-weight">(281)</div></section></div><div class="pt-col pt-col-desc"><p>El roentgenio es sintético, muy radiactivo y no se produce en la naturaleza. Se descubrió en 1994.</p><p>Este elemento no tiene isótopos estables ni naturales y se forma mediante fusión de dos átomos o la desintegración de elementos más pesados. Se han documentado nueve isótopos, dos con estados metaestables no confirmados. Estos isótopos sufren desintegración alfa o fisión espontánea en cuestión de segundos o minutos.</p><p>El roentgenio es un metal de transición y se espera que sus propiedades químicas básicas se parezcan al cobre, la plata y el oro.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (281) <br><strong>Punto de fusión:</strong> N/A <br><strong>Punto de ebullición:</strong> N/A <br><strong>Fase a temperatura y presión estándar:</strong> N/A <br><strong>Configuración electrónica:</strong> *[Rn]5f<sup>14</sup>6d<sup>10</sup>7s<sup>1</sup><br><strong>Estados de oxidación comunes:</strong> N/A <br><strong>Número de electrones de valencia:</strong> N/A </p></div>',
  },
  {
    element_data_id: 'cn112',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-transition pt-unknown g12 p7" id="cn"><div class="pt-element-number">112</div><h2 class="pt-element-symbol">Cn</h2><div class="pt-element-name">Copérnico</div><div class="pt-element-weight">(285)</div></section></div><div class="pt-col pt-col-desc"><p>El copérnico se creó en 1996 y tiene isótopos muy radiactivos que no se producen en la naturaleza. Su isótopo más estable tiene un período de semidesintegración de menos de 30 segundos.</p><p>Este elemento es extremadamente volátil y puede existir como un gas a temperatura y presión estándar. Se prevé que sus propiedades difieran de otros elementos del grupo como el cinc, el cadmio y el mercurio, y se prevé que sea el metal más noble de la tabla periódica.</p><p>No tiene isótopos estables ni naturales y se crea a partir de la fusión de átomos o la desintegración de elementos más pesados. Se conocen siete isótopos diferentes.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (285) <br><strong>Punto de fusión:</strong> N/A <br><strong>Punto de ebullición:</strong> N/A <br><strong>Fase a temperatura y presión estándar:</strong> N/A <br><strong>Configuración electrónica:</strong> *[Rn]5f<sup>14</sup>6d<sup>10</sup>7s<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> N/A <br><strong>Número de electrones de valencia:</strong> N/A </p></div>',
  },
  {
    element_data_id: 'nh113',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-basic pt-unknown g13 p7" id="nh"><div class="pt-element-number">113</div><h2 class="pt-element-symbol">NH</h2><div class="pt-element-name">Nihonio</div><div class="pt-element-weight">(286)</div></section></div><div class="pt-col pt-col-desc"><p>El nihonio es un elemento transactínido cuyo nombre oficial se estableció en 2016. Su isótopo conocido más estable tiene un período de semidesintegración de apenas 10 segundos.</p><p>Poco más se conoce de este elemento, que solo se ha producido en cantidades minúsculas que se desintegran rápidamente. Se cree que el nihonio tiene propiedades similares al boro, el aluminio, el galio, el indio, el talio y otros metales del bloque p.</p><p>Experimentos previos detectaron que el nihonio elemento no es muy volátil, pero su química no se ha explorado en profundidad.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (286) <br><strong>Punto de fusión:</strong> N/A <br><strong>Punto de ebullición:</strong> N/A <br><strong>Fase a temperatura y presión estándar:</strong> N/A <br><strong>Configuración electrónica:</strong> *[Rn]5f<sup>14</sup>6d<sup>10</sup>7s<sup>2</sup>7p<sup>1</sup><br><strong>Estados de oxidación comunes:</strong> N/A <br><strong>Número de electrones de valencia:</strong> N/A </p></div>',
  },
  {
    element_data_id: 'fl114',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-basic pt-unknown g14 p7" id="fl"><div class="pt-element-number">114</div><h2 class="pt-element-symbol">Fl</h2><div class="pt-element-name">Flerovio</div><div class="pt-element-weight">(289)</div></section></div><div class="pt-col pt-col-desc"><p>El flerovio es un elemento radiactivo superpesado y sintético que se descubrió en 1999.</p><p>Se considera un transactínido y es el miembro más pesado conocido del grupo del carbono (14).</p><p>Algunos estudios químicos realizados en 2007 y 2008 detectaron que es inesperadamente volátil y puede tener propiedades similares a los gases nobles. Podría también mostrar propiedades metálicas, aunque la cuestión de si se comporta más como un metal o como un gas todavía no está resuelta (en 2018).</p><p>El flerovio se crea mediante fusión o por la desintegración radiactiva de elementos más pesados.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (289) <br><strong>Punto de fusión:</strong> N/A <br><strong>Punto de ebullición:</strong> N/A <br><strong>Fase a temperatura y presión estándar:</strong> N/A <br><strong>Configuración electrónica:</strong> *[Rn]5f<sup>1410</sup>7s<sup>2</sup>7p<sup>2</sup><br><strong>Estados de oxidación comunes:</strong> N/A <br><strong>Número de electrones de valencia:</strong> N/A </p></div>',
  },
  {
    element_data_id: 'mc115',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-basic pt-unknown g15 p7" id="mc"><div class="pt-element-number">115</div><h2 class="pt-element-symbol">Mc</h2><div class="pt-element-name">Moscovio</div><div class="pt-element-weight">(289)</div></section></div><div class="pt-col pt-col-desc"><p>El elemento 115, el moscovio, se sintetizó por primera vez en 2003, se reconoció en 2015 y se le puso nombre en 2016.</p><p>Es un elemento extremadamente radiactivo, su isótopo conocido más estable tiene un período de semidesintegración de poco más de 1 minuto. El moscovio es un metal del bloque p que se estima que presenta propiedades similares a sus homólogos: nitrógeno, fósforo, arsénico, antimonio y bismuto.</p><p>También se prevé que sea similar al talio, con un solo electrón suelto fuera de una corteza semicerrada.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (289) <br><strong>Punto de fusión:</strong> N/A <br><strong>Punto de ebullición:</strong> N/A <br><strong>Fase a temperatura y presión estándar:</strong> N/A <br><strong>Configuración electrónica:</strong> *[Rn]5f<sup>14</sup>6d<sup>10</sup>7s<sup>2</sup>7p<sup>3</sup><br><strong>Estados de oxidación comunes:</strong> N/A <br><strong>Número de electrones de valencia:</strong> N/A </p></div>',
  },
  {
    element_data_id: 'lv116',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-basic pt-unknown g16 p7" id="lv"><div class="pt-element-number">116</div><h2 class="pt-element-symbol">Lv</h2><div class="pt-element-name">Livermorio</div><div class="pt-element-weight">(293)</div></section></div><div class="pt-col pt-col-desc"><p>El livermorio es un elemento sintético que se notificó por primera vez en el año 2000. Se conocen cuatro isótopos radiactivos de livermorio, el período de semidesintegración más largo es de aproximadamente 60 milisegundos. Hay un quinto isótopo no confirmado.</p><p>Se considera un metal del bloque p y es el calcógeno más pesado. El livermorio también se prevé que tenga propiedades en común con el oxígeno, el azufre, el selenio y el telurio.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (293) <br><strong>Punto de fusión:</strong> N/A <br><strong>Punto de ebullición:</strong> N/A <br><strong>Fase a temperatura y presión estándar:</strong> N/A <br><strong>Configuración electrónica:</strong> *[Rn]5f<sup>14</sup>6d<sup>10</sup>7s<sup>2</sup>7p<sup>4</sup><br><strong>Estados de oxidación comunes:</strong> N/A <br><strong>Número de electrones de valencia:</strong> N/A </p></div>',
  },
  {
    element_data_id: 'ts117',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-halogen pt-unknown g17 p7" id="ts"><div class="pt-element-number">117</div><h2 class="pt-element-symbol">Ts</h2><div class="pt-element-name">Teneso</div><div class="pt-element-weight">(294)</div></section></div><div class="pt-col pt-col-desc"><p>El teneso se descubrió en 2010 y se le puso nombre en 2016. Dado que es un elemento sintético y muy radiactivo, no se produce en la naturaleza.</p><p>Es el elemento que se ha descubierto más recientemente (a partir de 2019), sus átomos solo duran décimas o centésimas de milisegundos.</p><p>Este elemento se considera un halógeno y debería ser un metal volátil del bloque p. Por lo demás, se espera que sus propiedades sean similares a las de los otros halógenos: flúor, cloro, bromo, yodo y astatina.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (294) <br><strong>Punto de fusión:</strong> N/A <br><strong>Punto de ebullición:</strong> N/A <br><strong>Fase a temperatura y presión estándar:</strong> N/A <br><strong>Configuración electrónica:</strong> *[Rn]5f<sup>14</sup>6d<sup>10</sup>7s<sup>2</sup>7p<sup>5</sup><br><strong>Estados de oxidación comunes:</strong> N/A <br><strong>Número de electrones de valencia:</strong> N/A </p></div>',
  },
  {
    element_data_id: 'og118',
    element_data_content: '<div class="pt-col pt-col-element"><section class="pt-element pt-noble pt-unknown g18 p7" id="og"><div class="pt-element-number">118</div><h2 class="pt-element-symbol">Og</h2><div class="pt-element-name">Oganesón</div><div class="pt-element-weight">(294)</div></section></div><div class="pt-col pt-col-desc"><p>El oganesón es un elemento radiactivo que se sintetizó por primera vez en 2002. Se consideró un elemento nuevo en 2015 y se le puso nombre en 2016.</p><p>Tiene el número y la masa atómicos más altos de todos los elementos conocidos. Solo se han detectado un puñado de átomos desde 2005. El átomo de oganesón es muy inestable.</p><p>Su naturaleza radiactiva impide el estudio experimental real. Los cálculos teóricos indican que puede ser significativamente reactivo, a diferencia de los otros gases nobles de su grupo, y que en realidad puede ser un sólido.</p></div><div class="pt-col pt-col-detail"><p><strong>Peso atómico:</strong> (294) <br><strong>Punto de fusión:</strong> N/A <br><strong>Punto de ebullición:</strong> N/A <br><strong>Fase a temperatura y presión estándar:</strong> N/A <br><strong>Configuración electrónica:</strong> *[Rn]5f<sup>14</sup>6d<sup>10</sup>7s<sup>2</sup>7p<sup>6</sup><br><strong>Estados de oxidación comunes:</strong> N/A <br><strong>Número de electrones de valencia:</strong> N/A </p></div>',
  },
];
