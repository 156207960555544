<template>
  <div>
    <NavBar class="fixed" />
    <!--<router-view />-->
    <router-view :key="$route.fullPath"></router-view>
  </div>
</template>
<script>
import NavBar from '@/components/nav-bar';
export default {
  components: {NavBar},
  created() {
    const targetNode = document.body;

    // Options for the observer (which mutations to observe)
    const config = {attributes: true, childList: true, subtree: true};

    // eslint-disable-next-line no-unused-vars
    const callback = function(mutationsList, observer) {
      for (const mutation of mutationsList) {
        try {
          /*if (mutation) {
            console.log(mutation);
          }*/
          if (
            mutation.type === 'childList' &&
            mutation.addedNodes &&
            mutation.addedNodes.length === 1 &&
            mutation.addedNodes[0] &&
            mutation.addedNodes[0].className ===
              'tox tox-silver-sink tox-tinymce-aux'
          ) {
            //console.log('Opcion A.');
            let element = mutation.addedNodes[0];
            if (element.parentNode === document.body) {
              document
                .getElementById('floatingarea')
                .appendChild(mutation.addedNodes[0]);
            }
          } else if (
            mutation.type === 'childList' &&
            mutation.addedNodes &&
            mutation.addedNodes.length === 1 &&
            mutation.addedNodes[0] &&
            mutation.addedNodes[0].className ===
              'tox tox-silver-sink tox-tinymce-aux tox-platform-touch'
          ) {
            //console.log('Opcion A2.');
            let element = mutation.addedNodes[0];
            if (element.parentNode === document.body) {
              document
                .getElementById('floatingarea')
                .appendChild(mutation.addedNodes[0]);
            }
          } else if (
            mutation.type === 'childList' &&
            mutation.target.className
              .toString()
              .indexOf('tox tox-silver-sink tox-tinymce-aux') > -1 &&
            mutation.addedNodes.length === 1 &&
            mutation.addedNodes[0].id.toString().indexOf('aria-owns_') > -1
          ) {
            //console.log('Opcion B');
            document.getElementById('floatingarea').style.marginTop = '15%';
            document.getElementById('floatingarea').style.marginBottom = '10%';
            document.getElementById('floatingarea').style.height = '70%';
            document.getElementById('floatingarea').style.width = '100%';
          } else if (
            mutation.type === 'childList' &&
            mutation.removedNodes &&
            mutation.target.id.toString().indexOf('aria-owns_') > -1 &&
            mutation.removedNodes.length === 1
          ) {
            document.getElementById('floatingarea').style.marginTop = '0px';
            document.getElementById('floatingarea').style.marginBottom = '0px';
            document.getElementById('floatingarea').style.height = '0px';
            document.getElementById('floatingarea').style.width = '0px';
            document.getElementById('floatingarea').style.height = '0px';
            document.getElementById('floatingarea').style.width = '0px';
          }
        } catch (exception) {
          console.log(exception);
        }
      }
    };

    // Create an observer instance linked to the callback function
    const observer = new MutationObserver(callback);

    // Start observing the target node for configured mutations
    observer.observe(targetNode, config);
  },
};
</script>
