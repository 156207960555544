var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("div", { staticClass: "section content-title-group" }, [
        _c("h2", { staticClass: "title" }, [_vm._v(_vm._s(_vm.nombrePagina))]),
      ]),
      _c("br"),
      _c("Accordion", {
        attrs: { informacion: _vm.informacion, paginaid: _vm.numeroDePagina },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }