var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "app" } }, [
    _c("div", { staticClass: "section content-title-group" }, [
      _c("h2", { staticClass: "title" }, [_vm._v(_vm._s(_vm.titulo))]),
    ]),
    _c(
      "section",
      { staticClass: "main", attrs: { role: "main", id: "main" } },
      [
        _c("div", {}, [
          _c("div", { staticClass: "small-12 medium-12 large-12 columns" }, [
            _c("div", {}, [
              _c("div", {}, [
                _c("main", { staticClass: "pt-wrap" }, [
                  _c(
                    "div",
                    { staticClass: "pt-table" },
                    [
                      _vm._l(_vm.fields, function (elemento) {
                        return _c(
                          "section",
                          {
                            key: elemento.element_symbol.toLowerCase(),
                            class: elemento.element_clases,
                            attrs: {
                              id: elemento.element_symbol.toLowerCase(),
                            },
                          },
                          [
                            _c(
                              "a",
                              {
                                attrs: {
                                  href: "#dialogoModal",
                                  "data-toggle": "modal",
                                  "data-target": "#dialogoModal",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.cargarModalElemento(
                                      elemento.element_onclick_id,
                                      elemento.element_name
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "pt-element-number" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(elemento.element_number) +
                                        " "
                                    ),
                                  ]
                                ),
                                _c("h2", { staticClass: "pt-element-symbol" }, [
                                  _vm._v(
                                    " " + _vm._s(elemento.element_symbol) + " "
                                  ),
                                ]),
                                _c("div", { staticClass: "pt-element-name" }, [
                                  _vm._v(
                                    " " + _vm._s(elemento.element_name) + " "
                                  ),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "pt-element-weight" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(elemento.element_weight) +
                                        " "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        )
                      }),
                      _vm._m(0),
                      _vm._m(1),
                    ],
                    2
                  ),
                ]),
              ]),
            ]),
          ]),
        ]),
      ]
    ),
    _vm._m(2),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "section",
      { staticClass: "pt-element pt-lanthanide", attrs: { id: "lan" } },
      [
        _c("div", { staticClass: "pt-element-number" }, [_vm._v(" ")]),
        _c("h2", { staticClass: "pt-element-symbol" }, [_vm._v(" ")]),
        _c("div", { staticClass: "pt-element-name" }, [_vm._v("Lantánidos")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "section",
      { staticClass: "pt-element pt-actinide", attrs: { id: "act" } },
      [
        _c("div", { staticClass: "pt-element-number" }, [_vm._v(" ")]),
        _c("h2", { staticClass: "pt-element-symbol" }, [_vm._v(" ")]),
        _c("div", { staticClass: "pt-element-name" }, [_vm._v("Actínidos")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "modal fade",
        attrs: {
          id: "dialogoModal",
          tabindex: "-1",
          role: "dialog",
          "aria-labelledby": "dialogoModalLabel",
          "aria-hidden": "true",
        },
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c(
                  "h5",
                  {
                    staticClass: "modal-title",
                    attrs: { id: "dialogoModalLabel" },
                  },
                  [_vm._v("Modal title")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "close",
                    attrs: {
                      type: "button",
                      "data-dismiss": "modal",
                      "aria-label": "Close",
                    },
                  },
                  [
                    _c("span", { attrs: { "aria-hidden": "true" } }, [
                      _vm._v("×"),
                    ]),
                  ]
                ),
              ]),
              _c(
                "div",
                { staticClass: "modal-body", attrs: { id: "textoModal" } },
                [_vm._v(" ... ")]
              ),
            ]),
          ]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }