export default [
  {
    element_number: '1',
    element_name: 'Hidrógeno',
    element_onclick_id: '#h1',
    element_clases: 'pt-element pt-nonmetal pt-gas g1 p1',
    element_symbol: 'H',
    element_weight: '1.008',
  },
  {
    element_number: '2',
    element_name: 'Helio',
    element_onclick_id: '#he2',
    element_clases: 'pt-element pt-noble pt-gas g18 p1',
    element_symbol: 'He',
    element_weight: '4.003',
  },
  {
    element_number: '3',
    element_name: 'Litio',
    element_onclick_id: '#li3',
    element_clases: 'pt-element pt-alkali pt-solid g1 p2',
    element_symbol: 'Li',
    element_weight: '6.941',
  },
  {
    element_number: '4',
    element_name: 'Berilio',
    element_onclick_id: '#be4',
    element_clases: 'pt-element pt-alkaline pt-solid g2 p2',
    element_symbol: 'Be',
    element_weight: '9.012',
  },
  {
    element_number: '5',
    element_name: 'Boro',
    element_onclick_id: '#b5',
    element_clases: 'pt-element pt-semimetal pt-metalloids pt-solid g13 p2',
    element_symbol: 'B',
    element_weight: '10.81',
  },
  {
    element_number: '6',
    element_name: 'Carbono',
    element_onclick_id: '#c6',
    element_clases: 'pt-element pt-nonmetal pt-solid g14 p2',
    element_symbol: 'C',
    element_weight: '12.01',
  },
  {
    element_number: '7',
    element_name: 'Nitrógeno',
    element_onclick_id: '#n7',
    element_clases: 'pt-element pt-nonmetal pt-gas g15 p2',
    element_symbol: 'N',
    element_weight: '14.01',
  },
  {
    element_number: '8',
    element_name: 'Oxígeno',
    element_onclick_id: '#o8',
    element_clases: 'pt-element pt-nonmetal pt-gas g16 p2',
    element_symbol: 'O',
    element_weight: '16.00',
  },
  {
    element_number: '9',
    element_name: 'Flúor',
    element_onclick_id: '#f9',
    element_clases: 'pt-element pt-halogen pt-gas g17 p2',
    element_symbol: 'F',
    element_weight: '19.00',
  },
  {
    element_number: '10',
    element_name: 'Neón',
    element_onclick_id: '#ne10',
    element_clases: 'pt-element pt-noble pt-gas g18 p2',
    element_symbol: 'Ne',
    element_weight: '20.18',
  },
  {
    element_number: '11',
    element_name: 'Sodio',
    element_onclick_id: '#na11',
    element_clases: 'pt-element pt-alkali pt-solid g1 p3',
    element_symbol: 'Na',
    element_weight: '22.99',
  },
  {
    element_number: '12',
    element_name: 'Magnesio',
    element_onclick_id: '#mg12',
    element_clases: 'pt-element pt-alkaline pt-solid g2 p3',
    element_symbol: 'Mg',
    element_weight: '24.31',
  },
  {
    element_number: '13',
    element_name: 'Aluminio',
    element_onclick_id: '#al13',
    element_clases: 'pt-element pt-basic pt-post-transition pt-solid g13 p3',
    element_symbol: 'Al',
    element_weight: '26.98',
  },
  {
    element_number: '14',
    element_name: 'Silicio',
    element_onclick_id: '#si14',
    element_clases: 'pt-element pt-semimetal pt-metalloids pt-solid g14 p3',
    element_symbol: 'Si',
    element_weight: '28.09',
  },
  {
    element_number: '15',
    element_name: 'Fósforo',
    element_onclick_id: '#p15',
    element_clases: 'pt-element pt-nonmetal pt-solid g15 p3',
    element_symbol: 'P',
    element_weight: '30.97',
  },
  {
    element_number: '16',
    element_name: 'Azufre',
    element_onclick_id: '#s16',
    element_clases: 'pt-element pt-nonmetal pt-solid g16 p3',
    element_symbol: 'S',
    element_weight: '32.07',
  },
  {
    element_number: '17',
    element_name: 'Cloro',
    element_onclick_id: '#cl17',
    element_clases: 'pt-element pt-halogen pt-gas g17 p3',
    element_symbol: 'Cl',
    element_weight: '35.45',
  },
  {
    element_number: '18',
    element_name: 'Argón',
    element_onclick_id: '#ar18',
    element_clases: 'pt-element pt-noble pt-gas g18 p3',
    element_symbol: 'Ar',
    element_weight: '39.95',
  },
  {
    element_number: '19',
    element_name: 'Potasio',
    element_onclick_id: '#k19',
    element_clases: 'pt-element pt-alkali pt-solid g1 p4',
    element_symbol: 'K',
    element_weight: '39.10',
  },
  {
    element_number: '20',
    element_name: 'Calcio',
    element_onclick_id: '#ca20',
    element_clases: 'pt-element pt-alkaline pt-solid g2 p4',
    element_symbol: 'Ca',
    element_weight: '40.08',
  },
  {
    element_number: '21',
    element_name: 'Escandio',
    element_onclick_id: '#sc21',
    element_clases: 'pt-element pt-transition pt-solid g3 p4',
    element_symbol: 'Sc',
    element_weight: '44.96',
  },
  {
    element_number: '22',
    element_name: 'Titanio',
    element_onclick_id: '#ti22',
    element_clases: 'pt-element pt-transition pt-solid g4 p4',
    element_symbol: 'Ti',
    element_weight: '47.87',
  },
  {
    element_number: '23',
    element_name: 'Vanadio',
    element_onclick_id: '#v23',
    element_clases: 'pt-element pt-transition pt-solid g5 p4',
    element_symbol: 'V',
    element_weight: '50.94',
  },
  {
    element_number: '24',
    element_name: 'Cromo',
    element_onclick_id: '#cr24',
    element_clases: 'pt-element pt-transition pt-solid g6 p4',
    element_symbol: 'Cr',
    element_weight: '52.00',
  },
  {
    element_number: '25',
    element_name: 'Manganeso',
    element_onclick_id: '#mn25',
    element_clases: 'pt-element pt-transition pt-solid g7 p4',
    element_symbol: 'Mn',
    element_weight: '54.94',
  },
  {
    element_number: '26',
    element_name: 'Hierro',
    element_onclick_id: '#fe26',
    element_clases: 'pt-element pt-transition pt-solid g8 p4',
    element_symbol: 'Fe',
    element_weight: '55.85',
  },
  {
    element_number: '27',
    element_name: 'Cobalto',
    element_onclick_id: '#co27',
    element_clases: 'pt-element pt-transition pt-solid g9 p4',
    element_symbol: 'Co',
    element_weight: '58.93',
  },
  {
    element_number: '28',
    element_name: 'Níquel',
    element_onclick_id: '#ni28',
    element_clases: 'pt-element pt-transition pt-solid g10 p4',
    element_symbol: 'Ni',
    element_weight: '58.69',
  },
  {
    element_number: '29',
    element_name: 'Cobre',
    element_onclick_id: '#cu29',
    element_clases: 'pt-element pt-transition pt-solid g11 p4',
    element_symbol: 'Cu',
    element_weight: '63.55',
  },
  {
    element_number: '30',
    element_name: 'Zinc',
    element_onclick_id: '#zn30',
    element_clases: 'pt-element pt-transition pt-solid g12 p4',
    element_symbol: 'Zn',
    element_weight: '65.38',
  },
  {
    element_number: '31',
    element_name: 'Galio',
    element_onclick_id: '#ga31',
    element_clases: 'pt-element pt-basic pt-post-transition pt-solid g13 p4',
    element_symbol: 'Ga',
    element_weight: '69.72',
  },
  {
    element_number: '32',
    element_name: 'Germanio',
    element_onclick_id: '#ge32',
    element_clases: 'pt-element pt-semimetal pt-metalloids pt-solid g14 p4',
    element_symbol: 'Ge',
    element_weight: '72.63',
  },
  {
    element_number: '33',
    element_name: 'Arsénico',
    element_onclick_id: '#as33',
    element_clases: 'pt-element pt-semimetal pt-metalloids pt-solid g15 p4',
    element_symbol: 'As',
    element_weight: '74.92',
  },
  {
    element_number: '34',
    element_name: 'Selenio',
    element_onclick_id: '#se34',
    element_clases: 'pt-element pt-nonmetal pt-solid g16 p4',
    element_symbol: 'Se',
    element_weight: '78.97',
  },
  {
    element_number: '35',
    element_name: 'Bromo',
    element_onclick_id: '#br35',
    element_clases: 'pt-element pt-halogen pt-liquid g17 p4',
    element_symbol: 'Br',
    element_weight: '79.90',
  },
  {
    element_number: '36',
    element_name: 'Criptón',
    element_onclick_id: '#kr36',
    element_clases: 'pt-element pt-noble pt-gas g18 p4',
    element_symbol: 'Kr',
    element_weight: '83.80',
  },
  {
    element_number: '37',
    element_name: 'Rubidio',
    element_onclick_id: '#rb37',
    element_clases: 'pt-element pt-alkali pt-solid g1 p5',
    element_symbol: 'Rb',
    element_weight: '85.47',
  },
  {
    element_number: '38',
    element_name: 'Estroncio',
    element_onclick_id: '#sr38',
    element_clases: 'pt-element pt-alkaline pt-solid g2 p5',
    element_symbol: 'Sr',
    element_weight: '87.62',
  },
  {
    element_number: '39',
    element_name: 'Itrio',
    element_onclick_id: '#y39',
    element_clases: 'pt-element pt-transition pt-solid g3 p5',
    element_symbol: 'Y',
    element_weight: '88.91',
  },
  {
    element_number: '40',
    element_name: 'Circonio',
    element_onclick_id: '#zr40',
    element_clases: 'pt-element pt-transition pt-solid g4 p5',
    element_symbol: 'Zr',
    element_weight: '91.22',
  },
  {
    element_number: '41',
    element_name: 'Niobio',
    element_onclick_id: '#nb41',
    element_clases: 'pt-element pt-transition pt-solid g5 p5',
    element_symbol: 'Nb',
    element_weight: '92.91',
  },
  {
    element_number: '42',
    element_name: 'Molibdeno',
    element_onclick_id: '#mo42',
    element_clases: 'pt-element pt-transition pt-solid g6 p5',
    element_symbol: 'Mo',
    element_weight: '95.95',
  },
  {
    element_number: '43',
    element_name: 'Tecnecio',
    element_onclick_id: '#tc43',
    element_clases: 'pt-element pt-transition pt-solid g7 p5',
    element_symbol: 'Tc',
    element_weight: '98.00',
  },
  {
    element_number: '44',
    element_name: 'Rutenio',
    element_onclick_id: '#ru44',
    element_clases: 'pt-element pt-transition pt-solid g8 p5',
    element_symbol: 'Ru',
    element_weight: '101.1',
  },
  {
    element_number: '45',
    element_name: 'Rhodium',
    element_onclick_id: '#rh45',
    element_clases: 'pt-element pt-transition pt-solid g9 p5',
    element_symbol: 'Rh',
    element_weight: '102.9',
  },
  {
    element_number: '46',
    element_name: 'Paladio',
    element_onclick_id: '#pd46',
    element_clases: 'pt-element pt-transition pt-solid g10 p5',
    element_symbol: 'Pd',
    element_weight: '106.4',
  },
  {
    element_number: '47',
    element_name: 'Plata',
    element_onclick_id: '#ag47',
    element_clases: 'pt-element pt-transition pt-solid g11 p5',
    element_symbol: 'Ag',
    element_weight: '107.9',
  },
  {
    element_number: '48',
    element_name: 'Cadmio',
    element_onclick_id: '#cd48',
    element_clases: 'pt-element pt-transition pt-solid g12 p5',
    element_symbol: 'Cd',
    element_weight: '112.4',
  },
  {
    element_number: '49',
    element_name: 'Indio',
    element_onclick_id: '#in49',
    element_clases: 'pt-element pt-basic pt-post-transition pt-solid g13 p5',
    element_symbol: 'In',
    element_weight: '114.8',
  },
  {
    element_number: '50',
    element_name: 'Estaño',
    element_onclick_id: '#sn50',
    element_clases: 'pt-element pt-basic pt-post-transition pt-solid g14 p5',
    element_symbol: 'Sn',
    element_weight: '118.7',
  },
  {
    element_number: '51',
    element_name: 'Antimonio',
    element_onclick_id: '#sb51',
    element_clases: 'pt-element pt-semimetal pt-metalloids pt-solid g15 p5',
    element_symbol: 'Sb',
    element_weight: '121.8',
  },
  {
    element_number: '52',
    element_name: 'Telurio',
    element_onclick_id: '#te52',
    element_clases: 'pt-element pt-semimetal pt-metalloids pt-solid g16 p5',
    element_symbol: 'Te',
    element_weight: '127.6',
  },
  {
    element_number: '53',
    element_name: 'Yodo',
    element_onclick_id: '#i53',
    element_clases: 'pt-element pt-halogen pt-solid g17 p5',
    element_symbol: 'I',
    element_weight: '126.9',
  },
  {
    element_number: '54',
    element_name: 'Xenón',
    element_onclick_id: '#xe54',
    element_clases: 'pt-element pt-noble pt-gas g18 p5',
    element_symbol: 'Xe',
    element_weight: '131.3',
  },
  {
    element_number: '55',
    element_name: 'Cesio',
    element_onclick_id: '#cs55',
    element_clases: 'pt-element pt-alkali pt-solid g1 p6',
    element_symbol: 'Cs',
    element_weight: '132.9',
  },
  {
    element_number: '56',
    element_name: 'Bario',
    element_onclick_id: '#ba56',
    element_clases: 'pt-element pt-alkaline pt-solid g2 p6',
    element_symbol: 'Ba',
    element_weight: '137.3',
  },
  {
    element_number: '57',
    element_name: 'Lantano',
    element_onclick_id: '#la57',
    element_clases: 'pt-element pt-lanthanide pt-solid p6',
    element_symbol: 'La',
    element_weight: '138.9',
  },
  {
    element_number: '58',
    element_name: 'Cerio',
    element_onclick_id: '#ce58',
    element_clases: 'pt-element pt-lanthanide pt-solid p6',
    element_symbol: 'Ce',
    element_weight: '140.1',
  },
  {
    element_number: '59',
    element_name: 'Praseodimio',
    element_onclick_id: '#pr59',
    element_clases: 'pt-element pt-lanthanide pt-solid p6',
    element_symbol: 'Pr',
    element_weight: '140.9',
  },
  {
    element_number: '60',
    element_name: 'Neodimio',
    element_onclick_id: '#nd60',
    element_clases: 'pt-element pt-lanthanide pt-solid p6',
    element_symbol: 'Nd',
    element_weight: '144.2',
  },
  {
    element_number: '61',
    element_name: 'Prometio',
    element_onclick_id: '#pm61',
    element_clases: 'pt-element pt-lanthanide pt-solid p6',
    element_symbol: 'Pm',
    element_weight: '145',
  },
  {
    element_number: '62',
    element_name: 'Samario',
    element_onclick_id: '#sm62',
    element_clases: 'pt-element pt-lanthanide pt-solid p6',
    element_symbol: 'Sm',
    element_weight: '150.4',
  },
  {
    element_number: '63',
    element_name: 'Europio',
    element_onclick_id: '#eu63',
    element_clases: 'pt-element pt-lanthanide pt-solid p6',
    element_symbol: 'Eu',
    element_weight: '152.00',
  },
  {
    element_number: '64',
    element_name: 'Gadolinio',
    element_onclick_id: '#gd64',
    element_clases: 'pt-element pt-lanthanide pt-solid p6',
    element_symbol: 'Gd',
    element_weight: '157.3',
  },
  {
    element_number: '65',
    element_name: 'Terbio',
    element_onclick_id: '#tb65',
    element_clases: 'pt-element pt-lanthanide pt-solid p6',
    element_symbol: 'Tb',
    element_weight: '158.9',
  },
  {
    element_number: '66',
    element_name: 'Disprosio',
    element_onclick_id: '#dy66',
    element_clases: 'pt-element pt-lanthanide pt-solid p6',
    element_symbol: 'Dy',
    element_weight: '162.5',
  },
  {
    element_number: '67',
    element_name: 'Holmio',
    element_onclick_id: '#ho67',
    element_clases: 'pt-element pt-lanthanide pt-solid p6',
    element_symbol: 'Ho',
    element_weight: '164.9',
  },
  {
    element_number: '68',
    element_name: 'Erbio',
    element_onclick_id: '#er68',
    element_clases: 'pt-element pt-lanthanide pt-solid p6',
    element_symbol: 'Er',
    element_weight: '167.3',
  },
  {
    element_number: '69',
    element_name: 'Tulio',
    element_onclick_id: '#tm69',
    element_clases: 'pt-element pt-lanthanide pt-solid p6',
    element_symbol: 'Tm',
    element_weight: '168.9',
  },
  {
    element_number: '70',
    element_name: 'Iterbio',
    element_onclick_id: '#yb70',
    element_clases: 'pt-element pt-lanthanide pt-solid p6',
    element_symbol: 'Yb',
    element_weight: '173.04',
  },
  {
    element_number: '71',
    element_name: 'Lutecio',
    element_onclick_id: '#lu71',
    element_clases: 'pt-element pt-lanthanide pt-solid p6',
    element_symbol: 'Lu',
    element_weight: '175.00',
  },
  {
    element_number: '72',
    element_name: 'Hafnio',
    element_onclick_id: '#hf72',
    element_clases: 'pt-element pt-transition pt-solid g4 p6',
    element_symbol: 'Hf',
    element_weight: '178.5',
  },
  {
    element_number: '73',
    element_name: 'Tantalio',
    element_onclick_id: '#ta73',
    element_clases: 'pt-element pt-transition pt-solid g5 p6',
    element_symbol: 'Ta',
    element_weight: '180.9',
  },
  {
    element_number: '74',
    element_name: 'Tungsteno',
    element_onclick_id: '#w74',
    element_clases: 'pt-element pt-transition pt-solid g6 p6',
    element_symbol: 'W',
    element_weight: '183.8',
  },
  {
    element_number: '75',
    element_name: 'Renio',
    element_onclick_id: '#re75',
    element_clases: 'pt-element pt-transition pt-solid g7 p6',
    element_symbol: 'Re',
    element_weight: '186.2',
  },
  {
    element_number: '76',
    element_name: 'Osmio',
    element_onclick_id: '#os76',
    element_clases: 'pt-element pt-transition pt-solid g8 p6',
    element_symbol: 'Os',
    element_weight: '190.2',
  },
  {
    element_number: '77',
    element_name: 'Iridio',
    element_onclick_id: '#ir77',
    element_clases: 'pt-element pt-transition pt-solid g9 p6',
    element_symbol: 'Ir',
    element_weight: '192.2',
  },
  {
    element_number: '78',
    element_name: 'Platino',
    element_onclick_id: '#pt78',
    element_clases: 'pt-element pt-transition pt-solid g10 p6',
    element_symbol: 'Pt',
    element_weight: '195.1',
  },
  {
    element_number: '79',
    element_name: 'Oro',
    element_onclick_id: '#au79',
    element_clases: 'pt-element pt-transition pt-solid g11 p6',
    element_symbol: 'Au',
    element_weight: '197.00',
  },
  {
    element_number: '80',
    element_name: 'Mercurio',
    element_onclick_id: '#hg80',
    element_clases: 'pt-element pt-transition pt-liquid g12 p6',
    element_symbol: 'Hg',
    element_weight: '200.6',
  },
  {
    element_number: '81',
    element_name: 'Talio',
    element_onclick_id: '#tl81',
    element_clases: 'pt-element pt-basic pt-post-transition pt-solid g13 p6',
    element_symbol: 'Tl',
    element_weight: '204.4',
  },
  {
    element_number: '82',
    element_name: 'Plomo',
    element_onclick_id: '#pb82',
    element_clases: 'pt-element pt-basic pt-post-transition pt-solid g14 p6',
    element_symbol: 'Pb',
    element_weight: '207.2',
  },
  {
    element_number: '83',
    element_name: 'Bismuto',
    element_onclick_id: '#bi83',
    element_clases: 'pt-element pt-basic pt-post-transition pt-solid g15 p6',
    element_symbol: 'Bi',
    element_weight: '209.00',
  },
  {
    element_number: '84',
    element_name: 'Polonio',
    element_onclick_id: '#po84',
    element_clases: 'pt-element pt-basic pt-post-transition pt-solid g16 p6',
    element_symbol: 'Po',
    element_weight: '(209)',
  },
  {
    element_number: '85',
    element_name: 'Astato',
    element_onclick_id: '#at85',
    element_clases: 'pt-element pt-semimetal pt-solid pt-metalloids g17 p6',
    element_symbol: 'At',
    element_weight: '(210)',
  },
  {
    element_number: '86',
    element_name: 'Radón',
    element_onclick_id: '#rn86',
    element_clases: 'pt-element pt-noble pt-gas g18 p6',
    element_symbol: 'Rn',
    element_weight: '(222)',
  },
  {
    element_number: '87',
    element_name: 'Francio',
    element_onclick_id: '#fr87',
    element_clases: 'pt-element pt-alkali pt-solid g1 p7',
    element_symbol: 'Fr',
    element_weight: '(223)',
  },
  {
    element_number: '88',
    element_name: 'Radio',
    element_onclick_id: '#ra88',
    element_clases: 'pt-element pt-alkaline pt-solid g2 p7',
    element_symbol: 'Ra',
    element_weight: '(226)',
  },
  {
    element_number: '89',
    element_name: 'Actinio',
    element_onclick_id: '#ac89',
    element_clases: 'pt-element pt-actinide pt-solid p7',
    element_symbol: 'Ac',
    element_weight: '(227)',
  },
  {
    element_number: '90',
    element_name: 'Torio',
    element_onclick_id: '#th90',
    element_clases: 'pt-element pt-actinide pt-solid p7',
    element_symbol: 'Th',
    element_weight: '232',
  },
  {
    element_number: '91',
    element_name: 'Protactinio',
    element_onclick_id: '#pa91',
    element_clases: 'pt-element pt-actinide pt-solid p7',
    element_symbol: 'Pa',
    element_weight: '231.00',
  },
  {
    element_number: '92',
    element_name: 'Uranio',
    element_onclick_id: '#u92',
    element_clases: 'pt-element pt-actinide pt-solid p7',
    element_symbol: 'U',
    element_weight: '238.00',
  },
  {
    element_number: '93',
    element_name: 'Neptunio',
    element_onclick_id: '#np93',
    element_clases: 'pt-element pt-actinide pt-solid p7',
    element_symbol: 'Np',
    element_weight: '(237)',
  },
  {
    element_number: '94',
    element_name: 'Plutonio',
    element_onclick_id: '#pu94',
    element_clases: 'pt-element pt-actinide pt-solid p7',
    element_symbol: 'Pu',
    element_weight: '(244)',
  },
  {
    element_number: '95',
    element_name: 'Americio',
    element_onclick_id: '#am95',
    element_clases: 'pt-element pt-actinide pt-solid p7',
    element_symbol: 'Am',
    element_weight: '(243)',
  },
  {
    element_number: '96',
    element_name: 'Curio',
    element_onclick_id: '#cm96',
    element_clases: 'pt-element pt-actinide pt-solid p7',
    element_symbol: 'Cm',
    element_weight: '(247)',
  },
  {
    element_number: '97',
    element_name: 'Berkelio',
    element_onclick_id: '#bk97',
    element_clases: 'pt-element pt-actinide pt-solid p7',
    element_symbol: 'Bk',
    element_weight: '(247)',
  },
  {
    element_number: '98',
    element_name: 'Californio',
    element_onclick_id: '#cf98',
    element_clases: 'pt-element pt-actinide pt-solid p7',
    element_symbol: 'Cf',
    element_weight: '(251)',
  },
  {
    element_number: '99',
    element_name: 'Einstenio',
    element_onclick_id: '#es99',
    element_clases: 'pt-element pt-actinide pt-solid p7',
    element_symbol: 'Es',
    element_weight: '(252)',
  },
  {
    element_number: '100',
    element_name: 'Fermio',
    element_onclick_id: '#fm100',
    element_clases: 'pt-element pt-actinide pt-solid p7',
    element_symbol: 'Fm',
    element_weight: '(257)',
  },
  {
    element_number: '101',
    element_name: 'Mendelevio',
    element_onclick_id: '#md101',
    element_clases: 'pt-element pt-actinide pt-solid p7',
    element_symbol: 'Md',
    element_weight: '(258)',
  },
  {
    element_number: '102',
    element_name: 'Nobelio',
    element_onclick_id: '#no102',
    element_clases: 'pt-element pt-actinide pt-solid p7',
    element_symbol: 'No',
    element_weight: '(259)',
  },
  {
    element_number: '103',
    element_name: 'Laurencio',
    element_onclick_id: '#lr103',
    element_clases: 'pt-element pt-actinide pt-solid p7',
    element_symbol: 'Lr',
    element_weight: '(262)',
  },
  {
    element_number: '104',
    element_name: 'Rutherfordio',
    element_onclick_id: '#rf104',
    element_clases: 'pt-element pt-transition pt-unknown g4 p7',
    element_symbol: 'Rf',
    element_weight: '(267)',
  },
  {
    element_number: '105',
    element_name: 'Dubnio',
    element_onclick_id: '#db105',
    element_clases: 'pt-element pt-transition pt-unknown g5 p7',
    element_symbol: 'Db',
    element_weight: '(268)',
  },
  {
    element_number: '106',
    element_name: 'Seaborgio',
    element_onclick_id: '#sg106',
    element_clases: 'pt-element pt-transition pt-unknown g6 p7',
    element_symbol: 'Sg',
    element_weight: '(269)',
  },
  {
    element_number: '107',
    element_name: 'Bohrio',
    element_onclick_id: '#bh107',
    element_clases: 'pt-element pt-transition pt-unknown g7 p7',
    element_symbol: 'Bh',
    element_weight: '(270)',
  },
  {
    element_number: '108',
    element_name: 'Hassio',
    element_onclick_id: '#hs108',
    element_clases: 'pt-element pt-transition pt-unknown g8 p7',
    element_symbol: 'Hs',
    element_weight: '(269)',
  },
  {
    element_number: '109',
    element_name: 'Meitnerio',
    element_onclick_id: '#mt109',
    element_clases: 'pt-element pt-transition pt-unknown g9 p7',
    element_symbol: 'Mt',
    element_weight: '(278)',
  },
  {
    element_number: '110',
    element_name: 'Darmstadio',
    element_onclick_id: '#ds110',
    element_clases: 'pt-element pt-transition pt-unknown g10 p7',
    element_symbol: 'Ds',
    element_weight: '(281)',
  },
  {
    element_number: '111',
    element_name: 'Roentgenio',
    element_onclick_id: '#rg111',
    element_clases: 'pt-element pt-transition pt-unknown g11 p7',
    element_symbol: 'Rg',
    element_weight: '(281)',
  },
  {
    element_number: '112',
    element_name: 'Copernicio',
    element_onclick_id: '#cn112',
    element_clases: 'pt-element pt-transition pt-unknown g12 p7',
    element_symbol: 'Cn',
    element_weight: '(285)',
  },
  {
    element_number: '113',
    element_name: 'Nihonio',
    element_onclick_id: '#nh113',
    element_clases: 'pt-element pt-basic pt-post-transition g13 p7',
    element_symbol: 'Nh',
    element_weight: '(286)',
  },
  {
    element_number: '114',
    element_name: 'Flerovio',
    element_onclick_id: '#fl114',
    element_clases: 'pt-element pt-basic pt-post-transition g14 p7',
    element_symbol: 'Fl',
    element_weight: '(289)',
  },
  {
    element_number: '115',
    element_name: 'Moscovio',
    element_onclick_id: '#mc115',
    element_clases: 'pt-element pt-basic pt-post-transition g15 p7',
    element_symbol: 'Mc',
    element_weight: '(289)',
  },
  {
    element_number: '116',
    element_name: 'Livermorio',
    element_onclick_id: '#lv116',
    element_clases: 'pt-element pt-basic pt-post-transition g16 p7',
    element_symbol: 'Lv',
    element_weight: '(293)',
  },
  {
    element_number: '117',
    element_name: 'Teneso',
    element_onclick_id: '#ts117',
    element_clases: 'pt-element pt-halogen pt-unknown g17 p7',
    element_symbol: 'Ts',
    element_weight: '(294)',
  },
  {
    element_number: '118',
    element_name: 'Oganesón',
    element_onclick_id: '#og118',
    element_clases: 'pt-element pt-noble pt-unknown g18 p7',
    element_symbol: 'Og',
    element_weight: '(294)',
  },
];
