var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "app" } }, [
    _c("div", { staticClass: "section content-title-group" }, [
      _c("h2", { staticClass: "title" }, [_vm._v(_vm._s(_vm.titulo))]),
    ]),
    _c("br"),
    _c("br"),
    _c("span", { staticClass: "texto30px" }, [
      _vm._v(
        " El próposito de este sitio es brindar recursos para el aprendizaje y consulta de contenidos. "
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }